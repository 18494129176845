import { useEffect, useState } from "react";
import { db } from "../../config/firebaseConfig.js";
import { doc, updateDoc } from "firebase/firestore";
import { Box } from "@mui/material";
import { DirectoryAutocomplete, SubmitButton } from "../CommonCpnts";
import { trimPersonInfo } from "../../utils/utils.js";
import { Lightbox, LightboxHeader } from "../common/CustomLightbox.jsx";

export default function WorshipTeamForm({ directory, open, handleClose, sel }) {
  const [leader, setLeader] = useState(sel?.leader || "");
  const [keyboard, setKeyboard] = useState(sel?.keyboard || "");
  const [guitar, setGuitar] = useState(sel?.guitar || "");
  const [bass, setBass] = useState(sel?.bass || "");
  const [cajon, setCajon] = useState(sel?.cajon || "");
  const [vocal, setVocal] = useState(sel?.vocal || "");
  const [miscellaneous, setMiscellaneous] = useState(sel?.miscellaneous || "");
  const [submitting, setSubmitting] = useState(false);

  const values = {
    leader: trimPersonInfo(leader),
    keyboard: trimPersonInfo(keyboard),
    guitar: trimPersonInfo(guitar),
    bass: trimPersonInfo(bass),
    cajon: trimPersonInfo(cajon),
    vocal: trimPersonInfo(vocal),
    miscellaneous: trimPersonInfo(miscellaneous),
  };

  function saveDetails() {
    const ref = doc(db, "set_lists", sel.id);
    setSubmitting(true);
    updateDoc(ref, values)
      .then(() => setTimeout(() => handleClose(), 300))
      .catch((error) => console.log(error))
      .finally(() => setTimeout(() => setSubmitting(false), 200));
  }

  function resetForm() {
    setLeader(sel?.leader || "");
    setKeyboard(sel?.keyboard || "");
    setGuitar(sel?.guitar || "");
    setBass(sel?.bass || "");
    setCajon(sel?.cajon || "");
    setVocal(sel?.vocal || "");
    setMiscellaneous(sel?.miscellaneous || "");
  }

  useEffect(
    resetForm,
    // eslint-disable-next-line
    [open]
  );

  return (
    <Lightbox open={open} onClose={handleClose}>
      <LightboxHeader title="Musicians" />
      {/* <pre>{JSON.stringify(sel, null, 2)}</pre> */}
      <DirectoryAutocomplete
        directory={directory}
        label="worship lead"
        setState={setLeader}
        value={leader?.displayName || ""}
      />
      <DirectoryAutocomplete
        directory={directory}
        label="keyboard"
        setState={setKeyboard}
        value={keyboard?.displayName || ""}
      />
      <DirectoryAutocomplete
        directory={directory}
        label="guitar"
        setState={setGuitar}
        value={guitar?.displayName || ""}
      />
      <DirectoryAutocomplete
        directory={directory}
        label="bass"
        setState={setBass}
        value={bass?.displayName || ""}
      />
      <DirectoryAutocomplete
        directory={directory}
        label="cajon"
        setState={setCajon}
        value={cajon?.displayName || ""}
      />
      <DirectoryAutocomplete
        directory={directory}
        label="vocal"
        setState={setVocal}
        value={vocal?.displayName || ""}
      />
      <DirectoryAutocomplete
        directory={directory}
        label="miscellaneous"
        setState={setMiscellaneous}
        value={miscellaneous?.displayName || ""}
      />
      <Box className="flex flex-end">
        <SubmitButton
          onClick={saveDetails}
          submitting={submitting}
          title="SAVE"
        />
      </Box>
    </Lightbox>
  );
}
