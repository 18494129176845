import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Divider,
  Link,
  Stack,
  Typography,
  Paper,
} from "@mui/material";
import { BackToHomeBtn, PageHeader } from "./CommonCpnts";
import AddIcon from "@mui/icons-material/Add";
import { db, storage } from "../config/firebaseConfig.js";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  onSnapshot,
  orderBy,
  serverTimestamp,
  where,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { Lightbox, LightboxHeader } from "./common/CustomLightbox.jsx";
import { BtnContainer, SubmitBtn } from "./common/CustomButtons.jsx";
import { BodyField, TitleField, ReplyField } from "./common/CustomInputFields";
import {
  getDateString,
  getTimeString,
  trimPersonInfo,
} from "../utils/utils.js";
import { LoginForm } from "./forms/LoginForm.jsx";
import { UploadFile } from "./CommonCpnts";
import { ref, deleteObject } from "firebase/storage";

export default function PrayerBoard({ currentUser }) {
  const dimensions = { width: 290, minWidth: 310, minHeight: 290 };
  const dimensions2 = { width: 280, height: 260, minWidth: 260, p: 2 };

  const [open, setOpen] = useState(false);
  const [replyOpen, setReplyOpen] = useState(false);

  const [posts, setPosts] = useState(null);
  const [memberInfo, setMemberInfo] = useState(null);
  const [loginOpen, setLoginOpen] = useState(false);
  const [selMessage, setSelMessage] = useState(null);
  const [replies, setReplies] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const openLogin = () => setLoginOpen(true);
  const closeLogin = () => setLoginOpen(false);
  const handleOpenReply = () => setReplyOpen(true);
  const handleCloseReply = () => setReplyOpen(false);

  async function fetchReplies(message) {
    const q = query(
      collection(db, "posts", message?.id, "replies"),
      orderBy("posted", "asc")
    );
    const querySnapshot = await getDocs(q);
    const fetchedItems = [];
    querySnapshot.forEach((doc) => {
      fetchedItems.push({
        id: doc.id,
        text: doc.data().text || "",
        postedBy: doc.data().postedBy,
        posted: doc.data().posted?.toDate() || null,
      });
    });

    setReplies(fetchedItems);
  }

  useEffect(() => {
    const q = query(collection(db, "posts"), orderBy("posted", "desc"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedItems = snapshot.docs.map((doc) => ({
        id: doc.id,
        body: doc.data().body,
        title: doc.data().title,
        posted: doc.data().posted?.toDate() || null,
        postedBy: doc.data().postedBy,
        replyCount: doc.data().replyCount || 0,
      }));
      setPosts(fetchedItems);
    });
    return unsubscribe;
  }, []);

  useEffect(
    () => {
      if (!currentUser) return;
      const q = query(
        collection(db, "members"),
        where("email", "==", currentUser.email)
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedItems = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        if (!snapshot.empty) setMemberInfo(fetchedItems[0]);
      });
      return unsubscribe;
    },
    //eslint-disable-next-line
    [currentUser]
  );

  return (
    <div className="page-container" sx={{ maxWidth: "100vw" }}>
      <BackToHomeBtn />
      <PageHeader title="Prayer Board" />
      {!currentUser && (
        <Box className="flex flex-row" sx={{ ml: "10px" }}>
          <Typography display="inline">Please</Typography>
          <Link className="link" onClick={openLogin} sx={{ mx: 1 }}>
            <Typography>login</Typography>
          </Link>
          <Typography display="inline">to view the prayer board</Typography>
          <LoginForm open={loginOpen} handleClose={closeLogin} />
        </Box>
      )}

      {currentUser && (
        <Box className="flex flex-wrap">
          <NewPostCard
            dimensions={dimensions}
            handleOpen={handleOpen}
            memberInfo={memberInfo}
          />
          {posts?.map((post) => (
            <Paper
              key={post.id}
              elevation={3}
              sx={{ m: 2, ...dimensions2 }}
              className="relative"
            >
              <Typography variant="h6">{post.title}</Typography>
              {post.posted && (
                <Typography color="text.secondary" variant="subtitle2">
                  posted {getDateString(post.posted)} by{" "}
                  {post.postedBy?.displayName || "anonymous"}
                </Typography>
              )}
              <Typography>{post.body}</Typography>
              {currentUser && (
                <Box style={{ position: "absolute", bottom: 10, right: 8 }}>
                  <Button
                    onClick={() => {
                      handleOpenReply();
                      setSelMessage(post);
                      fetchReplies(post);
                    }}
                  >
                    {getReplyLabel(post.replyCount)}
                  </Button>
                </Box>
              )}
            </Paper>
          ))}

          <PostForm
            open={open}
            handleClose={handleClose}
            memberInfo={memberInfo}
          />
          <ReplyForm
            currentUser={currentUser}
            initVal={selMessage}
            open={replyOpen}
            handleClose={handleCloseReply}
            memberInfo={memberInfo}
            replies={replies}
            setReplies={setReplies}
          />
        </Box>
      )}
    </div>
  );
}

function getReplyLabel(replyCount) {
  if (replyCount === 0) {
    return "REPLY";
  } else if (replyCount === 1) {
    return "1 REPLY";
  } else {
    return `${replyCount} REPLIES`;
  }
}

function NewPostCard({ dimensions, handleOpen, memberInfo }) {
  const cardStyle = {
    borderStyle: "solid",
    borderColor: "silver",
    bgcolor: "whitesmoke",
    m: 2,
    ...dimensions,
  };

  if (!memberInfo) return null;
  return (
    <Card sx={cardStyle}>
      <CardActionArea onClick={handleOpen} sx={dimensions}>
        <Box className="flex flex-center-all">
          <AddIcon color="primary" />
          <Typography color="primary" variant="h6">
            New Post
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  );
}

export function PostForm({ open, handleClose, memberInfo }) {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleText = (e) => setBody(e.target.value);
  const handleTitle = (e) => setTitle(e.target.value);

  const values = {
    title: title,
    body: body,
    posted: serverTimestamp(),
    postedBy: getDisplayNameAndContactInfo(memberInfo),
  };

  const clean = !body && !title;

  const resetForm = () => {
    setTitle("");
    setBody("");
    setSubmitting(false);
  };

  const closeAndReset = () => {
    handleClose();
    setTimeout(() => resetForm(), 500);
  };

  async function addMessage() {
    setSubmitting(true);
    addDoc(collection(db, "posts"), values)
      .catch((error) => console.log(error))
      .finally(() => {
        setTimeout(() => {
          setSubmitting(false);
          handleClose();
        }, 300);
      });
  }

  return (
    <Lightbox open={open} onClose={closeAndReset}>
      <LightboxHeader title="New Post" center />
      <TitleField onChange={handleTitle} value={title} />
      <BodyField onChange={handleText} value={body} />
      <BtnContainer right>
        <SubmitBtn
          disabled={clean || submitting}
          label="POST"
          onClick={addMessage}
          submitting={submitting}
        />
      </BtnContainer>
    </Lightbox>
  );
}

function ReplyForm({
  currentUser,
  initVal,
  open,
  handleClose,
  memberInfo,
  replies,
  setReplies,
}) {
  const [body, setBody] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [file, setFile] = useState(null);

  const handleText = (e) => setBody(e.target.value);

  const clean = !body;

  async function addReply() {
    const repliesRef = collection(db, "posts", initVal?.id, "replies");
    const values = {
      text: body,
      postedBy: { ...trimPersonInfo(memberInfo), userID: currentUser?.uid },
      posted: serverTimestamp(),
    };
    setSubmitting(true);
    addDoc(repliesRef, values)
      .then((doc) =>
        setReplies([
          ...replies,
          {
            id: doc.id,
            text: values.text,
            postedBy: values.postedBy,
            posted: new Date(),
          },
        ])
      )
      .then(() => setBody(""))
      .then(() =>
        updateDoc(doc(db, "posts", initVal?.id), {
          replyCount: replies?.length + 1,
        })
      )
      .catch((error) => console.log(error))
      .finally(() => setSubmitting(false));
  }

  function deleteReply(reply) {
    const replyRef = doc(db, "posts", initVal?.id, "replies", reply?.id);
    deleteDoc(replyRef)
      .then(() => setReplies(replies.filter((el) => el.id !== reply.id)))
      .then(() =>
        updateDoc(doc(db, "posts", initVal?.id), {
          replyCount: replies.length - 1,
        })
      );

    if (reply.url) {
      const storageRef = ref(storage, `posts/${initVal?.id}/${reply.name}`);
      deleteObject(storageRef).catch((error) => console.log(error));
    }
  }

  function uploadImage(url) {
    const { name } = file;
    const repliesRef = collection(db, "posts", initVal?.id, "replies");

    const values = {
      name: name,
      url: url,
      postedBy: { ...trimPersonInfo(memberInfo), userID: currentUser?.uid },
      posted: serverTimestamp(),
    };

    addDoc(repliesRef, values)
      .then((doc) =>
        setReplies([
          ...replies,
          {
            id: doc.id,
            name: values.name,
            url: values.url,
            postedBy: values.postedBy,
            posted: new Date(),
          },
        ])
      )
      .then(() => setBody(""))
      .then(() =>
        updateDoc(doc(db, "posts", initVal?.id), {
          replyCount: replies.length + 1,
        })
      )
      .catch((error) => console.log(error))
      .finally(() => setSubmitting(false));
  }

  const resetForm = () => {
    setBody("");
    setSubmitting(false);
  };

  const closeAndReset = () => {
    handleClose();
    setTimeout(() => resetForm(), 500);
  };

  return (
    <Lightbox open={open} onClose={closeAndReset}>
      <Typography variant="h6">{initVal?.title}</Typography>
      <Typography>{initVal?.body}</Typography>

      <Divider sx={{ my: 2 }} />
      {replies.map((reply) => (
        <Stack key={reply.id} sx={{ mb: 5 }}>
          {reply.text && <Typography>{reply.text}</Typography>}
          {reply.url && (
            <img
              src={reply.url}
              alt=""
              style={{
                maxWidth: "100%",
                maxHeight: "200px",
                objectFit: "contain",
              }}
            />
          )}
          <Typography variant="subtitle2" color="text.secondary" align="right">
            {reply.postedBy?.displayName || "anonymous"}
          </Typography>
          <Typography variant="caption" color="text.secondary" align="right">
            {getDateString(reply.posted) + " at " + getTimeString(reply.posted)}
          </Typography>
          {reply.postedBy?.userID === currentUser?.uid && (
            <Link
              align="right"
              underline="hover"
              className="link"
              onClick={() => deleteReply(reply)}
            >
              delete
            </Link>
          )}
        </Stack>
      ))}
      {replies?.length > 0 && <Divider sx={{ my: 2 }} />}

      <ReplyField onChange={handleText} value={body} />

      <BtnContainer right>
        <SubmitBtn
          disabled={clean || submitting}
          label="POST"
          onClick={addReply}
          submitting={submitting}
        />
      </BtnContainer>
      <div
        style={{ marginRight: "auto", marginLeft: "auto", textAlign: "center" }}
      >
        <Typography align="center" color="text.secondary" fontStyle="italic">
          - or -
        </Typography>
        <br />
        <UploadFile
          file={file}
          setFile={setFile}
          storagePath={`posts/${initVal?.id}/${file?.name}`}
          label="UPLOAD IMAGE"
          addRef={uploadImage}
        />
      </div>
      <br />
      <br />
    </Lightbox>
  );
}

function getDisplayNameAndContactInfo(memberInfo) {
  if (!memberInfo) {
    return null;
  } else {
    return {
      displayName: memberInfo.displayName || "",
      email: memberInfo.email || "",
      phoneNumber: memberInfo.phoneNumber || "",
    };
  }
}
