import { useEffect, useState } from "react";
import { db } from "../../config/firebaseConfig.js";
import { doc, updateDoc } from "firebase/firestore";
import { Box } from "@mui/material";
import {
  DirectoryAutocomplete,
  SermonTitleField,
  SubmitButton,
} from "../CommonCpnts";
import { Lightbox, LightboxHeader } from "../common/CustomLightbox.jsx";
import { trimPersonInfo } from "../../utils/utils.js";

export default function SermonForm({ directory, open, handleClose, sel }) {
  const [speaker, setSpeaker] = useState(sel?.sermonSpeaker || "");
  const [title, setTitle] = useState(sel?.sermonTitle || "");
  const [submitting, setSubmitting] = useState(false);

  const values = {
    sermonTitle: title,
    sermonSpeaker: trimPersonInfo(speaker),
  };

  const handleTitle = (e) => setTitle(e.target.value);

  function saveDetails() {
    const ref = doc(db, "services", sel.id);
    setSubmitting(true);
    updateDoc(ref, values)
      .then(() => setTimeout(() => handleClose(), 300))
      .catch((error) => console.log(error))
      .finally(() => setTimeout(() => setSubmitting(false), 200));
  }

  function resetForm() {
    setSpeaker(sel?.sermonSpeaker || "");
    setTitle(sel?.sermonTitle || "");
  }

  useEffect(
    resetForm,
    // eslint-disable-next-line
    [open]
  );

  return (
    <Lightbox open={open} onClose={handleClose}>
      <LightboxHeader title="Sermon" left />
      <SermonTitleField onChange={handleTitle} value={title} />
      <DirectoryAutocomplete
        directory={directory}
        label="speaker"
        setState={setSpeaker}
        value={speaker?.displayName || ""}
      />

      <Box className="flex flex-end">
        <SubmitButton
          onClick={saveDetails}
          submitting={submitting}
          title="SAVE"
        />
      </Box>
    </Lightbox>
  );
}
