import { useState, useEffect } from "react";
import { db } from "../../config/firebaseConfig.js";
import { doc, updateDoc } from "firebase/firestore";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { DirectoryAutocomplete, ItemField, SubmitButton } from "../CommonCpnts";
import { trimPersonInfo } from "../../utils/utils.js";
import { Lightbox, LightboxHeader } from "../common/CustomLightbox.jsx";

export default function SnacksForm({ directory, open, handleClose, sel }) {
  const [person1, setPerson1] = useState(sel?.snackPerson1 || "");
  const [person2, setPerson2] = useState(sel?.snackPerson2 || "");
  const [person3, setPerson3] = useState(sel?.snackPerson3 || "");
  const [item1, setItem1] = useState(sel?.snackItem1 || "");
  const [item2, setItem2] = useState(sel?.snackItem2 || "");
  const [item3, setItem3] = useState(sel?.snackItem3 || "");

  const [tabIndex, setTabIndex] = useState(0);
  const [submitting, setSubmitting] = useState(false);

  const handleItem1 = (e) => setItem1(e.target.value);
  const handleItem2 = (e) => setItem2(e.target.value);
  const handleItem3 = (e) => setItem3(e.target.value);

  const handleTabChange = (event, newValue) => setTabIndex(newValue);

  function saveDetails() {
    const values = {
      snackItem1: item1,
      snackPerson1: trimPersonInfo(person1),
      snackItem2: item2,
      snackPerson2: trimPersonInfo(person2),
      snackItem3: item3,
      snackPerson3: trimPersonInfo(person3),
    };
    const ref = doc(db, "services", sel.id);
    setSubmitting(true);
    updateDoc(ref, values)
      .then(() => setTimeout(() => handleClose(), 300))
      .catch((error) => console.log(error))
      .finally(() => setTimeout(() => setSubmitting(false), 200));
  }

  function resetForm() {
    setPerson1(sel?.snackPerson1 || "");
    setPerson2(sel?.snackPerson2 || "");
    setPerson3(sel?.snackPerson3 || "");
    setItem1(sel?.snackItem1 || "");
    setItem2(sel?.snackItem2 || "");
    setItem3(sel?.snackItem3 || "");
  }

  useEffect(
    resetForm,
    // eslint-disable-next-line
    [open]
  );

  return (
    <Lightbox open={open} onClose={handleClose}>
      <LightboxHeader />
      <Typography color="primary" variant="h5" sx={{ mb: 2 }}>
        Edit Snacks
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3 }}>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="Food 1" />
          <Tab label="Food 2" />
          <Tab label="Coffee" />
        </Tabs>
      </Box>

      {tabIndex === 0 && (
        <Box>
          <DirectoryAutocomplete
            directory={directory}
            label="name"
            setState={setPerson1}
            value={person1?.displayName || ""}
          />
          <ItemField key="snackItem1" onChange={handleItem1} value={item1} />
        </Box>
      )}
      {tabIndex === 1 && (
        <Box>
          <DirectoryAutocomplete
            directory={directory}
            label="name"
            setState={setPerson2}
            value={person2?.displayName || ""}
          />
          <ItemField key="snackItem2" onChange={handleItem2} value={item2} />
        </Box>
      )}
      {tabIndex === 2 && (
        <Box>
          <DirectoryAutocomplete
            directory={directory}
            label="name"
            setState={setPerson3}
            value={person3?.displayName || ""}
          />
          <ItemField key="snackItem3" onChange={handleItem3} value={item3} />
        </Box>
      )}
      <Box className="flex flex-end">
        <SubmitButton
          onClick={saveDetails}
          submitting={submitting}
          title="SAVE"
        />
      </Box>
    </Lightbox>
  );
}
