import { TextField } from "@mui/material";

export const ConfirmPasswordField = ({ onChange, value }) => (
  <TextField
    autoComplete="off"
    fullWidth
    label="confirm password"
    onChange={onChange}
    sx={{ mb: 1 }}
    type="password"
    value={value}
    variant="filled"
  />
);

export const FirstNameField = ({ onChange, value }) => (
  <TextField
    autoComplete="off"
    label="first name"
    onChange={onChange}
    value={value}
    variant="filled"
  />
);

export const LastNameField = ({ onChange, value }) => (
  <TextField
    autoComplete="off"
    label="last name"
    onChange={onChange}
    value={value}
    variant="filled"
  />
);

export function PasswordField({ onChange, value }) {
  return (
    <TextField
      autoComplete="off"
      fullWidth
      label="password"
      onChange={onChange}
      sx={{ mb: 1 }}
      type="password"
      value={value}
      variant="filled"
    />
  );
}

export const EmailField = ({ onChange, value }) => (
  <TextField
    autoComplete="off"
    fullWidth
    label="email"
    onChange={onChange}
    sx={{ mb: 1 }}
    value={value}
    variant="filled"
  />
);

export const BodyField = ({ onChange, value }) => (
  <TextField
    autoComplete="off"
    fullWidth
    label="body"
    multiline
    onChange={onChange}
    rows={4}
    sx={{ mb: 1 }}
    value={value}
    variant="filled"
  />
);

export const TitleField = ({ onChange, value }) => (
  <TextField
    autoComplete="off"
    fullWidth
    label="title"
    onChange={onChange}
    sx={{ mb: 1 }}
    value={value}
    variant="filled"
  />
);

export const KeyField = ({ onChange, value }) => (
  <TextField
    autoComplete="off"
    fullWidth
    label="key"
    onChange={onChange}
    sx={{ mb: 1 }}
    value={value}
    variant="filled"
  />
);

export const StreetAddressField = ({ onChange, value }) => (
  <TextField
    autoComplete="off"
    fullWidth
    label="street address"
    onChange={onChange}
    sx={{ mb: 1 }}
    value={value}
    variant="filled"
  />
);

export const ZipCodeField = ({ onChange, value }) => (
  <TextField
    autoComplete="off"
    fullWidth
    label="zip code"
    onChange={onChange}
    sx={{ mb: 1, width: 280 }}
    value={value}
    variant="filled"
  />
);

export const StateField = ({ onChange, value }) => (
  <TextField
    autoComplete="off"
    fullWidth
    label="state"
    onChange={onChange}
    sx={{ mb: 1, width: 200 }}
    value={value}
    variant="filled"
  />
);

export const CityField = ({ onChange, value }) => (
  <TextField
    autoComplete="off"
    fullWidth
    label="city"
    onChange={onChange}
    sx={{ mb: 1 }}
    value={value}
    variant="filled"
  />
);

export const CategoryField = ({ onChange, value }) => (
  <TextField
    autoComplete="off"
    fullWidth
    label="category"
    placeholder="e.g. mains, drinks, dessert..."
    onChange={onChange}
    sx={{ mb: 2 }}
    value={value}
    variant="outlined"
  />
);

export function PhoneNumField({ onChange, value }) {
  return (
    <TextField
      autoComplete="off"
      fullWidth
      label="phone number"
      placeholder="(XXX) XXX-XXXX"
      onChange={onChange}
      sx={{ mb: 1 }}
      value={value}
      variant="filled"
    />
  );
}

export function BirthdayField({ onChange, value }) {
  return (
    <TextField
      autoComplete="off"
      fullWidth
      label="day"
      onChange={onChange}
      sx={{ mr: 3, width: 150 }}
      value={value}
      variant="filled"
      type="number"
      inputProps={{ min: 1, max: 31 }}
    />
  );
}

export const ReplyField = ({ onChange, value }) => (
  <TextField
    autoComplete="off"
    fullWidth
    label="new reply"
    multiline
    onChange={onChange}
    rows={3}
    sx={{ mb: 1 }}
    value={value}
    variant="filled"
  />
);

export const ShortAnswerTextField = ({ onChange, value }) => (
  <TextField
    autoComplete="off"
    fullWidth
    label="text"
    onChange={onChange}
    value={value}
    variant="filled"
  />
);

export const PromptField = ({ onChange, value }) => (
  <TextField
    autoComplete="off"
    fullWidth
    label="prompt"
    multiline
    onChange={onChange}
    rows={4}
    sx={{ mb: 1 }}
    value={value}
    variant="filled"
  />
);

export const AnswerChoiceField = ({ onChange, value }) => (
  <TextField
    autoComplete="off"
    fullWidth
    label="answer choice"
    multiline
    onChange={onChange}
    value={value}
    variant="filled"
  />
);

export const PassageField = ({ onChange, value }) => (
  <TextField
    autoComplete="off"
    fullWidth
    label="passage"
    placeholder="Luke 15 : 11 – 32"
    multiline
    onChange={onChange}
    sx={{ mb: 2 }}
    value={value}
    variant="outlined"
  />
);
