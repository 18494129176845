import "./App.css";
import { useState, useEffect } from "react";
import { Box, Button, Divider, Link, Typography } from "@mui/material";
import { Routes, Route, Link as RouterLink } from "react-router-dom";
import SundayService from "./components/SundayService";
import SmallGroups from "./components/SmallGroups";
import Directory from "./components/Directory";
import PrayerBoard from "./components/PrayerBoard.jsx";
import Volunteer from "./components/Volunteer.jsx";
import SpecialEvents from "./components/SpecialEvents.jsx";
import VotingPage from "./components/VotingPage.jsx";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { SignUpForm } from "./components/forms/SignUpForm.jsx";
import { LoginForm } from "./components/forms/LoginForm.jsx";
import { useAuth } from "./contexts/AuthContext";
import WorshipPage from "./components/WorshipPage";
import { db } from "./config/firebaseConfig.js";
import { collection, query, onSnapshot, where } from "firebase/firestore";

export default function App() {
  const { currentUser } = useAuth();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="App">
        <Routes>
          <Route element={<HomePage currentUser={currentUser} />} path="/" />
          <Route
            element={<SundayService currentUser={currentUser} />}
            path="/sundayservice"
          />
          <Route
            element={<SmallGroups currentUser={currentUser} />}
            path="/smallgroups"
          />
          <Route
            element={<SpecialEvents currentUser={currentUser} />}
            path="/specialevents"
          />
          <Route
            element={<Volunteer currentUser={currentUser} />}
            path="volunteer"
          />
          <Route
            element={<PrayerBoard currentUser={currentUser} />}
            path="/prayerboard"
          />
          <Route
            element={<Directory currentUser={currentUser} />}
            path="/directory"
          />
          <Route
            element={<WorshipPage currentUser={currentUser} />}
            path="/worship"
          />
          <Route
            element={<VotingPage currentUser={currentUser} />}
            path="/vote"
          />
        </Routes>
      </div>
    </LocalizationProvider>
  );
}

function HomePage({ currentUser }) {
  const { logout } = useAuth();
  const [loginOpen, setLoginOpen] = useState(false);
  const [signupOpen, setSignupOpen] = useState(false);
  const [logo, setLogo] = useState(null);

  const openLogin = () => setLoginOpen(true);
  const closeLogin = () => setLoginOpen(false);
  const openSignup = () => setSignupOpen(true);
  const closeSignup = () => setSignupOpen(false);

  const navButtons = [
    { btnText: "Sunday Service", pathName: "sundayservice" },
    { btnText: "Small Groups", pathName: "smallgroups" },
    { btnText: "Special Events", pathName: "specialevents" },
    { btnText: "Prayer Board", pathName: "prayerboard" },
    { btnText: "Volunteer", pathName: "volunteer" },
    { btnText: "Directory", pathName: "directory" },
    { btnText: "Worship", pathName: "worship" },
    { btnText: "Vote", pathName: "vote" },
  ];

  useEffect(() => {
    const q = query(collection(db, "logos"), where("status", "==", "current"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedItems = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLogo(fetchedItems[0]);
    });
    return unsubscribe;
  }, [currentUser]);

  return (
    <Box className="flex flex-column flex-center-all" sx={{ py: "10%" }}>
      <div style={{ maxHeight: "20%", height: "200px" }}>
        <img
          className="ck-logo"
          src={logo?.url}
          alt="christ kaleidoscope logo"
        />
      </div>

      <Typography variant="h2" color="primary">
        Welcome
      </Typography>
      {currentUser && (
        <Box className="flex flex-center-all" sx={{ my: 2 }}>
          <Typography>{currentUser.email}</Typography>
          <Divider
            orientation="vertical"
            sx={{ mx: 2, borderColor: "gray" }}
            flexItem
          />
          <Link className="link" onClick={logout} underline="hover">
            <Typography>LOG OUT</Typography>
          </Link>
        </Box>
      )}
      {!currentUser && (
        <Box className="flex flex-center-all" sx={{ my: 2 }}>
          <Link className="link" onClick={openLogin} underline="hover">
            <Typography>LOGIN</Typography>
          </Link>
          <Divider
            orientation="vertical"
            sx={{ mx: 3, borderColor: "gray" }}
            flexItem
          />
          <Link className="link" onClick={openSignup} underline="hover">
            <Typography>SIGN UP</Typography>
          </Link>
        </Box>
      )}
      <Box style={{ maxWidth: "800px" }}>
        {navButtons.map((info) => (
          <Button
            key={info.pathName}
            component={RouterLink}
            sx={{ m: 1, width: "200px" }}
            to={info.pathName}
            variant="outlined"
          >
            {info.btnText}
          </Button>
        ))}
      </Box>

      <LoginForm open={loginOpen} handleClose={closeLogin} />
      <SignUpForm open={signupOpen} handleClose={closeSignup} />
    </Box>
  );
}
