import { useEffect, useState } from "react";
import { db } from "../../config/firebaseConfig.js";
import { doc, updateDoc } from "firebase/firestore";
import { Box, Typography } from "@mui/material";
import { DirectoryAutocomplete, SubmitButton } from "../CommonCpnts";
import { getDateString, trimPersonInfo } from "../../utils/utils.js";
import { Lightbox, LightboxHeader } from "../common/CustomLightbox.jsx";
import { PassageField } from "../common/CustomInputFields.jsx";

function parsePassage(passage) {
  if (!passage) return "";
  if (typeof passage === "string") {
    return passage;
  } else {
    return (
      passage.book +
      " " +
      passage.chapter +
      " : " +
      passage.verseStart +
      " – " +
      passage.verseEnd
    );
  }
}

function getTitle(role) {
  switch (role) {
    case "reader1":
      return "Reader 1";
    case "reader2":
      return "Reader 2";
    case "reader3":
      return "Psalm";
    default:
      return "";
  }
}

function getValues(role, passage, reader) {
  switch (role) {
    case "reader1":
      return {
        passage1: passage,
        reader1: trimPersonInfo(reader) || null,
      };
    case "reader2":
      return {
        passage2: passage,
        reader2: trimPersonInfo(reader) || null,
      };
    case "reader3":
      return {
        passage3: passage,
        reader3: trimPersonInfo(reader) || null,
      };
    default:
      break;
  }
}

export default function ReaderForm({ directory, open, handleClose, sel }) {
  const dateString = sel?.date ? getDateString(sel?.date) : null;

  const [reader, setReader] = useState(sel?.reader || "");

  const [passage, setPassage] = useState(parsePassage(sel?.passage));
  const [submitting, setSubmitting] = useState(false);

  const handlePassage = (e) => setPassage(e.target.value);

  const values = getValues(sel?.role, passage, reader);

  function saveDetails() {
    const ref = doc(db, "services", sel.id);

    setSubmitting(true);
    updateDoc(ref, values)
      .then(() => setTimeout(() => handleClose(), 300))
      .catch((error) => console.log(error))
      .finally(() => setTimeout(() => setSubmitting(false), 200));
  }

  function resetForm() {
    setReader(sel?.reader || "");
    setPassage(parsePassage(sel?.passage));
  }

  useEffect(
    resetForm,
    // eslint-disable-next-line
    [open]
  );

  return (
    <Lightbox open={open} onClose={handleClose}>
      <LightboxHeader title={getTitle(sel?.role)} />
      <Typography
        align="left"
        color="text.secondary"
        variant="subtitle2"
        sx={{ mb: 2 }}
      >
        {dateString}
      </Typography>

      <Box>
        <PassageField onChange={handlePassage} value={passage} />

        <DirectoryAutocomplete
          directory={directory}
          label="reader"
          setState={setReader}
          value={reader?.displayName || ""}
        />
      </Box>

      <Box className="flex flex-end">
        <SubmitButton
          onClick={saveDetails}
          submitting={submitting}
          title="SAVE"
        />
      </Box>
    </Lightbox>
  );
}
