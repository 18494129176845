import { useState, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { db } from "../config/firebaseConfig.js";
import {
  arrayUnion,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import AddIcon from "@mui/icons-material/Add";
import { BackToHomeBtn } from "./CommonCpnts.jsx";
import {
  DirectoryAutocomplete,
  DescriptionField,
  GroupNameField,
  LocationField,
  PageHeader,
  StartDatePicker,
  EndDatePicker,
  SubmitButton,
} from "./CommonCpnts.jsx";
import { months } from "../lists.js";
import { LoginForm } from "./forms/LoginForm.jsx";
import { PersonChip, ConfirmAction, PendingApproval } from "./CommonCpnts.jsx";
import { Lightbox, LightboxHeader } from "./common/CustomLightbox.jsx";

export default function SmallGroups({ currentUser }) {
  const [smallGroups, setSmallGroups] = useState([]);
  const [directory, setDirectory] = useState([]);
  const [groupFormOpen, setGroupFormOpen] = useState(false);
  const [openJoin, setOpenJoin] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [edit, setEdit] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const memberInfo = directory?.find((el) => el.email === currentUser.email);
  const authorized = memberInfo?.permissions?.includes("c9b82b90ae572c840a76");

  const handleOpen = () => setGroupFormOpen(true);
  const handleOpenJoin = () => setOpenJoin(true);
  const handleClose = () => setGroupFormOpen(false);
  const handleCloseJoin = () => setOpenJoin(false);

  const openLogin = () => setLoginOpen(true);
  const closeLogin = () => setLoginOpen(false);

  useEffect(() => {
    const q = query(collection(db, "groups"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedItems = snapshot.docs.map((doc) => ({
        id: doc.id,
        endDate: doc.data().endDate.toDate(),
        startDate: doc.data().startDate.toDate(),
        groupName: doc.data().groupName,
        location: doc.data().location,
        leaders: doc.data().leaders || [],
        participants: doc.data().participants || [],
        description: doc.data().description,
      }));

      setSmallGroups(fetchedItems);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    fetchDirectory();
  }, []);

  async function fetchDirectory() {
    const q = query(collection(db, "members"), orderBy("firstName", "asc"));
    const querySnapshot = await getDocs(q);
    const fetchedItems = [];
    querySnapshot.forEach((doc) => {
      fetchedItems.push({ userID: doc.id, ...doc.data() });
    });
    setDirectory(fetchedItems);
  }

  if (currentUser && memberInfo?.pendingApproval) {
    return (
      <PendingApproval title="Small Groups" pageName="small groups page" />
    );
  }

  if (!currentUser) {
    return (
      <div className="page-container">
        <BackToHomeBtn />
        <PageHeader title="Small Groups" />
        <Box className="flex flex-row" sx={{ ml: "10px" }}>
          <Typography display="inline">Please</Typography>
          <Link className="link" onClick={openLogin} sx={{ mx: 1 }}>
            <Typography>login</Typography>
          </Link>
          <Typography display="inline">to view small groups.</Typography>
          <LoginForm open={loginOpen} handleClose={closeLogin} />
        </Box>
      </div>
    );
  }

  return (
    <div className="page-container">
      <BackToHomeBtn />
      <PageHeader title="Small Groups" />
      {!currentUser && (
        <Box className="flex flex-row " sx={{ ml: "10px" }}>
          <Typography display="inline">Please</Typography>
          <Link className="link" onClick={openLogin} sx={{ mx: 1 }}>
            <Typography>login</Typography>
          </Link>
          <Typography display="inline">to edit small group details</Typography>
        </Box>
      )}
      <Box
        className="flex flex-column flex-center-all"
        sx={{ p: 3, minWidth: "620px" }}
      >
        {smallGroups.map((group) => (
          <SmallGroupDetails
            authorized={authorized}
            currentUser={currentUser}
            group={group}
            handleOpenJoin={handleOpenJoin}
            handleOpen={handleOpen}
            key={group.id}
            setSelectedGroup={setSelectedGroup}
            setEdit={setEdit}
          />
        ))}
        {authorized && (
          <Button
            onClick={() => {
              handleOpen();
              setEdit(false);
            }}
            startIcon={<AddIcon />}
            sx={{ mb: 10 }}
          >
            ADD SMALL GROUP
          </Button>
        )}
        <SmallGroupForm
          authorized={authorized}
          edit={edit}
          initVal={selectedGroup}
          handleClose={handleClose}
          key={selectedGroup?.id || "new-group"}
          open={groupFormOpen}
        />
        <JoinSmallGroup
          directory={directory}
          handleClose={handleCloseJoin}
          open={openJoin}
          selectedGroup={selectedGroup}
        />
        <LoginForm open={loginOpen} handleClose={closeLogin} />
      </Box>
    </div>
  );
}

function SmallGroupDetails({
  authorized,
  currentUser,
  group,
  handleOpen,
  handleOpenJoin,
  setSelectedGroup,
  setEdit,
}) {
  const startDate = group.startDate;
  const startMonth = months[startDate.getMonth()];
  const startNum = startDate.getDate();

  const endDate = group.endDate;
  const endMonth = months[endDate.getMonth()];
  const endNum = endDate.getDate();

  const startDateString = startMonth + " " + startNum;
  const endDateString = endMonth + " " + endNum;

  const [confirmActionOpen, setConfirmActionOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const openConfirmAction = () => setConfirmActionOpen(true);
  const closeConfirmAction = () => setConfirmActionOpen(false);

  function deleteSmallGroup() {
    if (!authorized) return;
    const smallGroupRef = doc(db, "groups", group.id);
    setSubmitting(true);
    deleteDoc(smallGroupRef)
      .catch((error) => console.log(error))
      .finally(() => setSubmitting(false));
  }

  function deleteLeader(leader) {
    const remaining = group.leaders.filter(
      (el) => el.displayName !== leader.displayName
    );

    const groupRef = doc(db, "groups", group.id);

    updateDoc(groupRef, {
      leaders: remaining,
    })
      .then(() => console.log("success"))
      .catch((error) => console.log(error.message));
  }

  function deleteParticipant(participant) {
    const remaining = group.participants.filter(
      (el) => el.displayName !== participant.displayName
    );

    const groupRef = doc(db, "groups", group.id);

    updateDoc(groupRef, {
      participants: remaining,
    })
      .then(() => console.log("success"))
      .catch((error) => console.log(error.message));
  }

  const cell1Style = {
    width: "25%",
    textAlign: "right",
    paddingRight: "2%",
    height: 35,
  };

  const rowStyle = {
    verticalAlign: "top",
    minHeight: 40,
  };

  return (
    <Card sx={{ mb: 2, width: 600 }}>
      {group.leaders?.length === 0 && (
        <Alert severity="info">This small group needs a leader!</Alert>
      )}
      <CardContent className="relative">
        <Typography display="inline" variant="h6">
          {group.groupName}
        </Typography>
        <ConfirmAction
          btnLabel="DELETE"
          handleAction={deleteSmallGroup}
          handleClose={closeConfirmAction}
          open={confirmActionOpen}
          submitting={submitting}
          title="Delete Small Group"
        >
          <Typography>
            Delete <strong>{group.groupName}</strong> and all associated
            information?
          </Typography>
        </ConfirmAction>

        {authorized && (
          <Stack
            direction="row"
            divider={
              <Divider orientation="vertical" variant="middle" flexItem />
            }
            sx={{ position: "absolute", top: 10, right: 10 }}
          >
            <Button
              onClick={() => {
                handleOpen();
                setSelectedGroup(group);
                setEdit(true);
              }}
            >
              Edit
            </Button>
            <Button onClick={openConfirmAction}>DELETE</Button>
          </Stack>
        )}
        <table style={{ width: "100%", paddingTop: "20px" }}>
          <tbody>
            <tr style={rowStyle}>
              <td style={cell1Style}>
                <Typography>Date Range: </Typography>
              </td>
              <td>
                <Typography>
                  {startDateString} to {endDateString}
                </Typography>
              </td>
            </tr>
            <tr style={rowStyle}>
              <td style={cell1Style}>
                <Typography>Location: </Typography>
              </td>
              <td>
                <Typography>{group.location}</Typography>
              </td>
            </tr>
            <tr style={rowStyle}>
              <td style={{ ...cell1Style, paddingBottom: "50px" }}>
                <Typography>Description: </Typography>
              </td>
              <td>
                <Typography>{group.description}</Typography>
              </td>
            </tr>
            <tr style={rowStyle}>
              <td style={{ ...cell1Style, paddingBottom: "15px" }}>
                <Typography sx={{ mt: 0.5 }}>Leaders: </Typography>
              </td>
              <td>
                <Stack direction="row" spacing={1}>
                  {group.leaders?.map((leader) => (
                    <PersonChip
                      currentUser={currentUser}
                      key={leader.displayName}
                      person={leader}
                      handleDelete={deleteLeader}
                    />
                  ))}
                </Stack>
              </td>
            </tr>
            <tr style={rowStyle}>
              <td style={cell1Style}>
                <Typography sx={{ mt: 0.5 }}>Participants: </Typography>
              </td>
              <td>
                {group.participants?.map((participant) => (
                  <PersonChip
                    currentUser={currentUser}
                    key={participant.displayName}
                    person={participant}
                    handleDelete={deleteParticipant}
                  />
                ))}
              </td>
            </tr>
          </tbody>
        </table>
      </CardContent>
      {currentUser && (
        <CardActions className="flex flex-justify-center" sx={{ mb: 3 }}>
          <Button
            sx={{ width: "45%" }}
            variant="contained"
            onClick={() => {
              handleOpenJoin();
              setSelectedGroup(group);
            }}
          >
            JOIN
          </Button>
        </CardActions>
      )}
    </Card>
  );
}

function SmallGroupForm({ authorized, edit, open, handleClose, initVal }) {
  const [description, setDescription] = useState(initVal?.description || "");
  const [groupName, setGroupName] = useState(initVal?.groupName || "");
  const [startDate, setStartDate] = useState(initVal?.startDate || new Date());
  const [endDate, setEndDate] = useState(initVal?.endDate || new Date());
  const [location, setLocation] = useState(initVal?.location || "");
  const [submitting, setSubmitting] = useState(false);

  const values = {
    type: "small group",
    groupName: groupName,
    description: description,
    location: location,
    startDate: startDate,
    endDate: endDate,
  };

  const handleGroupName = (e) => setGroupName(e.target.value);
  const handleDescription = (e) => setDescription(e.target.value);
  const handleStartDate = (date) => setStartDate(date);
  const handleEndDate = (date) => setEndDate(date);
  const handleLocation = (e) => setLocation(e.target.value);

  function resetForm() {
    setGroupName("");
    setDescription("");
    setStartDate(new Date());
    setEndDate(new Date());
    setLocation("");
  }

  async function addSmallGroup() {
    if (!authorized) return;
    setSubmitting(true);
    addDoc(collection(db, "groups"), values)
      .then(() => {
        setTimeout(() => {
          setSubmitting(false);
          handleClose();
          resetForm();
        }, 1000);
      })
      .catch((error) => console.log(error));
  }

  async function editSmallGroup() {
    if (!authorized) return;
    setSubmitting(true);
    updateDoc(doc(db, "groups", initVal.id), values)
      .then(() =>
        setTimeout(() => {
          setSubmitting(false);
          handleClose();
        }, 300)
      )
      .catch((error) => console.log(error));
  }

  return (
    <Lightbox open={open} onClose={handleClose}>
      <LightboxHeader title="New Small Group" />
      <Box className="flex flex-center-all" sx={{ mb: 1 }}>
        <StartDatePicker onChange={handleStartDate} value={startDate} />
        <Typography sx={{ mx: 2 }}>to</Typography>
        <EndDatePicker onChange={handleEndDate} value={endDate} />
      </Box>
      <GroupNameField onChange={handleGroupName} value={groupName} />
      <LocationField onChange={handleLocation} value={location} />
      <DescriptionField onChange={handleDescription} value={description} />

      <Box className="flex flex-row flex-end">
        <SubmitButton
          onClick={edit ? editSmallGroup : addSmallGroup}
          submitting={submitting}
          title={edit ? "SAVE" : "ADD GROUP"}
        />
      </Box>
    </Lightbox>
  );
}

function JoinSmallGroup({ directory, handleClose, open, selectedGroup }) {
  const [person, setPerson] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [role, setRole] = useState("");

  const handleRole = (e) => setRole(e.target.value);

  async function addLeader() {
    setSubmitting(true);
    updateDoc(doc(db, "groups", selectedGroup.id), {
      leaders: arrayUnion(person),
    })
      .then(() => setTimeout(() => handleClose(), 300))
      .catch((error) => console.log(error))
      .finally(() => setTimeout(() => setSubmitting(false), 200));
  }

  async function addParticipant() {
    setSubmitting(true);
    updateDoc(doc(db, "groups", selectedGroup.id), {
      participants: arrayUnion(person),
    })
      .then(() => setTimeout(() => handleClose(), 300))
      .catch((error) => console.log(error))
      .finally(() => setTimeout(() => setSubmitting(false), 200));
  }

  function handleSubmit() {
    switch (role) {
      case "leader":
        addLeader();
        break;
      case "participant":
        addParticipant();
        break;
      default:
        break;
    }
  }

  function resetForm() {
    setPerson(null);
    setRole("");
  }

  useEffect(
    resetForm,
    // eslint-disable-next-line
    [open]
  );

  if (!selectedGroup) return null;

  return (
    <Lightbox open={open} onClose={handleClose}>
      <LightboxHeader title={"Join " + selectedGroup?.groupName} />
      <FormControl fullWidth>
        <InputLabel>Role</InputLabel>
        <Select label="Role" value={role} onChange={handleRole} sx={{ mb: 1 }}>
          <MenuItem value="leader">leader</MenuItem>
          <MenuItem value="participant">participant</MenuItem>
        </Select>
      </FormControl>

      <DirectoryAutocomplete
        directory={directory}
        label="name"
        setState={setPerson}
        value={person?.displayName || ""}
      />

      <Box className="flex flex-row flex-end">
        <SubmitButton
          onClick={handleSubmit}
          submitting={submitting}
          title="SIGN UP"
          disabled={submitting || !role || !person}
        />
      </Box>
    </Lightbox>
  );
}
