import PDFMerger from "pdf-merger-js/browser";

export function copyArray(arr) {
  return JSON.parse(JSON.stringify(arr));
}

export function formatDate(dateObj) {
  const isDate = dateObj instanceof Date;
  if (!isDate) return "no date";

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formattedDate = dateObj.toLocaleDateString("en-US", options);
  return formattedDate;
}

export function generateCode(length) {
  let randomCode = "";
  const characters =
    "ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    randomCode += characters.charAt(
      Math.floor(Math.random() * charactersLength)
    );
  }
  return randomCode;
}

export function getFileExtension(file) {
  return file.name.split(".").pop();
}

export function getDateString(date) {
  if (!date) return null;
  return date.toLocaleDateString("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });
}

export function getShortDateString(date) {
  return date.toLocaleDateString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
  });
}

export function getTimeString(date) {
  return date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
  });
}

export async function mergePDFs(urls, title) {
  const merger = new PDFMerger();

  for (const url of urls) {
    await merger.add(url);
  }

  await merger.save(title);
}

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function nextSunday() {
  const d = new Date();
  d.setDate(d.getDate() + (0 + ((7 - d.getDay()) % 7)));
  return d;
}

export function trimPersonInfo(info) {
  if (!info) return null;
  return {
    firstName: info.firstName || null,
    lastName: info.lastName || null,
    displayName: info.displayName || null,
    email: info.email || null,
    phoneNumber: info.phoneNumber || null,
  };
}

export function twoWeeksAgo() {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 14);
}

export function twoWeeksLater() {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate() + 14);
}
