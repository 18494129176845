import { useEffect, useState } from "react";
import { BackToHomeBtn, PageHeader, PendingApproval } from "./CommonCpnts";
import {
  Box,
  Divider,
  Link,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import {
  arrayRemove,
  arrayUnion,
  getDocs,
  query,
  collection,
  onSnapshot,
  orderBy,
  updateDoc,
  doc,
  where,
} from "firebase/firestore";
import { db } from "../config/firebaseConfig";
import { LoginForm } from "./forms/LoginForm.jsx";

export default function Volunteer({ currentUser }) {
  const [directory, setDirectory] = useState([]);
  const [memberInfo, setMemberInfo] = useState(null);
  const [loginOpen, setLoginOpen] = useState(false);
  const welcomingTeam = directory.filter((el) =>
    el.volunteerRoles.includes("welcoming team")
  );
  const emcee = directory.filter((el) => el.volunteerRoles.includes("emcee"));
  const scriptureReading = directory.filter((el) =>
    el.volunteerRoles.includes("scripture reading")
  );
  const communionServer = directory.filter((el) =>
    el.volunteerRoles.includes("communion server")
  );
  const musicTeam = directory.filter((el) =>
    el.volunteerRoles.includes("music team")
  );
  const audioVisual = directory.filter((el) =>
    el.volunteerRoles.includes("audio / visual and tech support")
  );
  const setup = directory.filter((el) =>
    el.volunteerRoles.includes("setup and tear-down")
  );

  const smallGroupLeader = directory.filter((el) =>
    el.volunteerRoles.includes("small group leader")
  );

  const bookClubLeader = directory.filter((el) =>
    el.volunteerRoles.includes("book club leader")
  );

  const careTeamLeader = directory.filter((el) =>
    el.volunteerRoles.includes("care team leader")
  );

  const communityServLeader = directory.filter((el) =>
    el.volunteerRoles.includes("community service leader")
  );

  const childrensMinistry = directory.filter((el) =>
    el.volunteerRoles.includes("children's ministry leader")
  );

  const foodDrinks = directory.filter((el) =>
    el.volunteerRoles.includes("food / drinks")
  );

  const artCoordinator = directory.filter((el) =>
    el.volunteerRoles.includes("visual art coordinator")
  );

  const photoVideo = directory.filter((el) =>
    el.volunteerRoles.includes("photography and video")
  );

  const openLogin = () => setLoginOpen(true);
  const closeLogin = () => setLoginOpen(false);

  useEffect(
    () => {
      if (!currentUser) return;
      const q = query(
        collection(db, "members"),
        where("email", "==", currentUser.email)
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedItems = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        if (!snapshot.empty) setMemberInfo(fetchedItems[0]);
      });
      return unsubscribe;
    },
    //eslint-disable-next-line
    [currentUser]
  );

  useEffect(() => {
    fetchDirectory();
  }, [memberInfo]);

  async function fetchDirectory() {
    const q = query(
      collection(db, "members"),
      orderBy("volunteerRoles", "asc")
    );
    const querySnapshot = await getDocs(q);
    const fetchedItems = [];
    querySnapshot.forEach((doc) => {
      fetchedItems.push({ userID: doc.id, ...doc.data() });
    });
    setDirectory(fetchedItems);
  }

  if (currentUser && memberInfo?.pendingApproval) {
    return <PendingApproval title="Directory" pageName="directory" />;
  }

  if (!currentUser)
    return (
      <div className="page-container">
        <BackToHomeBtn />
        <PageHeader title="Volunteer" />
        <Box className="flex flex-row" sx={{ ml: "10px" }}>
          <Typography display="inline">Please</Typography>
          <Link className="link" onClick={openLogin} sx={{ mx: 1 }}>
            <Typography>login</Typography>
          </Link>
          <Typography display="inline">to volunteer</Typography>
          <LoginForm open={loginOpen} handleClose={closeLogin} />
        </Box>
      </div>
    );

  return (
    <div className="page-container" sx={{ maxWidth: "100vw" }}>
      <BackToHomeBtn />
      <PageHeader title="Volunteer" />
      <Typography sx={{ ml: 1, mb: 2 }} fontStyle="italic">
        Christ Kaleidoscope depends on willing hearts to serve and build
        community! If any of the below roles appeal to you, please consider
        signing up.
      </Typography>
      <SectionHeader title="Sunday Service" />

      <Table>
        <Row
          category="welcoming team"
          currentUser={currentUser}
          memberInfo={memberInfo}
        />
        <Row
          category="emcee"
          currentUser={currentUser}
          memberInfo={memberInfo}
        />
        <Row
          category="scripture reading"
          currentUser={currentUser}
          memberInfo={memberInfo}
        />
        <Row
          category="communion server"
          currentUser={currentUser}
          memberInfo={memberInfo}
        />

        <Row
          category="music team"
          currentUser={currentUser}
          memberInfo={memberInfo}
        />
        <Row
          category="audio / visual and tech support"
          currentUser={currentUser}
          memberInfo={memberInfo}
        />
        <Row
          category="setup and tear-down"
          currentUser={currentUser}
          memberInfo={memberInfo}
        />
        <Row
          category="food / drinks"
          currentUser={currentUser}
          memberInfo={memberInfo}
        />
      </Table>
      <br />
      <SectionHeader title="Leadership Roles" />
      <Table>
        <Row
          category="small group leader"
          currentUser={currentUser}
          memberInfo={memberInfo}
        />
        <Row
          category="book club leader"
          currentUser={currentUser}
          memberInfo={memberInfo}
        />
        <Row
          category="children's ministry leader"
          currentUser={currentUser}
          memberInfo={memberInfo}
        />
        <Row
          category="care team leader"
          currentUser={currentUser}
          memberInfo={memberInfo}
        />
        <Row
          category="community service leader"
          currentUser={currentUser}
          memberInfo={memberInfo}
        />
      </Table>
      <br />
      <SectionHeader title="Area Specialists" />
      <Table>
        <Row
          category="visual art coordinator"
          currentUser={currentUser}
          memberInfo={memberInfo}
        />
        <Row
          category="photography and video"
          currentUser={currentUser}
          memberInfo={memberInfo}
        />
      </Table>

      <Divider style={{ marginTop: "10px", marginBottom: "20px" }} />
      <Typography color="primary" variant="h6" sx={{ mb: 2 }}>
        See Who's Volunteered
      </Typography>

      <Row2 category="Welcoming Team" volunteers={welcomingTeam} />
      <Row2 category="Emcee" volunteers={emcee} />
      <Row2 category="Scripture Reading" volunteers={scriptureReading} />
      <Row2 category="Communion Server" volunteers={communionServer} />
      <Row2 category="Music Team" volunteers={musicTeam} />
      <Row2 category="Audio / Visual Support" volunteers={audioVisual} />
      <Row2 category="Setup / Tear-Down" volunteers={setup} />
      <Row2 category="Food / Drinks" volunteers={foodDrinks} />
      <Row2 category="Small Group Leader" volunteers={smallGroupLeader} />
      <Row2 category="Book Club Leader" volunteers={bookClubLeader} />
      <Row2
        category="Children's Ministry Leader"
        volunteers={childrensMinistry}
      />
      <Row2 category="Care Team Leader" volunteers={careTeamLeader} />
      <Row2
        category="Community Service Leader"
        volunteers={communityServLeader}
      />

      <Row2 category="Visual Arts Coordinator" volunteers={artCoordinator} />
      <Row2 category="Photography and Video" volunteers={photoVideo} />
    </div>
  );
}

function SectionHeader({ title }) {
  return (
    <Typography variant="h6" sx={{ ml: 1 }}>
      {title}
    </Typography>
  );
}

function Table({ children }) {
  return (
    <table className="service-section-table">
      <tbody>{children}</tbody>
    </table>
  );
}

function Row({ category, confirmed, currentUser, role, memberInfo }) {
  const roleAdded = memberInfo?.volunteerRoles?.includes(category);

  if (!category) return null;

  async function addRole() {
    updateDoc(doc(db, "members", memberInfo.id), {
      volunteerRoles: arrayUnion(category),
    }).catch((error) => console.log(error));
  }

  async function removeRole() {
    updateDoc(doc(db, "members", memberInfo.id), {
      volunteerRoles: arrayRemove(category),
    }).catch((error) => console.log(error));
  }

  return (
    <tr style={{ height: "42px" }}>
      <td style={{ width: "260px" }}>
        <Typography>{category}</Typography>
      </td>
      <td>
        {!roleAdded && currentUser && (
          <Link
            onClick={addRole}
            component="button"
            className="confirm"
            underline="none"
          >
            sign up
          </Link>
        )}
        {confirmed && !currentUser && <CheckIcon color="success" />}
        {roleAdded && currentUser && (
          <Tooltip title="click to undo" placement="left">
            <IconButton onClick={removeRole}>
              <CheckIcon color="success" />
            </IconButton>
          </Tooltip>
        )}
      </td>
    </tr>
  );
}

function Row2({ category, volunteers }) {
  const volunteerNames = volunteers?.map((el) => el.displayName);
  const volunteerNamesTidied =
    volunteerNames?.length > 0
      ? volunteerNames.join(", ")
      : "(no volunteers yet)";
  return (
    <>
      <Typography variant="subtitle1">{category}</Typography>
      <Typography fontStyle="italic" sx={{ px: 3, mb: 3 }}>
        {volunteerNamesTidied}
      </Typography>
    </>
  );
}
