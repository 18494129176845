import { useState } from "react";
import { useAuth } from "../../contexts/AuthContext.jsx";
import { db } from "../../config/firebaseConfig.js";
import { setDoc, doc } from "firebase/firestore";
import {
  Alert,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Lightbox, LightboxHeader } from "../common/CustomLightbox.jsx";
import {
  ConfirmPasswordField,
  EmailField,
  FirstNameField,
  LastNameField,
  PasswordField,
} from "../common/CustomInputFields.jsx";
import { BtnContainer, SubmitBtn } from "../common/CustomButtons.jsx";

export function SignUpForm({ open, handleClose }) {
  const { signup } = useAuth();

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [cfmPassword, setCfmPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleEmail = (e) => setEmail(e.target.value);
  const handleFirstName = (e) => setFirstName(e.target.value);
  const handleLastName = (e) => setLastName(e.target.value);
  const handlePassword = (e) => setPassword(e.target.value);
  const handleCfmPassword = (e) => setCfmPassword(e.target.value);

  const match = password && password === cfmPassword;
  const length = password.length || 0;
  const numCapitalLetters = password.match(/[A-Z]/g)?.length || 0;
  const numSpecialChar = password.match(/[~!@#$%&_+-]/g)?.length || 0;
  const allFulfilled =
    email && length && numCapitalLetters && numSpecialChar && match;

  const resetForm = () => {
    setEmail("");
    setFirstName("");
    setLastName("");
    setPassword("");
    setCfmPassword("");
    setErrorMessage("");
    setSuccessMessage("");
  };

  const closeAndReset = () => {
    handleClose();
    setTimeout(() => resetForm(), 500);
  };

  async function createUserAccount() {
    setErrorMessage("");
    setSubmitting(true);
    signup(email, password)
      .then((userCredentials) => {
        setDoc(doc(db, "members", userCredentials.user.uid), {
          userID: userCredentials.user.uid,
          email: email,
          firstName: firstName,
          lastName: lastName,
          displayName: firstName + " " + lastName,
          permissions: [],
          pendingApproval: true,
        });
      })
      .then(() => {
        setTimeout(() => setSubmitting(false), 500);
        setTimeout(() => setSuccessMessage("Success! Account created."), 600);
        setTimeout(() => handleClose(), 2000);
        setTimeout(() => resetForm(), 2500);
      })
      .catch((error) => {
        setTimeout(() => setSubmitting(false), 500);
        setTimeout(() => setErrorMessage(getErrorMessage(error.code)), 600);
      });
  }

  return (
    <Lightbox open={open} onClose={closeAndReset}>
      <LightboxHeader title="Create A New Account" center />
      <EmailField onChange={handleEmail} value={email} />
      <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
        <FirstNameField onChange={handleFirstName} value={firstName} />
        <LastNameField onChange={handleLastName} value={lastName} />
      </Stack>
      <PasswordField onChange={handlePassword} value={password} />
      <ConfirmPasswordField onChange={handleCfmPassword} value={cfmPassword} />

      <List>
        <PasswordValidation
          condition={length >= 10}
          primaryText="password has at least 10 characters"
        />
        <PasswordValidation
          condition={numCapitalLetters > 0}
          primaryText="password has at least one capital letter"
        />
        <PasswordValidation
          condition={numSpecialChar > 0}
          primaryText="password has at least one special character"
          secondaryText="choose from: ~ ! @ # $ % & _ + -"
        />
        <PasswordValidation
          condition={match}
          primaryText="password confirmed"
        />
      </List>

      {errorMessage && <Alert severity="warning">{errorMessage}</Alert>}
      {successMessage && <Alert severity="success">{successMessage}</Alert>}

      <BtnContainer right>
        <SubmitBtn
          disabled={!allFulfilled || submitting}
          label="CREATE MY ACCOUNT"
          onClick={createUserAccount}
          submitting={submitting}
        />
      </BtnContainer>
    </Lightbox>
  );
}

function PasswordValidation({ condition, primaryText, secondaryText }) {
  return (
    <ListItem sx={{ py: 0 }}>
      <ListItemIcon sx={{ minWidth: "40px" }}>
        <CheckCircleOutlineIcon color={condition ? "success" : "disabled"} />
      </ListItemIcon>
      <ListItemText primary={primaryText} secondary={secondaryText} />
    </ListItem>
  );
}

function getErrorMessage(errorCode) {
  console.log(errorCode);
  switch (errorCode) {
    case "auth/invalid-email":
      return "Please check if you entered your email correctly.";
    case "auth/user-not-found":
      return "The email you entered could not be found.";
    case "auth/wrong-password":
      return "The password you entered is incorrect.";
    case "auth/too-many-requests":
      return "You've enterred the incorrect password too many times. Please wait 10 minutes before trying again.";
    case "auth/email-already-in-use":
      return "The email you entered is already in use.";
    default:
      return "An unknown error occured";
  }
}
