import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Checkbox,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import parse from "html-react-parser";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Lightbox } from "../common/CustomLightbox";

const copyToClipboard = (str) => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText)
    return navigator.clipboard.writeText(str);
  return Promise.reject("The Clipboard API is not available.");
};

export default function RemindersForm({ open, handleClose, sel }) {
  const speaker = sel?.sermonSpeaker;
  const reader1 = sel?.reader1;
  const reader2 = sel?.reader2;
  const communion1 = sel?.communion1;
  const communion2 = sel?.communion2;
  const emceeLead = sel?.emceeLead;
  const musicLead = sel?.musicLead;
  const audioLead = sel?.audioLead;
  const livestreamLead = sel?.livestreamLead;
  const slidesLead = sel?.slidesLead;
  const snacks1 = sel?.snackPerson1;
  const snacks2 = sel?.snackPerson2;
  const snacks3 = sel?.snackPerson3;
  const childrensMinistryLead = sel?.childrensMinistryLead;
  const welcomeLead = sel?.welcomeLead;

  const speakerName = speaker?.displayName || "(not assigned)";
  const reader1Name = reader1?.displayName || "(not assigned)";
  const reader2Name = reader2?.displayName || "(not assigned)";
  const passage1 = sel?.passage1 || null;
  const passage1String = passage1 || "(no passage)";

  const passage2 = sel?.passage2 || null;
  const passage2String = passage2 || "(no passage)";

  const communion1Name = communion1?.displayName || "(not assigned)";
  const communion2Name = communion2?.displayName || "(not assigned)";
  const emceeName = emceeLead?.displayName || "(not assigned)";
  const musicLeadName = musicLead?.displayName || "(not assigned)";
  const audioLeadName = audioLead?.displayName || "(not assigned)";
  const livestreamLeadName = livestreamLead?.displayName || "(not assigned)";
  const slidesLeadName = slidesLead?.displayName || "(not assigned)";
  const childrensMinistryLeadName =
    childrensMinistryLead?.displayName || "(not assigned)";
  const welcomeLeadName = welcomeLead?.displayName || "(not assigned)";
  const snacks1Name = snacks1?.displayName || "(not assigned)";
  const snacks2Name = snacks2?.displayName || "(not assigned)";
  const snacks3Name = snacks3?.displayName || "(not assigned)";

  const infoList = [
    speaker,
    reader1,
    reader2,
    communion1,
    communion2,
    emceeLead,
    musicLead,
    audioLead,
    livestreamLead,
    slidesLead,
    childrensMinistryLead,
    welcomeLead,
    snacks1,
    snacks2,
    snacks3,
  ];

  const displayNames = infoList.map((info) => info?.displayName);
  const uniqueDisplayNames = removeDuplicates(displayNames);

  const emails = infoList.map((info) => info?.email);
  const uniqueEmails = removeDuplicates(emails);

  const [templateIndex, setTemplateIndex] = useState(0);
  const [checked, setChecked] = useState([]);

  const handleTemplateIndex = (event) => setTemplateIndex(event.target.value);

  function handleToggle(value) {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  }

  function removeDuplicates(names) {
    const uniqueNames = [...new Set(names)];
    return uniqueNames.filter((name) => name); // removes undefined elements from array
  }

  const tidiedList = uniqueDisplayNames.map((name) => getAssociatedEmail(name));

  function getAssociatedEmail(name) {
    const ind = displayNames.findIndex((el) => el === name);
    const associatedEmail = infoList[ind]?.email || null;
    return {
      email: associatedEmail,
      displayName: name || "couldn't find",
    };
  }

  function toggleCheckAll() {
    if (checked.length === uniqueEmails.length) {
      setChecked([]);
    } else {
      setChecked(uniqueEmails);
    }
  }

  useEffect(resetForm, [open]);
  useEffect(
    () => setChecked(uniqueEmails),
    //eslint-disable-next-line
    [open]
  );

  const mailingList = checked.join(", ");

  const templates = [
    {
      selectHandle: "template1",
      title: "Friendly reminder to this week's Sunday Servers!",
      text:
        "Hello everyone,<br><br>Thank you for volunteering with Sunday Service! <br><br> Below are the assigned servers for this Sunday, please login to <u>https://ckmembers.org</u> by Saturday 12 PM to confirm or let me know if you need help finding a substitute. Please remember to arrive 15 minutes before service (9:45 AM) for team prayer." +
        "<ul>" +
        `<li>sermon: ${speakerName}</li>` +
        `<li>reader 1: ${reader1Name} | ${passage1String}</li>` +
        `<li>reader 2: ${reader2Name} | ${passage2String}</li>` +
        `<li>communion 1: ${communion1Name}</li>` +
        `<li>communion 2: ${communion2Name}</li>` +
        `<li>emcee: ${emceeName}</li>` +
        `<li>music lead: ${musicLeadName}</li>` +
        `<li>audio: ${audioLeadName}</li>` +
        `<li>livestream: ${livestreamLeadName}</li>` +
        `<li>slides: ${slidesLeadName}</li>` +
        `<li>welcome team: ${welcomeLeadName}</li>` +
        `<li>children's ministry: ${childrensMinistryLeadName}</li>` +
        `<li>snacks 1: ${snacks1Name}</li>` +
        `<li>snacks 2: ${snacks2Name}</li>` +
        `<li>coffee: ${snacks3Name}</li>` +
        "</ul>",
      plaintext:
        "Hello everyone, BREAK" +
        " " +
        "Thank you for volunteering with Sunday Service! Below are the assigned servers for this Sunday, please login to https://ckmembers.org by Saturday 12 PM to confirm or let me know if you need help finding a substitute. BREAK" +
        " " +
        `sermon: ${speakerName} BREAK` +
        " " +
        `reader 1: ${reader1Name} | ${passage1String} BREAK` +
        " " +
        `reader 2: ${reader2Name} | ${passage2String} BREAK` +
        " " +
        `communion 1: ${communion1Name} BREAK` +
        " " +
        `communion 2: ${communion2Name} BREAK` +
        " " +
        `emcee: ${emceeName} BREAK` +
        " " +
        `music lead: ${musicLeadName} BREAK` +
        " " +
        `audio: ${audioLeadName} BREAK` +
        " " +
        `livestream: ${livestreamLeadName} BREAK` +
        " " +
        `slides: ${slidesLeadName} BREAK` +
        " " +
        `welcome team: ${welcomeLeadName} BREAK` +
        " " +
        `children's ministry: ${childrensMinistryLeadName} BREAK` +
        " " +
        `snacks 1: ${snacks1Name} BREAK` +
        " " +
        `snacks 2: ${snacks2Name} BREAK` +
        " " +
        `drinks: ${snacks3Name}`,
    },
    {
      selectHandle: "template2",
      title: getIndividualTitle(checked, infoList, sel),
      text: getIndividualBody(
        checked,
        infoList,
        sel,
        passage1String,
        passage2String
      ),
      plaintext: getIndividualBody(
        checked,
        infoList,
        sel,
        passage1String,
        passage2String
      ),
    },
    {
      selectHandle: "template3",
      title: "Friendly Reminder - Please Confirm Your Sunday Server Assignment",
      text: "Hello! This is a follow-up reminder that you're signed up for this Sunday's service. Please login to https://ckmembers.org as soon as possible to confirm or let me know if you need help finding a substitute.",
      plaintext:
        "Hello! This is a follow-up reminder that you're signed up for this Sunday's service. Please login to https://ckmembers.org as soon as possible to confirm or let me know if you need help finding a substitute.",
    },
  ];

  const emailMessage = `mailto:${mailingList}?subject=${templates[templateIndex]?.title}&body=${templates[templateIndex]?.plaintext}`;

  const templateFieldStyle = {
    width: 550,
    position: "relative",
    p: 2,
    mb: 2,
    borderRadius: 2,
  };

  function SectionHeader({ title }) {
    return (
      <Typography
        variant="subtitle1"
        fontStyle="italic"
        color="text.secondary"
        sx={{ mb: 1 }}
      >
        {title}
      </Typography>
    );
  }

  function resetForm() {
    setChecked([]);
    setTemplateIndex(0);
  }

  if (!sel?.id) return null;

  return (
    <Lightbox open={open} onClose={handleClose}>
      <Box className="flex flex-row">
        <List
          sx={{
            width: "100%",
            maxWidth: 300,
            maxHeight: 600,
            overflow: "auto",
            bgcolor: "background.paper",
          }}
        >
          <ListItem disablePadding>
            <ListItemButton onClick={toggleCheckAll}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.length === uniqueEmails.length}
                />
              </ListItemIcon>
              <ListItemText primary="Select All" />
            </ListItemButton>
          </ListItem>
          <Divider />
          {tidiedList.map((value, ind) => {
            return (
              <ListItem key={`listitem${ind}`} disablePadding>
                <ListItemButton
                  onClick={() => handleToggle(value.email)}
                  // onClick={handleToggle(value.email)}
                  dense
                  disabled={!value.email}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(value.email) !== -1}
                      tabIndex={-1}
                      disableRipple
                      disabled={!value.email}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={value.displayName}
                    secondary={value.email || "(no email entered)"}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        <Box className="flex" sx={{ ml: 5, width: 800 }}>
          <Paper sx={{ p: 2 }} className="relative">
            <Box className="flex flex-space-between" sx={{ mb: 2 }}>
              <Typography variant="h5" sx={{ m: 1 }}>
                Email Template
              </Typography>

              <FormControl>
                <InputLabel id="demo-simple-select-label">Template</InputLabel>
                <Select
                  sx={{ width: 200 }}
                  label="Template"
                  value={templateIndex}
                  onChange={handleTemplateIndex}
                >
                  <MenuItem value={0}>weekly reminder</MenuItem>
                  <MenuItem value={1}>individual reminder</MenuItem>
                  <MenuItem value={2}>generic reminder</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {templateIndex === 1 && checked.length > 1 && (
              <Alert severity="info">
                This template assumes an individual recipient - please look over
                the title and body if you intend to use it for multiple people.
              </Alert>
            )}

            <Box sx={templateFieldStyle}>
              <SectionHeader title="Mailing List" />

              <Tooltip title="copy mailing list" placement="right">
                <IconButton
                  sx={{ position: "absolute", left: 100, top: 10 }}
                  onClick={() => copyToClipboard(mailingList)}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>

              {mailingList.length === 0 && (
                <Typography sx={{ color: "left" }}>
                  (no emails selected)
                </Typography>
              )}
              {mailingList.length > 0 && (
                <Typography variant="subtitle2" color="primary">
                  {mailingList}
                </Typography>
              )}
            </Box>
            <Box sx={templateFieldStyle}>
              <SectionHeader title="Title" />
              {templateIndex >= 0 && templates[templateIndex] && (
                <Tooltip title="copy title" placement="right">
                  <IconButton
                    sx={{ position: "absolute", left: 60, top: 10 }}
                    onClick={() =>
                      copyToClipboard(templates[templateIndex]?.title)
                    }
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              )}
              {templateIndex < 0 && (
                <Typography sx={{ color: "gray" }}>
                  (no template selected)
                </Typography>
              )}
              {templateIndex >= 0 && (
                <Typography>{templates[templateIndex]?.title}</Typography>
              )}
            </Box>
            <Box sx={templateFieldStyle}>
              <SectionHeader title="Body" />
              {templateIndex >= 0 && (
                <Tooltip title="copy body" placement="right">
                  <IconButton
                    sx={{ position: "absolute", left: 60, top: 10 }}
                    onClick={() =>
                      copyToClipboard(templates[templateIndex]?.plaintext)
                    }
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              )}
              {templateIndex < 0 && (
                <Typography sx={{ color: "gray" }}>
                  (no template selected)
                </Typography>
              )}
              {templateIndex >= 0 &&
                parse(templates[templateIndex]?.text || "")}
            </Box>

            <Box className="flex flex-end">
              <Link href={emailMessage} underline="none">
                Open Template In Mail Client
              </Link>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Lightbox>
  );
}

function getIndividualTitle(checked, infoList, sel) {
  if (!checked || checked.length === 0) return "";

  const selPerson = infoList.find((person) => person?.email === checked[0]);
  const selPersonIndex = infoList.findIndex(
    (person) => person?.email === checked[0]
  );

  const roles = [
    "sermonSpeaker",
    "reader1",
    "reader2",
    "communion1",
    "communion2",
    "emceeLead",
    "musicLead",
    "audioLead",
    "livestreamLead",
    "slidesLead",
    "welcomeLead",
    "childrensMinistryLead",
    "snackPerson1",
    "snackPerson2",
    "snackPerson3",
  ];

  const foundRole = roles.find((role) => sel[role]?.email === selPerson?.email);

  if (selPersonIndex < 0) return "";

  switch (foundRole) {
    case "sermonSpeaker":
      return "Still up for giving the sermon this Sunday?";
    case "reader1":
    case "reader2":
      return "Help read scripture this Sunday?";
    case "communion1":
    case "communion2":
      return "Help serve communion this Sunday?";
    case "emceeLead":
      return "Help emcee this Sunday?";
    case "musicLead":
      return "Help lead music this Sunday?";
    case "audioLead":
      return "Help with audio this Sunday?";
    case "livestreamLead":
      return "Help with livestream this Sunday?";
    case "slidesLead":
      return "Help with slides this Sunday?";
    case "childrensMinistryLead":
      return "Help with children's ministry this Sunday?";
    case "welcomeLead":
      return "Help welcome people this Sunday?";
    case "snackPerson1":
    case "snackPerson2":
      return "Help bring snacks this Sunday?";
    case "snackPerson3":
      return "Help bring coffee this Sunday?";
    default:
      return "";
  }
}

function getIndividualBody(
  checked,
  infoList,
  sel,
  passage1String,
  passage2String
) {
  if (!checked || checked.length === 0) return "";

  const selPerson = infoList.find((person) => person?.email === checked[0]);
  const selPersonIndex = infoList.findIndex(
    (person) => person?.email === checked[0]
  );

  const roles = [
    "sermonSpeaker",
    "reader1",
    "reader2",
    "communion1",
    "communion2",
    "emceeLead",
    "musicLead",
    "audioLead",
    "slidesLead",
    "welcomeLead",
    "childrensMinistryLead",
    "snackPerson1",
    "snackPerson2",
    "snackPerson3",
  ];

  const foundRole = roles.find((role) => sel[role]?.email === selPerson?.email);

  if (selPersonIndex < 0) return "";
  if (!foundRole) return "";

  switch (foundRole) {
    case "sermonSpeaker":
      return `Hi ${selPerson.firstName}, would you still be able to give the sermon speaker this week? Please login to https://ckmembers.org to confirm or let me know if you need a substitute.`;
    case "reader1":
      return `Hi ${selPerson.firstName}, would you still be willing to serve as a scripture reader this Sunday? Your assigned passage is ${passage1String}. Please login to https://ckmembers.org to confirm or let me know if you need a substitute.`;
    case "reader2":
      return `Hi ${selPerson.firstName}, would you still be willing to serve as a scripture reader this Sunday? Your assigned passage is ${passage2String}. Please login to https://ckmembers.org to confirm or let me know if you need a substitute.`;
    case "communion1":
    case "communion2":
      return `Hi ${selPerson.firstName}, would you still be willing to serve communion this Sunday? Please login to https://ckmembers.org to confirm or let me know if you need a substitute.`;
    case "emceeLead":
      return `Hi ${selPerson.firstName}, would you still be willing to serve as emcee this Sunday? Please login to https://ckmembers.org to confirm or let me know if you need a substitute.`;
    case "musicLead":
      return `Hi ${selPerson.firstName}, would you still be willing to lead the worship team this Sunday? Please login to https://ckmembers.org to confirm or let me know if you need a substitute.`;
    case "audioLead":
      return `Hi ${selPerson.firstName}, would you still be willing to help with audio this Sunday? Please login to https://ckmembers.org to confirm or let me know if you need a substitute.`;
    case "slidesLead":
      return `Hi ${selPerson.firstName}, would you still be willing to help with slides this Sunday? Please login to https://ckmembers.org to confirm or let me know if you need a substitute.`;
    case "welcomeLead":
      return `Hi ${selPerson.firstName}, would you still be willing to lead the welcoming team this Sunday? Please login to https://ckmembers.org to confirm or let me know if you need a substitute.`;
    case "childrensMinistryLead":
      return `Hi ${selPerson.firstName}, would you still be willing to lead children's ministry this Sunday? Please login to https://ckmembers.org to confirm or let me know if you need a substitute.`;
    case "snacksPerson1":
    case "snacksPerson2":
      return `Hi ${selPerson.firstName}, would you still be willing to bring snacks this Sunday? Please login to https://ckmembers.org to confirm or let me know if you need a substitute.`;
    case "snacksPerson3":
      return `Hi ${selPerson.firstName}, would you still be willing to bring drinks this Sunday? Please login to https://ckmembers.org to confirm or let me know if you need a substitute.`;
    default:
      return "";
  }
}
