import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebaseConfig";
import { Lightbox, LightboxHeader } from "../common/CustomLightbox";
import { TitleField } from "../common/CustomInputFields";
import { Box, TextField, Typography } from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { BtnContainer, SubmitBtn } from "../common/CustomButtons";

export function PollForm({ edit, handleClose, initVal, open }) {
  const { currentUser } = useAuth();
  const [title, setTitle] = useState(initVal?.title);
  const [startDate, setStartDate] = useState(initVal?.startDate);
  const [endDate, setEndDate] = useState(initVal?.endDate);
  const [releaseDate, setReleaseDate] = useState(initVal?.releaseDate);
  const [submitting, setSubmitting] = useState(false);

  const values = {
    title: title,
    startDate: startDate,
    endDate: endDate,
    releaseDate: releaseDate,
    editorIDs: [currentUser?.uid],
  };

  const handleTitle = (e) => setTitle(e.target.value);
  const handleStartDate = (date) => setStartDate(date);
  const handleEndDate = (date) => setEndDate(date);
  const handleReleaseDate = (date) => setReleaseDate(date);

  function handleSubmit() {
    if (edit) {
      updatePoll();
    } else {
      addPoll();
    }
  }

  function addPoll() {
    const ref = collection(db, "polls");

    setSubmitting(true);
    addDoc(ref, values)
      .then(() => setTimeout(() => handleClose(), 300))
      .catch((error) => console.log(error))
      .finally(() => setTimeout(() => setSubmitting(false), 200));
  }

  function updatePoll() {
    const ref = doc(db, "polls", initVal.id);
    setSubmitting(true);
    updateDoc(ref, values)
      .then(() => setTimeout(() => handleClose(), 300))
      .catch((error) => console.log(error))
      .finally(() => setTimeout(() => setSubmitting(false), 200));
  }

  function resetForm() {
    setTitle(initVal?.title || "");
    setStartDate(initVal?.startDate || new Date());
    setEndDate(initVal?.endDate || new Date());
  }
  useEffect(
    resetForm,
    //eslint-disable-next-line
    [open]
  );

  return (
    <Lightbox open={open} onClose={handleClose}>
      <LightboxHeader title="New Poll Form" />
      <TitleField onChange={handleTitle} value={title} />

      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        Poll available from:
      </Typography>

      <Box className="flex flex-center-all" sx={{ mb: 1 }}>
        <DatePicker
          label="start date"
          onChange={handleStartDate}
          renderInput={(params) => (
            <TextField {...params} sx={{ width: "180px" }} />
          )}
          value={startDate}
        />
        <TimePicker
          label="start time"
          onChange={(value) => setStartDate(value)}
          renderInput={(params) => (
            <TextField {...params} sx={{ ml: 2, width: "135px" }} />
          )}
          value={startDate}
        />
        <Typography sx={{ mx: 2 }}>to</Typography>
        <DatePicker
          label="end date"
          onChange={handleEndDate}
          renderInput={(params) => (
            <TextField {...params} sx={{ width: "180px" }} />
          )}
          value={endDate}
        />
        <TimePicker
          label="end time"
          onChange={(value) => setEndDate(value)}
          renderInput={(params) => (
            <TextField {...params} sx={{ ml: 2, width: "135px" }} />
          )}
          value={endDate}
        />
      </Box>

      <Typography sx={{ mt: 5, mb: 2 }}>
        Poll results available from:
      </Typography>

      <Box className="flex " sx={{ mb: 1 }}>
        <DatePicker
          label="release date"
          onChange={handleReleaseDate}
          renderInput={(params) => (
            <TextField {...params} sx={{ width: "180px" }} />
          )}
          value={releaseDate}
        />
        <TimePicker
          label="release time"
          onChange={(value) => setReleaseDate(value)}
          renderInput={(params) => (
            <TextField {...params} sx={{ ml: 2, width: "135px" }} />
          )}
          value={releaseDate}
        />
      </Box>
      <BtnContainer right>
        <SubmitBtn
          disabled={submitting}
          label="SAVE"
          onClick={handleSubmit}
          submitting={submitting}
        />
      </BtnContainer>
    </Lightbox>
  );
}
