import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardMedia,
  CardActions,
  CardHeader,
  CardContent,
  Divider,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  BackToHomeBtn,
  ConfirmAction,
  DescriptionField,
  EventNameField,
  ItemField,
  LocationField,
  PageHeader,
  SubmitButton,
} from "./CommonCpnts";
import { db } from "../config/firebaseConfig";
import {
  doc,
  addDoc,
  arrayUnion,
  deleteDoc,
  collection,
  getDocs,
  query,
  onSnapshot,
  orderBy,
  updateDoc,
} from "firebase/firestore";
import AddIcon from "@mui/icons-material/Add";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  getDateString,
  getTimeString,
  trimPersonInfo,
} from "../utils/utils.js";
import { LoginForm } from "./forms/LoginForm.jsx";
import { Lightbox, LightboxHeader } from "./common/CustomLightbox";
import { SubmitBtn, BtnContainer } from "./common/CustomButtons";
import {
  DirectoryAutocomplete,
  PersonChip,
  PendingApproval,
} from "./CommonCpnts";
import { CategoryField } from "./common/CustomInputFields";

export default function SpecialEvents({ currentUser }) {
  const [events, setEvents] = useState([]);
  const [open, setOpen] = useState(false);
  const [directory, setDirectory] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [edit, setEdit] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [openJoin, setOpenJoin] = useState(false);
  const [openItemSignup, setOpenItemSignup] = useState(false);
  const [signupIndex, setSignupIndex] = useState(-1);
  const memberInfo = directory?.find((el) => el.email === currentUser.email);
  const authorized = memberInfo?.permissions?.includes("c9b82b90ae572c840a76");

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const openLogin = () => setLoginOpen(true);
  const closeLogin = () => setLoginOpen(false);

  const handleOpenJoin = () => setOpenJoin(true);
  const handleCloseJoin = () => setOpenJoin(false);
  const handleOpenItemSignup = () => setOpenItemSignup(true);
  const handleCloseItemSignup = () => setOpenItemSignup(false);

  useEffect(() => {
    fetchDirectory();
  }, []);

  async function fetchDirectory() {
    const q = query(collection(db, "members"), orderBy("firstName", "asc"));
    const querySnapshot = await getDocs(q);
    const fetchedItems = [];
    querySnapshot.forEach((doc) => {
      fetchedItems.push({ userID: doc.id, ...doc.data() });
    });
    setDirectory(fetchedItems);
  }

  useEffect(() => {
    const q = query(collection(db, "events"), orderBy("startDate", "desc"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedItems = snapshot.docs.map((doc) => ({
        id: doc.id,
        eventName: doc.data().eventName || "",
        type: doc.data().type || "other",
        location: doc.data().location || "",
        description: doc.data().description || "",
        participants: doc.data().participants || [],
        signups: doc.data().signups?.sort(compare) || [],
        startDate: doc.data().startDate?.toDate() || new Date(),
        endDate: doc.data().endDate?.toDate() || new Date(),
        banner: doc.data().banner || null,
      }));
      setEvents(fetchedItems);
    });
    return unsubscribe;
  }, []);

  function compare(a, b) {
    if (a.category?.toLowerCase() < b.category?.toLowerCase()) {
      return -1;
    } else if (a.category > b.category) {
      return 1;
    } else return 0;
  }

  if (currentUser && memberInfo?.pendingApproval) {
    return (
      <PendingApproval title="Special Events" pageName="special events page" />
    );
  }

  if (!currentUser)
    return (
      <div className="page-container">
        <BackToHomeBtn />
        <PageHeader title="Special Events" />
        <Box className="flex flex-row" sx={{ ml: "10px" }}>
          <Typography display="inline">Please</Typography>
          <Link className="link" onClick={openLogin} sx={{ mx: 1 }}>
            <Typography>login</Typography>
          </Link>
          <Typography display="inline">to view special events.</Typography>
          <LoginForm open={loginOpen} handleClose={closeLogin} />
        </Box>
      </div>
    );

  return (
    <Box className="page-container">
      <BackToHomeBtn />
      <PageHeader title="Special Events" />
      {!currentUser && (
        <Box className="flex flex-row" sx={{ ml: "10px" }}>
          <Typography display="inline">Please</Typography>
          <Link className="link" onClick={openLogin} sx={{ mx: 1 }}>
            <Typography>login</Typography>
          </Link>
          <Typography display="inline">to edit special events</Typography>
          <LoginForm open={loginOpen} handleClose={closeLogin} />
        </Box>
      )}
      <Box
        className="flex flex-column flex-center-all"
        sx={{ p: 3, minWidth: "620px" }}
      >
        {events.map((info) => (
          <EventInfoCard
            authorized={authorized}
            currentUser={currentUser}
            info={info}
            handleOpen={handleOpen}
            handleOpenJoin={handleOpenJoin}
            handleOpenItemSignup={handleOpenItemSignup}
            key={info.id}
            setSelectedEvent={setSelectedEvent}
            setSignupIndex={setSignupIndex}
            setEdit={setEdit}
          />
        ))}
        {authorized && (
          <Button
            onClick={() => {
              handleOpen();
              setSelectedEvent(null);
              setEdit(false);
            }}
            startIcon={<AddIcon />}
            sx={{ mb: 10, mt: 5 }}
          >
            ADD EVENT
          </Button>
        )}
        <SpecialEventForm
          authorized={authorized}
          key={selectedEvent?.id}
          handleClose={handleClose}
          open={open}
          initialValues={selectedEvent}
          edit={edit}
        />
        <JoinEvent
          directory={directory}
          handleClose={handleCloseJoin}
          open={openJoin}
          initVal={selectedEvent}
        />
        <ItemSignUpForm
          directory={directory}
          edit={edit}
          info={selectedEvent}
          handleClose={handleCloseItemSignup}
          open={openItemSignup}
          signupIndex={signupIndex}
          setSignupIndex={setSignupIndex}
        />
      </Box>
    </Box>
  );
}

function EventInfoCard({
  authorized,
  currentUser,
  info,
  handleOpen,
  handleOpenJoin,
  handleOpenItemSignup,
  setEdit,
  setSelectedEvent,
  setSignupIndex,
}) {
  const dateString =
    getDateString(info.startDate) +
    " at " +
    getTimeString(info.startDate) +
    " — " +
    getDateString(info.endDate) +
    " at " +
    getTimeString(info.endDate);

  function deleteParticipant(participant) {
    const remaining = info.participants.filter(
      (el) => el.displayName !== participant.displayName
    );

    const eventRef = doc(db, "events", info.id);

    updateDoc(eventRef, {
      participants: remaining,
    }).catch((error) => console.log(error.message));
  }

  function removeSignupItem(index) {
    const updated = info.signups.filter((el, ind) => ind !== index);
    const eventRef = doc(db, "events", info.id);
    updateDoc(eventRef, {
      signups: updated,
    }).catch((error) => console.log(error.message));
  }

  const rowStyle = {
    verticalAlign: "top",
  };
  const cellStyle = {
    textAlign: "left",
    width: "78px",
    paddingRight: "15px",
    paddingLeft: "25px",
    height: "40px",
  };

  const cellStyle2 = {
    width: currentUser ? "150px" : "210px",
  };

  const cellStyle3 = {
    width: currentUser ? "170px" : "240px",
  };

  const tableStyle1 = {
    textAlign: "center",
    marginTop: "10px",
  };

  return (
    <Card sx={{ m: 1, width: 700 }} className="relative">
      <Actions
        authorized={authorized}
        currentUser={currentUser}
        info={info}
        handleOpen={handleOpen}
        setSelectedEvent={setSelectedEvent}
        setEdit={setEdit}
      />
      <CardHeader title={info.eventName} />
      {info.banner && <CardMedia component="img" image={info.banner.url} />}
      <CardContent>
        <Typography sx={{ ml: 3 }} variant="h6">
          Details
        </Typography>
        <table>
          <tbody>
            <tr style={rowStyle}>
              <td style={cellStyle}>
                <Typography fontStyle="italic">type:</Typography>
              </td>
              <td>
                <Typography>{info.type}</Typography>
              </td>
            </tr>
            <tr style={rowStyle}>
              <td style={cellStyle}>
                <Typography fontStyle="italic">when:</Typography>
              </td>
              <td>
                <Typography>{dateString}</Typography>
              </td>
            </tr>
            <tr style={rowStyle}>
              <td style={cellStyle}>
                <Typography fontStyle="italic">where:</Typography>
              </td>
              <td>
                <Typography>{info.location}</Typography>
              </td>
            </tr>
            <tr style={rowStyle}>
              <td style={cellStyle}>
                <Typography fontStyle="italic">description:</Typography>
              </td>
              <td style={{ paddingBottom: "25px" }}>
                <Typography>{info.description}</Typography>
              </td>
            </tr>

            <tr style={rowStyle}>
              <td style={cellStyle}>
                <Typography sx={{ paddingTop: "2px" }} fontStyle="italic">
                  participants:
                </Typography>
              </td>
              <td>
                {info.participants.length === 0 && (
                  <Typography color="text.secondary">
                    (no participants yet)
                  </Typography>
                )}

                {info.participants?.map((participant) => (
                  <PersonChip
                    currentUser={currentUser}
                    key={participant.displayName}
                    person={participant}
                    handleDelete={deleteParticipant}
                  />
                ))}
              </td>
            </tr>
          </tbody>
        </table>
      </CardContent>

      {currentUser && (
        <CardActions className="flex flex-justify-center" sx={{ mb: 3 }}>
          <Button
            sx={{ width: "45%" }}
            variant="contained"
            onClick={() => {
              handleOpenJoin();
              setSelectedEvent(info);
            }}
          >
            Join
          </Button>
        </CardActions>
      )}
      <Divider sx={{ my: 2 }} />
      <CardContent>
        <Typography sx={{ ml: 3 }} variant="h6">
          Sign Ups
        </Typography>
        <table style={tableStyle1}>
          {info?.signups?.length > 0 && (
            <thead>
              <tr>
                <td style={cellStyle2}>
                  <Typography fontStyle="italic">category</Typography>
                </td>
                <td style={cellStyle3}>
                  <Typography fontStyle="italic">item</Typography>
                </td>
                <td style={cellStyle2}>
                  <Typography fontStyle="italic">person</Typography>
                </td>
                {currentUser && (
                  <>
                    <td></td>
                    <td></td>
                  </>
                )}
              </tr>
            </thead>
          )}
          <tbody>
            {info?.signups?.map((el, ind) => (
              <tr key={`${info.id}-item-${ind}`}>
                <td>{el.category}</td>
                <td>{el.item}</td>
                <td>{el?.person?.displayName}</td>
                {currentUser && (
                  <>
                    <td>
                      <Button
                        onClick={() => {
                          handleOpenItemSignup();
                          setEdit(true);
                          setSelectedEvent(info);
                          setSignupIndex(ind);
                        }}
                      >
                        EDIT
                      </Button>
                    </td>
                    <td>
                      <Button onClick={() => removeSignupItem(ind)}>
                        REMOVE
                      </Button>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {currentUser && (
          <BtnContainer center>
            <Button
              onClick={() => {
                handleOpenItemSignup();
                setEdit(false);
                setSelectedEvent(info);
              }}
              sx={{ width: "45%" }}
              startIcon={<AddIcon />}
            >
              ADD ITEM
            </Button>
          </BtnContainer>
        )}
      </CardContent>
    </Card>
  );
}

function Actions({
  authorized,
  currentUser,
  info,
  handleOpen,
  setSelectedEvent,
  setEdit,
}) {
  const [confirmActionOpen, setConfirmActionOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const openConfirmAction = () => setConfirmActionOpen(true);
  const closeConfirmAction = () => setConfirmActionOpen(false);

  function deleteEvent() {
    if (!authorized) return;
    const eventRef = doc(db, "events", info.id);
    setSubmitting(true);
    deleteDoc(eventRef)
      .catch((error) => console.log(error))
      .finally(() => setSubmitting(false));
  }

  const editClearBox = { position: "absolute", top: 15, right: 5 };

  if (!currentUser || !authorized) return null;

  return (
    <Stack direction="row" sx={editClearBox}>
      <Button
        onClick={() => {
          setSelectedEvent(info);
          handleOpen();
          setEdit(true);
        }}
      >
        Edit
      </Button>
      <Divider orientation="vertical" variant="middle" flexItem />
      <ConfirmAction
        btnLabel="DELETE"
        handleAction={deleteEvent}
        handleClose={closeConfirmAction}
        open={confirmActionOpen}
        submitting={submitting}
        title="Delete Event"
      >
        <Typography>
          Delete <strong>{info?.eventName}</strong> and all associated
          information?
        </Typography>
      </ConfirmAction>
      <Button onClick={openConfirmAction}>DELETE</Button>
    </Stack>
  );
}

function JoinEvent({ directory, initVal, open, handleClose }) {
  const [person, setPerson] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const clean = !person?.displayName;

  async function addParticipant() {
    const tidiedPerson = trimPersonInfo(person);
    setSubmitting(true);
    updateDoc(doc(db, "events", initVal.id), {
      participants: arrayUnion(tidiedPerson),
    })
      .catch((error) => console.log(error))
      .finally(() => {
        setTimeout(() => {
          setSubmitting(false);
          handleClose();
          // resetForm();
        }, 300);
      });
  }

  return (
    <Lightbox open={open} onClose={handleClose}>
      <LightboxHeader title={`Join ${initVal?.eventName}`} center />
      <DirectoryAutocomplete
        directory={directory}
        label="name"
        setState={setPerson}
        value={person?.displayName || ""}
      />
      <BtnContainer right>
        <SubmitBtn
          disabled={clean || submitting}
          label="JOIN"
          onClick={addParticipant}
          submitting={submitting}
        />
      </BtnContainer>
    </Lightbox>
  );
}

function SpecialEventForm({
  authorized,
  edit,
  handleClose,
  open,
  initialValues,
}) {
  const [type, setType] = useState(initialValues?.type || "");
  const [description, setDescription] = useState(
    initialValues?.description || ""
  );
  const [eventName, setEventName] = useState(initialValues?.eventName || "");
  const [location, setLocation] = useState(initialValues?.location || "");
  const [startDate, setStartDate] = useState(
    initialValues?.startDate || new Date()
  );
  const [endDate, setEndDate] = useState(initialValues?.endDate || new Date());
  const [submitting, setSubmitting] = useState(false);

  const handleType = (e) => setType(e.target.value);
  const handleEventName = (e) => setEventName(e.target.value);
  const handleStartDate = (date) => setStartDate(date);
  const handleEndDate = (date) => setEndDate(date);
  const handleLocation = (e) => setLocation(e.target.value);
  const handleDescription = (e) => setDescription(e.target.value);

  const values = {
    type: type,
    startDate: startDate,
    endDate: endDate,
    eventName: eventName,
    description: description,
    location: location,
  };

  async function addEvent() {
    if (!authorized) return;
    setSubmitting(true);
    addDoc(collection(db, "events"), values)
      .then(() => setTimeout(() => handleClose(), 300))
      .catch((error) => console.log(error))
      .finally(() => setTimeout(() => setSubmitting(false), 200));
  }

  async function updateEvent() {
    if (!authorized) return;
    const eventRef = doc(db, "events", initialValues.id);
    setSubmitting(true);
    updateDoc(eventRef, {
      ...values,
    })
      .then(() => setTimeout(() => handleClose(), 300))
      .catch((error) => console.log(error))
      .finally(() => setTimeout(() => setSubmitting(false), 200));
  }

  function resetForm() {
    setType(initialValues?.type);
    setDescription(initialValues?.description);
    setEventName(initialValues?.eventName);
    setLocation(initialValues?.location);
    setStartDate(initialValues?.startDate);
    setEndDate(initialValues?.endDate);
  }

  useEffect(
    resetForm,
    //eslint-disable-next-line
    [open]
  );

  return (
    <Lightbox open={open} onClose={handleClose}>
      <LightboxHeader title={edit ? "Update Event" : "Add Event"} />
      <FormControl fullWidth>
        <InputLabel>Type</InputLabel>
        <Select label="type" value={type} onChange={handleType} sx={{ mb: 2 }}>
          <MenuItem value="retreat">retreat</MenuItem>
          <MenuItem value="forum">forum</MenuItem>
          <MenuItem value="gathering">gathering</MenuItem>
          <MenuItem value="other">other</MenuItem>
        </Select>
      </FormControl>
      <Box className="flex flex-center-all" sx={{ mb: 1 }}>
        <DatePicker
          label="start date"
          onChange={handleStartDate}
          renderInput={(params) => (
            <TextField {...params} sx={{ width: "180px" }} />
          )}
          value={startDate}
        />
        <TimePicker
          label="start time"
          value={startDate}
          onChange={(value) => setStartDate(value)}
          renderInput={(params) => (
            <TextField {...params} sx={{ ml: 2, width: "135px" }} />
          )}
        />
        <Typography sx={{ mx: 2 }}>to</Typography>
        <DatePicker
          label="end date"
          onChange={handleEndDate}
          renderInput={(params) => (
            <TextField {...params} sx={{ width: "180px" }} />
          )}
          value={endDate}
        />
        <TimePicker
          label="end time"
          value={endDate}
          onChange={(value) => setEndDate(value)}
          renderInput={(params) => (
            <TextField {...params} sx={{ ml: 2, width: "135px" }} />
          )}
        />
      </Box>
      <EventNameField onChange={handleEventName} value={eventName} />
      <LocationField onChange={handleLocation} value={location} />
      <DescriptionField onChange={handleDescription} value={description} />

      <Box className="flex flex-row flex-end">
        <SubmitButton
          onClick={edit ? updateEvent : addEvent}
          submitting={submitting}
          title={edit ? "UPDATE EVENT" : "ADD EVENT"}
        />
      </Box>
    </Lightbox>
  );
}

function ItemSignUpForm({
  edit,
  directory,
  info,
  open,
  handleClose,
  signupIndex,
  setSignupIndex,
}) {
  const cleanValues = { person: null, item: "", category: "" };
  const initVal = edit ? info?.signups[signupIndex] : cleanValues;
  const [person, setPerson] = useState(initVal?.person || null);
  const [item, setItem] = useState(initVal?.item || "");
  const [category, setCategory] = useState(initVal?.category || "");
  const [submitting, setSubmitting] = useState(false);

  const values = {
    person: person || null,
    item: item || "",
    category: category || "",
  };
  const handleItem = (e) => setItem(e.target.value);
  const handleCategory = (e) => setCategory(e.target.value);

  function resetForm() {
    setPerson(initVal?.person || null);
    setItem(initVal?.item || "");
    setCategory(initVal?.category || "");
  }

  useEffect(
    resetForm,
    //eslint-disable-next-line
    [open]
  );

  function handleSubmit() {
    edit ? updateItem() : addItem();
  }

  function updateItem() {
    const updated = info.signups.map((el, ind) =>
      ind === signupIndex ? values : el
    );

    setSubmitting(true);

    const eventRef = doc(db, "events", info.id);
    updateDoc(eventRef, {
      signups: updated,
    })
      .then(() => setSignupIndex(-1))
      .then(() => setTimeout(() => handleClose(), 300))
      .catch((error) => console.log(error))
      .finally(() => setTimeout(() => setSubmitting(false), 200));
  }

  function addItem() {
    const values = {
      person: trimPersonInfo(person),
      item: item,
      category: category,
    };

    const updated = [...info.signups, values];
    setSubmitting(true);
    updateDoc(doc(db, "events", info.id), {
      signups: updated,
    })
      .then(() => setTimeout(() => handleClose(), 300))
      .catch((error) => console.log(error))
      .finally(() => setTimeout(() => setSubmitting(false), 200));
  }

  return (
    <Lightbox open={open} onClose={handleClose}>
      <LightboxHeader title="Sign up to bring or contribute..." center />
      <CategoryField value={category} onChange={handleCategory} />
      <ItemField value={item} onChange={handleItem} />
      <DirectoryAutocomplete
        directory={directory}
        label="name"
        setState={setPerson}
        value={person?.displayName || ""}
      />
      <BtnContainer right>
        <SubmitBtn
          disabled={submitting}
          label="SAVE"
          onClick={handleSubmit}
          submitting={submitting}
        />
      </BtnContainer>
    </Lightbox>
  );
}
