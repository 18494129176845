import { useEffect, useState } from "react";
import { Lightbox, LightboxHeader } from "../common/CustomLightbox";
import {
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Typography,
} from "@mui/material";
import MultipleChoiceBuilder from "./MultipleChoiceBuilder.jsx";
import ShortAnswerBuilder from "./ShortAnswerBuilder.jsx";

export default function QuestionBuilder({
  edit,
  open,
  handleClose,
  poll,
  setSelPoll,
  question,
}) {
  const initialType = edit ? question?.type : "";
  const [type, setType] = useState(initialType);

  const selectType = (event) => setType(event.target.value);

  function resetForm() {
    setTimeout(() => setType(initialType), 300);
  }

  useEffect(
    resetForm,
    //eslint-disable-next-line
    [edit, open]
  );

  return (
    <Lightbox open={open} onClose={handleClose}>
      <LightboxHeader title="Add Question" />

      <TypeSelect type={type} handleChange={selectType} />

      <HelperText type={type} />
      <Box className="relative">
        <Main
          edit={edit}
          handleClose={handleClose}
          poll={poll}
          setSelPoll={setSelPoll}
          question={question}
          type={type}
        />
      </Box>
    </Lightbox>
  );
}

function Main({ edit, handleClose, poll, question, type, setSelPoll }) {
  switch (type) {
    case "multiple choice":
      return (
        <MultipleChoiceBuilder
          edit={edit}
          handleClose={handleClose}
          poll={poll}
          question={question}
        />
      );
    case "short answer":
      return (
        <ShortAnswerBuilder
          edit={edit}
          handleClose={handleClose}
          poll={poll}
          question={question}
        />
      );

    default:
      return null;
  }
}

function TypeSelect({ type, handleChange }) {
  return (
    <Box sx={{ width: 250, mb: 2 }}>
      <FormControl fullWidth>
        <InputLabel>Question Type</InputLabel>
        <Select value={type} label="Question Type" onChange={handleChange}>
          <MenuItem value={"multiple choice"}>Multiple Choice</MenuItem>
          <MenuItem value={"short answer"}>Short Answer</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

function HelperText({ type, subtype }) {
  if (!type)
    return (
      <Box height="100px" sx={{ pt: 10 }}>
        <Typography color="primary" align="center">
          Please Select a Question Type
        </Typography>
      </Box>
    );
  else return null;
}
