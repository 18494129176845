import { useEffect, useState } from "react";
import { db } from "../../config/firebaseConfig.js";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { Box, Typography, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { SubmitButton } from "../CommonCpnts";
import { Lightbox, LightboxHeader } from "../common/CustomLightbox.jsx";

export default function PickDateForm({ date, edit, open, handleClose, sel }) {
  const initialStartTime = new Date(date.setHours(10, 0, 0));
  const initialEndTime = new Date(date.setHours(11, 30, 0));
  const [startDate, setStartDate] = useState(initialStartTime);
  const [endDate, setEndDate] = useState(initialEndTime);

  const [submitting, setSubmitting] = useState(false);

  const values = {
    type: "service",
    startDate: startDate,
    endDate: endDate,
  };

  function addService() {
    addDoc(collection(db, "services"), values)
      .then(() => setTimeout(() => handleClose(), 300))
      .catch((error) => console.log(error))
      .finally(() => setTimeout(() => setSubmitting(false), 200));
  }

  function updateService() {
    updateDoc(doc(db, "services", sel.id), values)
      .then(() => setTimeout(() => handleClose(), 300))
      .catch((error) => console.log(error))
      .finally(() => setTimeout(() => setSubmitting(false), 200));
  }

  function handleSubmit() {
    edit ? updateService() : addService();
  }

  function resetForm() {
    setStartDate(initialStartTime);
    setEndDate(initialEndTime);
  }

  useEffect(
    resetForm,
    // eslint-disable-next-line
    [open]
  );

  return (
    <Lightbox open={open} onClose={handleClose}>
      <LightboxHeader
        title={edit ? "Edit Date / Time" : "Schedule New Service"}
        left
      />
      <Box style={{ marginTop: "30px", marginBottom: "30px" }}>
        <DatePicker
          label="date"
          value={startDate}
          onChange={(value) => setStartDate(value)}
          renderInput={(params) => <TextField {...params} />}
        />
      </Box>

      <Box className="flex flex-center-all">
        <TimePicker
          label="start time"
          value={startDate}
          onChange={(value) => setStartDate(value)}
          renderInput={(params) => <TextField {...params} />}
        />
        <Typography sx={{ mx: 3 }}>to</Typography>

        <TimePicker
          label="end time"
          value={endDate}
          onChange={(value) => setEndDate(value)}
          renderInput={(params) => <TextField {...params} />}
        />
      </Box>
      <Box className="flex flex-end">
        <SubmitButton
          onClick={handleSubmit}
          submitting={submitting}
          title="SAVE"
        />
      </Box>
    </Lightbox>
  );
}
