import { useEffect, useState } from "react";
import { BackToHomeBtn, PageHeader } from "./CommonCpnts";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Link,
  Radio,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  getShortDateString,
  getTimeString,
  twoWeeksLater,
} from "../utils/utils";
import QuestionBuilder from "./forms/QuestionBuilder.jsx";
import { LoginForm } from "./forms/LoginForm.jsx";
import {
  fetchAllPollSubmissions,
  fetchPolls,
  fetchUserPollSubmission,
  saveUserPollResponse,
  updatePollQuestions,
} from "../firestoreClient";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { PollForm } from "./forms/PollForm.jsx";
import { BtnContainer } from "./common/CustomButtons.jsx";

export default function VotingPage({ currentUser }) {
  const [open, setOpen] = useState(false);
  const [qFormOpen, setQFormOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [polls, setPolls] = useState([]);
  const [selPoll, setSelPoll] = useState(null);
  const [loginOpen, setLoginOpen] = useState(false);
  const [response, setResponse] = useState(null);
  const [view, setView] = useState("loading");

  function handleOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
    setEdit(false);
  }
  const openLogin = () => setLoginOpen(true);
  const closeLogin = () => setLoginOpen(false);
  const openQForm = () => setQFormOpen(true);
  const closeQForm = () => setQFormOpen(false);

  const cleanValues = {
    title: "",
    startDate: new Date(),
    endDate: twoWeeksLater(),
    releaseDate: twoWeeksLater(),
  };

  function handlePollSelect(e) {
    setSelPoll(e.target.value);
  }

  useEffect(() => {
    fetchPolls(setPolls);
  }, []);

  useEffect(
    () => {
      if (!selPoll) return;
      const selected = polls.find((poll) => poll.id === selPoll.id);
      setSelPoll(selected);
    },
    //eslint-disable-next-line
    [polls]
  );

  useEffect(() => {
    if (!currentUser?.uid) {
      return;
    }
    fetchUserPollSubmission(selPoll, currentUser.uid, setResponse, setView);
  }, [selPoll?.id]);

  if (!currentUser)
    return (
      <div className="page-container">
        <BackToHomeBtn />
        <PageHeader title="Voting Center" />
        <Box className="flex flex-row" sx={{ ml: "10px" }}>
          <Typography display="inline">Please</Typography>
          <Link className="link" onClick={openLogin} sx={{ mx: 1 }}>
            <Typography>login</Typography>
          </Link>
          <Typography display="inline">to view the voting center.</Typography>
          <LoginForm open={loginOpen} handleClose={closeLogin} />
        </Box>
      </div>
    );

  return (
    <div className="page-container">
      <BackToHomeBtn />
      <PageHeader title="Voting Center" />
      <br />
      <PollForm
        edit={edit}
        initVal={edit ? selPoll : cleanValues}
        handleClose={handleClose}
        open={open}
      />
      <QuestionBuilder
        open={qFormOpen}
        handleClose={closeQForm}
        poll={selPoll}
      />
      <Box className="flex flex-column">
        <Box className="flex flex-row">
          <FormControl sx={{ minWidth: "300px" }}>
            <InputLabel>Poll</InputLabel>
            <Select
              label="Poll"
              value={selPoll?.id ? selPoll : ""}
              onChange={handlePollSelect}
            >
              {polls.map((poll) => (
                <MenuItem key={poll.id} value={poll}>
                  {poll.title || "(no title)"}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            onClick={() => {
              handleOpen();
              setEdit(false);
            }}
            startIcon={<AddIcon />}
            sx={{ ml: "10px" }}
          >
            ADD POLL
          </Button>
        </Box>
        <Box sx={{ my: 3 }}>
          {selPoll ? null : (
            <Box
              sx={{
                width: "600px",
                border: "1px solid #026c8c",
                borderRadius: "8px",
                py: "30px",
              }}
            >
              <Typography align="center" variant="h5" color="primary">
                Please select a poll from the list
              </Typography>
            </Box>
          )}

          {view === "poll" && (
            <PollCard
              currentUser={currentUser}
              handleOpen={handleOpen}
              openQForm={openQForm}
              response={response}
              setEdit={setEdit}
              selPoll={selPoll}
              setResponse={setResponse}
              setView={setView}
            />
          )}
          {view === "submitted" && <SubmittedCard setView={setView} />}
          {view === "results" && (
            <ResultsCard selPoll={selPoll} setView={setView} />
          )}
        </Box>
      </Box>
    </div>
  );
}

function PollCard({
  currentUser,
  handleOpen,
  openQForm,
  response,
  setEdit,
  selPoll,
  setResponse,
  setView,
}) {
  const isEditor = selPoll?.editorIDs?.includes(currentUser?.uid) || false;
  const startDateString = selPoll
    ? getShortDateString(selPoll?.startDate) +
      ", " +
      getTimeString(selPoll?.startDate)
    : null;

  const endDateString = selPoll
    ? getShortDateString(selPoll?.endDate) +
      ", " +
      getTimeString(selPoll?.endDate)
    : null;

  function handleSubmit() {
    saveUserPollResponse(selPoll, response, currentUser, setView);
  }

  function handleDeleteQuestion(question) {
    const updated = selPoll?.questions?.filter(
      (elem) => elem?.id === question.id
    );
    updatePollQuestions(selPoll, updated);
  }

  if (!selPoll) return;
  if (!response) return;

  return (
    <Card sx={{ width: "600px", position: "relative" }}>
      <CardContent>
        {isEditor ? (
          <div style={{ position: "absolute", top: "2px", right: "2px" }}>
            <Button
              onClick={() => {
                handleOpen();
                setEdit(true);
              }}
            >
              EDIT
            </Button>
          </div>
        ) : null}

        <Typography color="primary" variant="h4">
          {selPoll.title}
        </Typography>
        <Typography>{startDateString + " — " + endDateString}</Typography>

        <Divider sx={{ my: "10px" }} />
        {selPoll.questions?.map((question, qIndex) => (
          <div key={question.id}>
            <QuestionDisplay
              question={question}
              qIndex={qIndex}
              response={response}
              setResponse={setResponse}
            />

            {isEditor ? (
              <Button onClick={() => handleDeleteQuestion(question)}>
                DELETE
              </Button>
            ) : null}
          </div>
        ))}
        {isEditor ? (
          <>
            <br />
            <br />
            <Divider />
            <div className="flex flex-center-all">
              <Button onClick={openQForm} startIcon={<AddIcon />}>
                Add Question
              </Button>
            </div>
            <Divider />
          </>
        ) : null}

        <BtnContainer right>
          <Button onClick={handleSubmit} variant="contained">
            Submit
          </Button>
        </BtnContainer>
      </CardContent>
    </Card>
  );
}

function ResultsCard({ selPoll, setView }) {
  const [allResponses, setAllResponses] = useState([]);

  useEffect(() => {
    fetchAllPollSubmissions(selPoll, setAllResponses);
  }, []);

  return (
    <Card sx={{ width: "600px" }}>
      <Button
        onClick={() => setView("submitted")}
        startIcon={<ChevronLeftIcon />}
      >
        POLL
      </Button>
      <CardContent>
        {selPoll.questions?.map((question, ind) => (
          <Box sx={{ p: "10px" }}>
            <Typography variant="h6">Question {ind + 1}</Typography>
            <Typography sx={{ mb: "3px" }}>{question.prompt}</Typography>
            <QuestionResults question={question} allResponses={allResponses} />
          </Box>
        ))}
      </CardContent>
    </Card>
  );
}

function QuestionResults({ question, allResponses }) {
  const questionID = question?.id;

  const filteredArray = allResponses?.filter((response) =>
    response.hasOwnProperty(questionID)
  );

  const responses = filteredArray.map((elem) => elem[questionID]);

  function calcWidth(ind) {
    const totalVotes = responses.length;
    const voteCount = responses.filter((response) =>
      response.includes(ind)
    ).length;

    return `${(voteCount * 200) / totalVotes}px`;
  }

  function countVotes(ind) {
    const voteCount = responses.filter((response) =>
      response.includes(ind)
    ).length;

    if (voteCount === 1) {
      return "1 vote";
    } else {
      return `${voteCount} votes`;
    }
  }

  if (question.type === "multiple choice") {
    const freeResponses = filteredArray.map(
      (elem) => elem[`${questionID}-custom`]
    );
    return (
      <table>
        {question.answerChoices.map((choice, ind) => (
          <tr>
            <td style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              <Typography>{choice}</Typography>
            </td>
            <td>
              <div className="flex flex-row flex-align-center">
                <span
                  style={{
                    width: calcWidth(ind),
                    height: "20px",
                    backgroundColor: "skyblue",
                  }}
                ></span>
                <span style={{ paddingLeft: "30px" }}>{countVotes(ind)}</span>
              </div>
            </td>
          </tr>
        ))}
        {question.allowFreeResponse ? (
          <tr>
            <td style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              <Typography>other</Typography>
            </td>
            <td>
              <Typography sx={{ paddingLeft: "30px" }}>
                {freeResponses.join(", ")}
              </Typography>
            </td>
          </tr>
        ) : null}
      </table>
    );
  }
  if (question.type === "short answer") {
    return (
      <div>
        {responses.map((response, ind) => (
          <List>
            <ListItem>
              <ListItemText key={`${response}-ind`} primary={response} />
            </ListItem>
          </List>
        ))}
      </div>
    );
  } else return null;
}

function SubmittedCard({ setView }) {
  return (
    <Card sx={{ width: "600px" }}>
      <CardContent>
        <br />
        <Typography variant="h5">Thanks for taking the poll!</Typography>
        <Typography variant="h5">Your submission has been saved.</Typography>
        <br />
        <Box className="flex flex-column ">
          <Button
            onClick={() => setView("results")}
            sx={{ my: "5px" }}
            variant="contained"
            fullWidth
          >
            VIEW RESULTS
          </Button>
          <Button
            sx={{ my: "5px" }}
            onClick={() => setView("poll")}
            variant="outlined"
            fullWidth
          >
            EDIT SUBMISSION
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}

function QuestionDisplay({ question, qIndex, response, setResponse }) {
  if (!question) return;

  switch (question.type) {
    case "multiple choice":
      return (
        <MultipleChoice
          question={question}
          qIndex={qIndex}
          response={response}
          setResponse={setResponse}
        />
      );
    case "short answer":
      return (
        <ShortAnswer
          question={question}
          qIndex={qIndex}
          response={response}
          setResponse={setResponse}
        />
      );
    default:
      break;
  }
}

function MultipleChoice({ question, qIndex, response, setResponse }) {
  const keyName = `${question.id}`;
  const [freeResponse, setFreeResponse] = useState(
    response?.hasOwnProperty(`${keyName}-custom`)
      ? response[`${keyName}-custom`]
      : ""
  );

  const selectMany = question.allowSelectMany || false;
  const allowFreeResponse = question.allowFreeResponse || false;
  const answerChoices = allowFreeResponse
    ? [...question.answerChoices, "other"]
    : question.answerChoices;

  function handleFreeResponse(e) {
    setFreeResponse(e.target.value);
    const responseCopy = { ...response };
    const extendedKeyName = keyName + "-custom";
    responseCopy[extendedKeyName] = e.target.value;
    setResponse(responseCopy);
  }

  function handleChange(e, ind) {
    switch (selectMany) {
      case false:
        {
          const updated = [ind];
          const responseCopy = { ...response };
          responseCopy[keyName] = updated;
          setResponse(responseCopy);
        }

        break;
      default: {
        const responseCopy = { ...response };
        if (response[keyName].includes(ind)) {
          const updated = response[keyName].filter((el) => el !== ind);
          responseCopy[keyName] = updated.sort();
          setResponse(responseCopy);
        } else {
          const updated = [...response[keyName], ind];
          responseCopy[keyName] = updated.sort();
          setResponse(responseCopy);
        }
      }
    }
  }

  if (!response) return;

  if (!selectMany) {
    const selIndex = response[keyName]?.length > 0 ? response[keyName][0] : -1;
    return (
      <Box sx={{ my: 2 }}>
        <QuestionHeader qIndex={qIndex} />
        <Typography>{question.prompt}</Typography>
        {answerChoices?.map((el, ind) => (
          <Box
            className="flex flex-row flex-align-center"
            key={`question-${ind}`}
          >
            <Radio
              onChange={(e) => handleChange(e, ind)}
              checked={selIndex === ind}
            />
            <Typography>{el}</Typography>
          </Box>
        ))}
        {answerChoices[selIndex] === "other" ? (
          <Box style={{ paddingLeft: "40px", paddingRight: "40px" }}>
            <TextField
              placeholder="custom response"
              value={freeResponse}
              onChange={handleFreeResponse}
            />
          </Box>
        ) : null}
      </Box>
    );
  } else if (selectMany) {
    const selIndexes = response[keyName]?.length > 0 ? response[keyName] : [];
    return (
      <Box sx={{ my: 2 }}>
        <QuestionHeader qIndex={qIndex} />
        <Typography>{question.prompt}</Typography>
        {answerChoices?.map((el, ind) => (
          <Box
            className="flex flex-row flex-align-center"
            key={`question-${ind}`}
          >
            <Checkbox
              onChange={(e) => handleChange(e, ind)}
              checked={selIndexes?.includes(ind)}
            />
            <Typography>{el}</Typography>
          </Box>
        ))}
      </Box>
    );
  }
}

function ShortAnswer({ question, qIndex, response, setResponse }) {
  if (!response) return;

  const keyName = question.id;

  function handleChange(e) {
    const updated = e.target.value;
    const responseCopy = { ...response };
    responseCopy[keyName] = updated;
    setResponse(responseCopy);
  }

  return (
    <Box sx={{ my: 2 }}>
      <QuestionHeader qIndex={qIndex} />
      <Typography>{question.prompt}</Typography>
      <TextField onChange={handleChange} value={response[keyName]} />
    </Box>
  );
}

function QuestionHeader({ qIndex }) {
  return <Typography variant="h6">Question {qIndex + 1}</Typography>;
}
