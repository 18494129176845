import { Box, Button, CircularProgress } from "@mui/material";

export function SubmitBtn({
  disabled,
  fullWidth,
  onClick,
  submitting,
  label,
  warning,
}) {
  return (
    <Button
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={onClick}
      variant="contained"
      color={warning ? "warning" : "primary"}
    >
      {submitting ? <CircularProgress size={25} sx={{ mx: 3 }} /> : label}
    </Button>
  );
}

export function BtnContainer({
  children,
  left,
  center,
  right,
  split,
  noGutters,
}) {
  const containerStyle = {
    my: noGutters ? 0 : 2,
  };

  if (left) {
    return (
      <Box className="flex flex-row flex-start" sx={containerStyle}>
        {children}
      </Box>
    );
  } else if (center) {
    return (
      <Box className="flex flex-row flex-justify-center" sx={containerStyle}>
        {children}
      </Box>
    );
  } else if (right) {
    return (
      <Box className="flex flex-row flex-end" sx={containerStyle}>
        {children}
      </Box>
    );
  } else if (split) {
    return (
      <Box className="flex flex-row flex-space-between" sx={containerStyle}>
        {children}
      </Box>
    );
  } else {
    return (
      <Box className="flex flex-row flex-start" sx={containerStyle}>
        {children}
      </Box>
    );
  }
}
