import { useState } from "react";
import { PromptField } from "../common/CustomInputFields.jsx";
import { BtnContainer, SubmitBtn } from "../common/CustomButtons.jsx";
import { generateCode } from "../../utils/utils.js";
import { updatePoll } from "../../firestoreClient.js";
// import { updateUserQSet } from "../../../firestoreClient";

export default function ShortAnswerBuilder({
  edit,
  handleClose,
  poll,
  question,
}) {
  // const initVal = getInitVal(question, edit);

  const [prompt, setPrompt] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handlePrompt = (e) => setPrompt(e.target.value);

  const values = {
    type: "short answer",
    subtype: "text",
    prompt: prompt,
  };

  function handleSubmit() {
    values.id = edit ? poll.id : generateCode(20);

    const updatedQuestions = edit
      ? poll.questions.map((el) => (el.id === question.id ? values : el))
      : [...poll.questions, values];

    const updatedValues = { questions: updatedQuestions };

    updatePoll(poll, updatedValues, setSubmitting, handleClose);
  }

  return (
    <>
      <PromptField value={prompt} onChange={handlePrompt} />
      {/* <pre>{JSON.stringify(poll, null, 2)}</pre> */}

      <BtnContainer center>
        <SubmitBtn
          disabled={submitting}
          label="SAVE QUESTION"
          onClick={handleSubmit}
          submitting={submitting}
        />
      </BtnContainer>
    </>
  );
}

// function getInitVal(question, edit) {
//   const existingValues = {
//     prompt: question?.prompt || "",
//     text: question?.answerChoices || [],
//   };

//   const cleanValues = {
//     prompt: "",
//     text: "",
//   };

//   return edit ? existingValues : cleanValues;
// }
