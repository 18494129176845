import { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  FormControl,
  InputLabel,
  Link,
  List,
  ListItemButton,
  ListItemText,
  Select,
  Stack,
  MenuItem,
  Tab,
  Tabs,
  Tooltip,
  TextField,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { db, storage } from "../config/firebaseConfig.js";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import {
  BackToHomeBtn,
  PageHeader,
  SubmitButton,
  UploadFile,
} from "./CommonCpnts";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDocs,
  query,
  onSnapshot,
  orderBy,
  updateDoc,
  where,
} from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import { TitleField, KeyField } from "./common/CustomInputFields";
import DeleteIcon from "@mui/icons-material/Delete";
import TableViewIcon from "@mui/icons-material/TableView";
import { ConfirmAction } from "./CommonCpnts.jsx";
import { Lightbox, LightboxHeader } from "./common/CustomLightbox.jsx";
import { LoginForm } from "./forms/LoginForm.jsx";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  nextSunday,
  formatDate,
  copyArray,
  getDateString,
  mergePDFs,
} from "../utils/utils.js";
import { BtnContainer } from "./common/CustomButtons.jsx";
import WorshipReminders from "./forms/WorshipReminders.jsx";
import WorshipTeamForm from "./forms/WorshipTeamForm.jsx";
import ListIcon from "@mui/icons-material/List";
import { EditMusicianForm } from "./forms/TableCellForm.jsx";

export default function WorshipPage({ currentUser }) {
  const cardStyle = {
    bgcolor: "background.paper",
    maxHeight: "60vh",
    minWidth: "280px",
    overflow: "auto",
    mb: 2,
  };

  const [directory, setDirectory] = useState([]);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [songs, setSongs] = useState([]);
  const [selSong, setSelSong] = useState(null);
  const [setLists, setSetLists] = useState([]);
  const [selSetList, setSelSetList] = useState(null);
  const [loginOpen, setLoginOpen] = useState(false);
  const [setListOpen, setSetListOpen] = useState(false);
  const [addSongOpen, setAddSongOpen] = useState(false);
  const [musiciansOpen, setMusiciansOpen] = useState(false);
  const [remindersOpen, setRemindersOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [view, setView] = useState("list");
  const [sel, setSel] = useState(null);
  const [editMusicianOpen, setEditMusicianOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const openLogin = () => setLoginOpen(true);
  const closeLogin = () => setLoginOpen(false);

  const openSetList = () => setSetListOpen(true);
  const closeSetList = () => setSetListOpen(false);

  const openAddSong = () => setAddSongOpen(true);
  const closeAddSong = () => setAddSongOpen(false);

  const openMusicians = () => setMusiciansOpen(true);
  const closeMusicians = () => setMusiciansOpen(false);

  const openReminders = () => setRemindersOpen(true);
  const closeReminders = () => setRemindersOpen(false);

  const openEditMusician = () => setEditMusicianOpen(true);
  const closeEditMusician = () => setEditMusicianOpen(false);

  const handleTabChange = (event, newValue) => setTabIndex(newValue);

  async function fetchDirectory() {
    const ref = collection(db, "members");
    const q = query(ref, where("roles", "array-contains", "worship"));
    const querySnapshot = await getDocs(q);
    const fetchedItems = [];
    querySnapshot.forEach((doc) => {
      fetchedItems.push({ userID: doc.id, ...doc.data() });
    });

    fetchedItems.sort(compare);

    setDirectory(fetchedItems);
  }

  function compare(a, b) {
    if (a.displayName < b.displayName) {
      return -1;
    } else if (a.displayName > b.displayName) {
      return 1;
    } else return 0;
  }

  function handleClick(setList, role) {
    const person = setList[role];
    openEditMusician();
    console.log(person);
    setSel({
      id: setList?.id,
      date: setList?.date,
      role: role,
      person: person,
    });
  }

  useEffect(
    () => {
      fetchDirectory();
    },
    //eslint-disable-next-line
    []
  );

  useEffect(() => {
    const ref = collection(db, "songs");
    const q = query(ref, orderBy("title", "asc"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedItems = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSongs(fetchedItems);
    });
    return unsubscribe;
  }, [currentUser]);

  useEffect(() => {
    const ref = collection(db, "set_lists");
    const q = query(ref, orderBy("date", "asc"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedItems = snapshot.docs.map((doc) => ({
        id: doc.id,
        date: doc.data().date?.toDate() || null,
        name: doc.data().name || "",
        songs: doc.data().songs || [],
        leader: doc.data().leader || null,
        keyboard: doc.data().keyboard || null,
        guitar: doc.data().guitar || null,
        bass: doc.data().bass || null,
        cajon: doc.data().cajon || null,
        vocal: doc.data().vocal || null,
        miscellaneous: doc.data().miscellaneous || null,
        confirmations: doc.data().confirmations || null,
      }));
      setSetLists(fetchedItems);
    });
    return unsubscribe;
  }, [currentUser]);

  useEffect(
    () => {
      if (!selSetList) {
        const today = new Date();
        const deltas = setLists.map(
          (setList) => setList.date.getTime() - today.getTime()
        );
        const upcomingIndex = deltas.findIndex((delta) => delta > 0);
        setSelSetList(setLists[upcomingIndex]);
        return;
      }
      const updated = setLists.find((setList) => setList.id === selSetList.id);
      setTimeout(() => setSelSetList(updated), 800);
    },
    //eslint-disable-next-line
    [setLists]
  );

  useEffect(() => setSelSong(null), [tabIndex, selSetList]);

  if (!currentUser)
    return (
      <div className="page-container">
        <BackToHomeBtn />
        <PageHeader title="Worship" />
        <Box className="flex flex-row " sx={{ ml: "10px" }}>
          <Typography display="inline">Please</Typography>
          <Link className="link" onClick={openLogin} sx={{ mx: 1 }}>
            <Typography>login</Typography>
          </Link>
          <Typography display="inline">to view the worship page</Typography>
          <LoginForm open={loginOpen} handleClose={closeLogin} />
        </Box>
      </div>
    );

  return (
    <div className="page-container relative">
      <BackToHomeBtn />
      <PageHeader title="Worship" />

      <Tabs value={tabIndex} onChange={handleTabChange}>
        <Tab label="Songs" />
        <Tab label="Set Lists" />
      </Tabs>

      {tabIndex === 0 && (
        <Box className="flex flex-row" sx={{ p: 2 }}>
          <Box width="300px" className="flex flex-column">
            <List sx={cardStyle}>
              {songs.map((song) => (
                <ListItemButton
                  key={song.id}
                  onClick={() => setSelSong(song)}
                  sx={
                    selSong?.id === song.id
                      ? {
                          bgcolor: "rgba(2,108,140,0.08)",
                        }
                      : { bgcolor: "white" }
                  }
                >
                  <ListItemText primary={song.title} secondary={song.key} />
                </ListItemButton>
              ))}
            </List>

            <Button
              onClick={() => {
                handleOpen();
                setEdit(false);
              }}
              startIcon={<AddIcon />}
              variant="contained"
            >
              NEW SONG
            </Button>
          </Box>
          <Stack sx={{ ml: 3 }}>
            <MusicPDF selSong={selSong} />
            <SongCard
              handleOpen={handleOpen}
              selSong={selSong}
              setEdit={setEdit}
              setSelSong={setSelSong}
            />
          </Stack>
        </Box>
      )}
      {tabIndex === 1 && (
        <Box
          className="flex flex-row"
          sx={{ position: "absolute", top: 120, left: 300 }}
        >
          <Button onClick={() => setView("list")} startIcon={<ListIcon />}>
            LIST
          </Button>
          <Button
            onClick={() => setView("table")}
            startIcon={<TableViewIcon />}
          >
            table
          </Button>
        </Box>
      )}
      {tabIndex === 1 && view === "list" && (
        <Box className="flex flex-row relative" sx={{ p: 2 }}>
          <Box width="300px" className="flex flex-column">
            <List sx={cardStyle}>
              {setLists.map((setList) => (
                <ListItemButton
                  key={setList.id}
                  onClick={() => setSelSetList(setList)}
                  sx={
                    selSetList?.id === setList.id
                      ? {
                          bgcolor: "rgba(2,108,140,0.08)",
                        }
                      : { bgcolor: "white" }
                  }
                >
                  <ListItemText primary={formatDate(setList.date)} />
                </ListItemButton>
              ))}
            </List>
            <Button
              onClick={() => {
                openSetList();
                setEdit(false);
              }}
              startIcon={<AddIcon />}
              variant="contained"
            >
              NEW SET LIST
            </Button>
          </Box>
          <Stack sx={{ ml: 3 }}>
            <MusicPDF selSong={selSong} />
            <SetListCard
              currentUser={currentUser}
              handleOpen={openAddSong}
              handleOpenReminders={openReminders}
              handleOpenMusicians={openMusicians}
              selSetList={selSetList}
              setEdit={setEdit}
              setSelSetList={setSelSetList}
              setSelSong={setSelSong}
            />
          </Stack>
        </Box>
      )}
      {tabIndex === 1 && view === "table" && (
        <Box className="flex flex-row" sx={{ p: 2 }}>
          <Box>
            <table className="sunday-server-table">
              <thead>
                <tr>
                  <td className="header-cell relative">
                    <Typography sx={{ p: 2, minHeight: "23px" }} noWrap>
                      Date
                    </Typography>
                    {/* <IconButton
                    onClick={toggleOrderBy}
                    sx={{ position: "absolute", top: 10, left: 40 }}
                  >
                    <SwapVertIcon sx={{ color: "rgb(160,160,160)" }} />
                  </IconButton> */}
                  </td>
                </tr>
              </thead>
              <tbody>
                {setLists.map((setList, ind) => (
                  <tr key={`set-list-${ind}`} style={pickRowColor(ind)}>
                    <Cell value={getDateString(setList?.date)} />
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
          <Box className="flex-grow overflow-auto">
            <table className="sunday-server-table">
              <thead>
                <tr>
                  <HeaderCell value="Leader" />
                  <HeaderCell value="Keyboard" />
                  <HeaderCell value="Guitar" />
                  <HeaderCell value="Bass" />
                  <HeaderCell value="Cajon" />
                  <HeaderCell value="Vocal" />
                  <HeaderCell value="Miscellaneous" />
                </tr>
              </thead>
              <tbody>
                {setLists.map((setList, ind) => (
                  <tr key={`set-list-${ind}`} style={pickRowColor(ind)}>
                    <EditableCell
                      currentUser={currentUser}
                      value={parseCellValue(setList, "leader")}
                      onClick={() => handleClick(setList, "leader")}
                    />
                    <EditableCell
                      currentUser={currentUser}
                      value={parseCellValue(setList, "keyboard")}
                      onClick={() => handleClick(setList, "keyboard")}
                    />
                    <EditableCell
                      currentUser={currentUser}
                      value={parseCellValue(setList, "guitar")}
                      onClick={() => handleClick(setList, "guitar")}
                    />
                    <EditableCell
                      currentUser={currentUser}
                      value={parseCellValue(setList, "bass")}
                      onClick={() => handleClick(setList, "bass")}
                    />
                    <EditableCell
                      currentUser={currentUser}
                      value={parseCellValue(setList, "cajon")}
                      onClick={() => handleClick(setList, "cajon")}
                    />
                    <EditableCell
                      currentUser={currentUser}
                      value={parseCellValue(setList, "vocal")}
                      onClick={() => handleClick(setList, "vocal")}
                    />
                    <EditableCell
                      currentUser={currentUser}
                      value={parseCellValue(setList, "miscellaneous")}
                      onClick={() => handleClick(setList, "miscellaneous")}
                    />
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        </Box>
      )}

      <SongForm
        edit={edit}
        handleClose={handleClose}
        open={open}
        selSong={selSong}
        setSelSong={setSelSong}
      />
      <SetListForm
        edit={edit}
        handleClose={closeSetList}
        open={setListOpen}
        // selSong={selSong}
        // setSelSong={setSelSong}
      />
      <AddSongForm
        open={addSongOpen}
        handleClose={closeAddSong}
        songs={songs}
        selSetList={selSetList}
      />
      <WorshipReminders
        open={remindersOpen}
        handleClose={closeReminders}
        sel={selSetList}
      />
      <WorshipTeamForm
        directory={directory}
        open={musiciansOpen}
        handleClose={closeMusicians}
        sel={selSetList}
      />
      <EditMusicianForm
        key={sel?.id}
        directory={directory}
        handleClose={closeEditMusician}
        open={editMusicianOpen}
        sel={sel}
      />
    </div>
  );
}

function pickRowColor(ind) {
  if (ind % 2)
    return {
      backgroundColor: "rgba(51, 116, 186, 0.08)",
    };
  else
    return {
      backgroundColor: "rgba(51, 116, 186, 0.14)",
    };
}

function Cell({ value }) {
  return (
    <td className="cell">
      <Typography sx={{ p: 2, minHeight: "23px" }} noWrap>
        {value}
      </Typography>
    </td>
  );
}

function HeaderCell({ value }) {
  return (
    <td className="header-cell">
      <Typography sx={{ p: 2, minHeight: "23px" }} noWrap>
        {value}
      </Typography>
    </td>
  );
}

function EditableCell({ currentUser, value, onClick }) {
  if (!currentUser) {
    return (
      <td className="non-editable-cell">
        <Typography sx={{ p: 2, minHeight: "23px" }} noWrap>
          {value}
        </Typography>
      </td>
    );
  } else {
    return (
      <td className="editable-cell" onClick={onClick}>
        <Typography sx={{ p: 2, minHeight: "23px" }} noWrap>
          {value}
        </Typography>
      </td>
    );
  }
}

function parseCellValue(setList, role) {
  switch (role) {
    case "leader": {
      return setList?.leader?.displayName || "N/A";
    }
    case "keyboard": {
      return setList?.keyboard?.displayName || "N/A";
    }
    case "guitar": {
      return setList?.guitar?.displayName || "N/A";
    }
    case "bass": {
      return setList?.bass?.displayName || "N/A";
    }
    case "cajon": {
      return setList?.cajon?.displayName || "N/A";
    }
    case "vocal": {
      return setList?.vocal?.displayName || "N/A";
    }
    case "miscellaneous": {
      return setList?.miscellaneous?.displayName || "N/A";
    }
    default:
      break;
  }
}

function MusicPDF({ selSong }) {
  if (!selSong?.upload) return;
  return (
    <embed
      src={selSong.upload.url + "#toolbar=0&navpanes=0&scrollbar=0"}
      type="application/pdf"
      width="900px"
      height="600px"
    />
  );
}

function SongCard({ handleOpen, selSong, setEdit, setSelSong }) {
  const [confirmActionOpen, setConfirmActionOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [file, setFile] = useState("");

  const openConfirmAction = () => setConfirmActionOpen(true);
  const closeConfirmAction = () => setConfirmActionOpen(false);

  function addMusicSheetRef(url) {
    const personRef = doc(db, "songs", selSong.id);
    const { name } = file;
    const uploadInfo = { name: name, url: url };
    updateDoc(personRef, {
      upload: uploadInfo,
    }).then(() => setSelSong({ ...selSong, upload: uploadInfo }));
  }

  function deleteSong() {
    const ref = doc(db, "songs", selSong.id);
    setSubmitting(true);
    deleteDoc(ref)
      .then(() => setSelSong(null))
      .catch((error) => console.log(error))
      .finally(() => setSubmitting(false));
  }

  function removeAvatarProperty() {
    const personCopy = { ...selSong };
    delete personCopy.upload;
    setSelSong(personCopy);
  }

  function deleteSongRef() {
    const ref = doc(db, "songs", selSong.id);
    updateDoc(ref, {
      upload: deleteField(),
    }).then(() => removeAvatarProperty());
  }

  function handleDelete(name) {
    const storageRef = ref(storage, `songs/${name}`);
    deleteObject(storageRef)
      .then(deleteSongRef)
      .catch((error) => console.log(error));
  }

  if (!selSong) return null;

  return (
    <Box
      className="relative"
      sx={{
        bgcolor: "rgba(0,0,0,0.02)",
        minWidth: "600px",
      }}
    >
      <Box
        className="flex flex-end"
        sx={{ position: "absolute", top: 0, right: 0 }}
      >
        <Button
          onClick={() => {
            handleOpen();
            setEdit(true);
          }}
        >
          Edit
        </Button>
        <Button onClick={openConfirmAction}>DELETE</Button>
      </Box>

      <Typography variant="h5" sx={{ pt: 3, pl: 3 }}>
        {selSong.title}
      </Typography>
      <table style={{ paddingLeft: "21px" }}>
        <tbody>
          <tr>
            <td>
              {!selSong.upload && (
                <UploadFile
                  file={file}
                  setFile={setFile}
                  storagePath={`songs/${file?.name}`}
                  label="UPLOAD SHEET MUSIC"
                  addRef={addMusicSheetRef}
                />
              )}
              {selSong.upload && (
                <Link
                  href={selSong.upload.url}
                  target="_blank"
                  underline="none"
                >{`${selSong.upload.name} `}</Link>
              )}
            </td>
            <td>
              {selSong?.upload && (
                <IconButton
                  color="primary"
                  onClick={() => handleDelete(selSong?.upload?.name)}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <ConfirmAction
        btnLabel="DELETE"
        handleAction={() => {
          deleteSong();
          closeConfirmAction();
        }}
        handleClose={closeConfirmAction}
        open={confirmActionOpen}
        submitting={submitting}
        title="Delete Music Sheet"
      >
        <Typography>
          Delete <strong>{selSong?.title}</strong> and all associated
          information?
        </Typography>
      </ConfirmAction>
    </Box>
  );
}

function SetListCard({
  currentUser,
  handleOpen,
  handleOpenReminders,
  handleOpenMusicians,
  selSetList,
  setSelSetList,
  setSelSong,
}) {
  const [confirmActionOpen, setConfirmActionOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [delIndex, setDelIndex] = useState(-1);

  const songURLs = selSetList?.songs.map((song) => song?.upload?.url);

  const openConfirmAction = () => setConfirmActionOpen(true);
  const closeConfirmAction = () => setConfirmActionOpen(false);

  const cardStyle = {
    bgcolor: "rgba(0,0,0,0.02)",
    minWidth: "600px",
    p: 3,
  };

  function deleteSetList() {
    const ref = doc(db, "set_lists", selSetList.id);
    setSubmitting(true);
    deleteDoc(ref)
      .then(() => setSelSetList(null))
      .catch((error) => console.log(error))
      .finally(() => {
        setSubmitting(false);
        closeConfirmAction();
      });
  }

  function removeSong(ind) {
    const updated = copyArray(selSetList.songs);
    updated.splice(ind, 1);
    setSubmitting(true);
    setDelIndex(ind);
    const ref = doc(db, "set_lists", selSetList.id);
    const values = { songs: updated };
    updateDoc(ref, values)
      .then(() =>
        setTimeout(() => setSelSetList({ ...selSetList, ...values }), 300)
      )
      .catch((error) => console.log(error))
      .finally(() =>
        setTimeout(() => {
          setSubmitting(false);
          setDelIndex(-1);
        }, 300)
      );
  }

  function toggleConfirm(role, confirmed) {
    const docRef = doc(db, "set_lists", selSetList?.id);
    updateDoc(docRef, {
      confirmations: confirmed ? arrayRemove(role) : arrayUnion(role),
    }).catch((error) => console.log(error.message));
  }

  if (!selSetList) return;
  const compiledPDFTitle = `Set List for ${formatDate(selSetList.date)}`;

  return (
    <Box className="relative" sx={cardStyle}>
      <Box
        className="flex flex-end"
        sx={{ position: "absolute", top: 10, right: 15 }}
      >
        <Button onClick={handleOpen} startIcon={<AddIcon />}>
          Add Song
        </Button>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ mx: 1 }}
        />
        <Button
          startIcon={<DownloadIcon />}
          onClick={() => mergePDFs(songURLs, compiledPDFTitle)}
        >
          PDF
        </Button>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ mx: 1 }}
        />
        <Button onClick={openConfirmAction} startIcon={<DeleteIcon />}>
          DELETE
        </Button>
      </Box>

      <Box className="flex flex-column flex-justify-center">
        <Typography variant="h5">
          {selSetList.name ? `Set List - ${selSetList.name}` : "Set List"}
        </Typography>

        <Typography variant="subtitle1">
          {formatDate(selSetList.date)}
        </Typography>
      </Box>
      <BtnContainer right></BtnContainer>

      {selSetList.songs.length > 0 && (
        <table style={{ paddingLeft: "10px" }}>
          <thead>
            <tr>
              <th style={{ width: "300px" }}>
                <Typography fontStyle="italic">title</Typography>
              </th>
              <th style={{ width: "100px" }}>
                <Typography fontStyle="italic">key</Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            {selSetList.songs?.map((song, ind) => (
              <tr key={song.id}>
                <td>
                  <Typography variant="subtitle1">{song.title}</Typography>
                </td>
                <td>
                  <Typography variant="subtitle1">{song.key}</Typography>
                </td>
                <td>
                  <Button onClick={() => setSelSong(song)}>View</Button>
                </td>
                <td>
                  {submitting && delIndex === ind ? (
                    <CircularProgress size={25} sx={{ ml: "25px" }} />
                  ) : (
                    <Button onClick={() => removeSong(ind)}>Remove</Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <Divider sx={{ my: 2 }} />
      <Box className="flex flex-end">
        <Button onClick={handleOpenMusicians}>EDIT</Button>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ mx: 1 }}
        />
        <Button onClick={handleOpenReminders}>Send Reminders</Button>
      </Box>
      <Typography align="center" fontStyle="italic" sx={{ my: 2 }}>
        Worship Team
      </Typography>

      <Table>
        <Row
          category={"leader"}
          confirmed={selSetList?.confirmations?.includes("leader")}
          displayName={selSetList?.leader?.displayName}
          currentUser={currentUser}
          role="leader"
          toggleConfirm={toggleConfirm}
        />
        <Row
          category={"keyboard"}
          confirmed={selSetList?.confirmations?.includes("keyboard")}
          displayName={selSetList?.keyboard?.displayName}
          currentUser={currentUser}
          role="keyboard"
          toggleConfirm={toggleConfirm}
        />
        <Row
          category={"guitar"}
          confirmed={selSetList?.confirmations?.includes("guitar")}
          displayName={selSetList?.guitar?.displayName}
          currentUser={currentUser}
          role="guitar"
          toggleConfirm={toggleConfirm}
        />
        <Row
          category={"bass"}
          confirmed={selSetList?.confirmations?.includes("bass")}
          displayName={selSetList?.bass?.displayName}
          currentUser={currentUser}
          role="bass"
          toggleConfirm={toggleConfirm}
        />
        <Row
          category={"cajon"}
          confirmed={selSetList?.confirmations?.includes("cajon")}
          displayName={selSetList?.cajon?.displayName}
          currentUser={currentUser}
          role="cajon"
          toggleConfirm={toggleConfirm}
        />
        <Row
          category={"vocal"}
          confirmed={selSetList?.confirmations?.includes("vocal")}
          displayName={selSetList?.vocal?.displayName}
          currentUser={currentUser}
          role="vocal"
          toggleConfirm={toggleConfirm}
        />
        <Row
          category={"miscellaneous"}
          confirmed={selSetList?.confirmations?.includes("miscellaneous")}
          displayName={selSetList?.miscellaneous?.displayName}
          currentUser={currentUser}
          role="miscellaneous"
          toggleConfirm={toggleConfirm}
        />
      </Table>
      <ConfirmAction
        btnLabel="DELETE"
        handleAction={deleteSetList}
        handleClose={closeConfirmAction}
        open={confirmActionOpen}
        submitting={submitting}
        title="Delete Set List"
      >
        <Typography>
          Delete set list for <strong>{formatDate(selSetList.date)}</strong>?
        </Typography>
      </ConfirmAction>
    </Box>
  );
}
function SongForm({ edit, handleClose, open, selSong, setSelSong }) {
  const initVal = edit ? selSong : { title: "", key: "" };
  const [title, setTitle] = useState(initVal?.title);
  const [key, setKey] = useState(initVal?.key);
  const [submitting, setSubmitting] = useState(false);

  const handleTitle = (e) => setTitle(e.target.value);
  const handleKey = (e) => setKey(e.target.value);

  const values = {
    title: title,
    key: key,
  };

  function resetForm() {
    setTitle(initVal?.title);
    setKey(initVal?.key);
  }

  function handleSubmit() {
    edit ? editMusicSheet() : addMusicSheet();
  }
  function addMusicSheet() {
    const ref = collection(db, "songs");
    setSubmitting(true);
    addDoc(ref, values)
      .then((doc) =>
        setTimeout(() => setSelSong({ id: doc.id, ...values }), 600)
      )
      .then(() => setTimeout(() => handleClose(), 300))
      .catch((error) => console.log(error))
      .finally(() => setTimeout(() => setSubmitting(false), 200));
  }

  function editMusicSheet() {
    setSubmitting(true);
    updateDoc(doc(db, "songs", initVal.id), values)
      .then(() => setSelSong({ id: initVal.id, ...values }))
      .then(() => setTimeout(() => handleClose(), 300))
      .catch((error) => console.log(error))
      .finally(() => setTimeout(() => setSubmitting(false), 200));
  }

  useEffect(
    resetForm,
    //eslint-disable-next-line
    [open]
  );

  return (
    <Lightbox open={open} onClose={handleClose}>
      <LightboxHeader title={edit ? "Edit Song" : "New Song"} />
      <TitleField onChange={handleTitle} value={title} />
      <KeyField onChange={handleKey} value={key} />

      <Box className="flex flex-row flex-end">
        <SubmitButton
          onClick={handleSubmit}
          disabled={submitting}
          submitting={submitting}
          title="SAVE"
        />
      </Box>
    </Lightbox>
  );
}

function AddSongForm({ open, handleClose, songs, selSetList }) {
  const [newSong, setNewSong] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const existingSongs = selSetList?.songs || [];

  const selectSong = (e) => setNewSong(e.target.value);

  function updateSetList() {
    const ref = doc(db, "set_lists", selSetList.id);
    const values = {
      songs: [...existingSongs, newSong],
    };
    setSubmitting(true);
    updateDoc(ref, values)
      .then(() => setTimeout(() => handleClose(), 300))
      .catch((error) => console.log(error))
      .finally(() => setTimeout(() => setSubmitting(false), 200));
  }

  function resetForm() {
    setNewSong("");
  }

  useEffect(resetForm, [open]);

  return (
    <Lightbox open={open} onClose={handleClose}>
      <LightboxHeader title="Add Song" />
      <FormControl>
        <InputLabel>Song</InputLabel>
        <Select
          sx={{ width: 400 }}
          label="Song"
          value={newSong}
          onChange={selectSong}
        >
          <MenuItem disabled value="">
            select song
          </MenuItem>
          {songs.map((song) => (
            <MenuItem key={song.id} value={song}>
              {song.title + " / " + song.key}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box className="flex flex-row flex-end">
        <SubmitButton
          onClick={updateSetList}
          // disabled={submitting}
          submitting={submitting}
          title="SAVE"
        />
      </Box>
    </Lightbox>
  );
}

function SetListForm({ edit, handleClose, open, selSong, setSelSong }) {
  // const initVal = edit ? selSong : { title: "", key: "" };
  const [name, setName] = useState("");
  const [type, setType] = useState(0);
  const [date, setDate] = useState(() => nextSunday());
  const [submitting, setSubmitting] = useState(false);

  const handleStartDate = (value) => setDate(value);
  const selectType = (e) => setType(e.target.value);
  const handleName = (e) => setName(e.target.value);

  const values = {
    type: type,
    date: date,
    name: name,
  };

  function resetForm() {
    setType("sunday service");
  }

  function handleSubmit() {
    addSetList();
    // edit ? editMusicSheet() : addMusicSheet();
  }
  function addSetList() {
    const ref = collection(db, "set_lists");
    setSubmitting(true);
    addDoc(ref, values)
      // .then((doc) =>
      //   setTimeout(() => setSelSong({ id: doc.id, ...values }), 600)
      // )
      .then(() => setTimeout(() => handleClose(), 300))
      .catch((error) => console.log(error))
      .finally(() => setTimeout(() => setSubmitting(false), 200));
  }

  // async function editMusicSheet() {
  //   setSubmitting(true);
  //   updateDoc(doc(db, "set_lists", initVal.id), values)
  //     .then(() => setSelSong({ id: initVal.id, ...values }))
  //     .then(() => setTimeout(() => handleClose(), 300))
  //     .catch((error) => console.log(error))
  //     .finally(() => setTimeout(() => setSubmitting(false), 200));
  // }

  useEffect(
    resetForm,
    //eslint-disable-next-line
    [open]
  );

  return (
    <Lightbox open={open} onClose={handleClose}>
      <LightboxHeader title="New Set List" />
      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <FormControl>
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <Select
            sx={{ width: 200 }}
            label="Type"
            value={type}
            onChange={selectType}
          >
            <MenuItem value="sunday service">Sunday Service</MenuItem>
            <MenuItem value="special event">Special Event</MenuItem>
          </Select>
        </FormControl>

        <DatePicker
          label="date"
          onChange={handleStartDate}
          renderInput={(params) => (
            <TextField {...params} sx={{ width: "180px" }} />
          )}
          value={date}
        />
      </Stack>
      <TextField
        fullWidth
        autoComplete="off"
        label="set list name (optional)"
        onChange={handleName}
        value={name}
      />

      <Box className="flex flex-row flex-end">
        <SubmitButton
          onClick={handleSubmit}
          disabled={submitting}
          submitting={submitting}
          title="SAVE"
        />
      </Box>
    </Lightbox>
  );
}

function Table({ children }) {
  return (
    <table className="service-section-table">
      <tbody>{children}</tbody>
    </table>
  );
}

function Row({
  category,
  confirmed,
  currentUser,
  displayName,
  role,
  toggleConfirm,
}) {
  const handleConfirm = () => toggleConfirm(role, confirmed);
  if (!category && !displayName) return null;

  return (
    <tr>
      <td className="table-left-col">
        {currentUser && !confirmed && displayName && (
          <Link
            onClick={handleConfirm}
            component="button"
            className="confirm"
            underline="none"
          >
            confirm
          </Link>
        )}
        {confirmed && !currentUser && <CheckIcon color="success" />}
        {confirmed && currentUser && (
          <Tooltip title="click to unconfirm" placement="left">
            <IconButton onClick={handleConfirm} sx={{ p: 0 }}>
              <CheckIcon color="success" />
            </IconButton>
          </Tooltip>
        )}
      </td>
      <td className="table-middle-col">
        {displayName ? (
          <Typography>{displayName}</Typography>
        ) : (
          <Typography color="text.secondary">unassigned</Typography>
        )}
      </td>
      <td>
        <Typography>{category}</Typography>
      </td>
    </tr>
  );
}
