import { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
  FormControl,
  InputLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DeleteIcon from "@mui/icons-material/Delete";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";

import { db, storage } from "../config/firebaseConfig.js";
import {
  addDoc,
  deleteDoc,
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  deleteField,
} from "firebase/firestore";
import {
  BackToHomeBtn,
  PageHeader,
  PendingApproval,
  SubmitButton,
  UploadFile,
} from "./CommonCpnts";
import {
  BirthdayField,
  StreetAddressField,
  CityField,
  EmailField,
  FirstNameField,
  LastNameField,
  PhoneNumField,
  StateField,
  ZipCodeField,
} from "./common/CustomInputFields.jsx";
import { LoginForm } from "./forms/LoginForm.jsx";
import { ref, deleteObject } from "firebase/storage";
import { months } from "../utils/utils.js";
import { ConfirmAction } from "./CommonCpnts.jsx";
import { Lightbox, LightboxHeader } from "./common/CustomLightbox.jsx";

export default function Directory({ currentUser }) {
  const [members, setMembers] = useState([]);
  const [selMember, setSelMember] = useState(null);
  const [open, setOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  const memberInfo = members.find((el) => el.email === currentUser.email);
  const authorized = memberInfo?.permissions?.includes("c9b82b90ae572c840a76");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const openLogin = () => setLoginOpen(true);
  const closeLogin = () => setLoginOpen(false);

  useEffect(() => {
    const q = query(collection(db, "members"), orderBy("firstName", "asc"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedItems = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMembers(fetchedItems);
    });
    return unsubscribe;
  }, [currentUser]);

  if (currentUser && memberInfo?.pendingApproval) {
    return <PendingApproval title="Directory" pageName="directory" />;
  }

  if (!currentUser)
    return (
      <div className="page-container">
        <BackToHomeBtn />
        <PageHeader title="Directory" />
        <Box className="flex flex-row " sx={{ ml: "10px" }}>
          <Typography display="inline">Please</Typography>
          <Link className="link" onClick={openLogin} sx={{ mx: 1 }}>
            <Typography>login</Typography>
          </Link>
          <Typography display="inline">to view the directory</Typography>
          <LoginForm open={loginOpen} handleClose={closeLogin} />
        </Box>
      </div>
    );

  // if (currentUser && !authorized)
  //   return (
  //     <div className="page-container">
  //       <BackToHomeBtn />
  //       <PageHeader title="Directory" />

  //       <Typography variant="h6">
  //         This website is in testing phase, so access to the directory is
  //         temporarily restricted.
  //       </Typography>
  //       <Typography>
  //         Please email Stan at shiew381@gmail.com if you have questions about
  //         accessing the directory.
  //       </Typography>
  //     </div>
  //   );

  const listStyle = {
    maxHeight: "60vh",
    overflow: "auto",
    mb: 2,
    bgcolor: "background.paper",
  };

  return (
    <div className="page-container">
      <BackToHomeBtn />
      <PageHeader title="Directory" />
      <Box className="flex flex-row" sx={{ p: 2 }}>
        <Box width="300px" className="flex flex-column">
          <List sx={listStyle}>
            {members.map((member) => (
              <ListItemButton
                key={member.id}
                onClick={() => setSelMember(member)}
              >
                <ListItemAvatar>
                  <Avatar src={member?.avatar?.url}>
                    <PermIdentityIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={member.firstName + " " + member.lastName}
                  secondary={member.email}
                />
              </ListItemButton>
            ))}
          </List>

          <Button
            onClick={() => {
              handleOpen();
              setEdit(false);
            }}
            startIcon={<AddIcon />}
            variant="contained"
          >
            ADD MEMBER
          </Button>
        </Box>

        <MemberInfoCard
          authorized={authorized}
          currentUser={currentUser}
          handleOpen={handleOpen}
          selMember={selMember}
          setSelMember={setSelMember}
          setEdit={setEdit}
        />
      </Box>
      <MemberForm
        edit={edit}
        initVal={edit ? selMember : cleanValues}
        key={selMember?.id || "new-entry"}
        handleClose={handleClose}
        setSelMember={setSelMember}
        open={open}
      />
    </div>
  );
}

const cleanValues = {
  firstName: "",
  lastName: "",
  displayName: "",
  email: "",
  phoneNumber: "",
  streetAddress: "",
  city: "",
  state: "",
  zipCode: "",
  birthMonth: "",
  birthday: "",
};

function MemberInfoCard({
  authorized,
  currentUser,
  handleOpen,
  selMember,
  setEdit,
  setSelMember,
}) {
  const [confirmActionOpen, setConfirmActionOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [file, setFile] = useState("");

  const openConfirmAction = () => setConfirmActionOpen(true);
  const closeConfirmAction = () => setConfirmActionOpen(false);

  if (!selMember) return null;

  function toggleVisibility(fieldName) {
    const memberRef = doc(db, "members", selMember.id);
    setSubmitting(true);
    updateDoc(memberRef, {
      [fieldName]: !selMember[fieldName],
    })
      .then(() =>
        setSelMember({ ...selMember, [fieldName]: !selMember[fieldName] })
      )
      .catch((error) => console.log(error))
      .finally(() => setSubmitting(false));
  }

  function deleteMember() {
    const memberRef = doc(db, "members", selMember.id);
    setSubmitting(true);
    deleteDoc(memberRef)
      .then(() => setSelMember(null))
      .catch((error) => console.log(error))
      .finally(() => setSubmitting(false));
  }

  function removePendingApproval() {
    const memberRef = doc(db, "members", selMember.id);
    const selMemberCopy = { ...selMember };
    delete selMemberCopy.pendingApproval;
    console.log(selMemberCopy);
    updateDoc(memberRef, {
      pendingApproval: deleteField(),
    }).then(() => setSelMember(selMemberCopy));
  }

  function addAvatarRef(url) {
    const personRef = doc(db, "members", selMember.id);
    const { name } = file;

    const avatarInfo = { name: name, url: url };

    updateDoc(personRef, {
      avatar: avatarInfo,
    }).then(() => setSelMember({ ...selMember, avatar: avatarInfo }));
  }

  function deleteAvatarRef() {
    const membersRef = doc(db, "members", selMember.id);

    updateDoc(membersRef, {
      avatar: deleteField(),
    }).then(() => removeAvatarProperty());
  }

  function removeAvatarProperty() {
    const personCopy = { ...selMember };
    delete personCopy.avatar;
    setSelMember(personCopy);
  }

  function handleDelete(name) {
    const storageRef = ref(storage, `avatars/${name}`);
    deleteObject(storageRef)
      .then(() => {
        deleteAvatarRef();
      })
      .catch((error) => console.log(error));
  }

  const phoneNumber = selMember.phoneNumber || null;
  const email = selMember.email || null;
  const birthday = selMember.birthMonth
    ? selMember.birthMonth + " " + selMember.birthDay
    : null;

  const address = {
    streetAddress: selMember.streetAddress || null,
    city: selMember.city || null,
    state: selMember.state || null,
    zipCode: selMember.zipCode || null,
  };

  const ownProfile = selMember.email === currentUser.email;

  const memberCardStyle = {
    ml: 6,
    borderRadius: 3,
    bgcolor: "background.paper",
    minWidth: 550,
    height: 425,
  };

  return (
    <Box className="relative" sx={memberCardStyle}>
      {selMember.pendingApproval && authorized && (
        <Box
          sx={{
            minWidth: 550,
            bgcolor: "#026c8c",
            position: "absolute",
            top: -55,
            paddingTop: 1,
            paddingBottom: 1,
          }}
        >
          <Typography display="inline" sx={{ p: 3, color: "white" }}>
            This member is awaiting approval.
          </Typography>
          <Button sx={{ color: "white" }} onClick={removePendingApproval}>
            Approve
          </Button>
          <Typography display="inline" sx={{ color: "white", px: 1 }}>
            |
          </Typography>
          <Button sx={{ color: "white" }} onClick={deleteMember}>
            Delete Member
          </Button>
        </Box>
      )}
      <Stack direction="row" sx={{ position: "absolute", top: 1, right: 1 }}>
        {(ownProfile || authorized) && (
          <Button
            onClick={() => {
              handleOpen();
              setEdit(true);
            }}
          >
            EDIT
          </Button>
        )}
      </Stack>

      <Typography variant="h4" sx={{ pl: 4, pt: 4 }}>
        {selMember.firstName + " " + selMember.lastName}
      </Typography>

      <table>
        <tbody>
          <Row>
            <Cell category="email" />
            <Cell2 authorized visible value={email} />
            <td></td>
          </Row>
          <Row>
            <Cell category="phone number" />
            <Cell2
              authorized={authorized}
              grayed={!selMember.phoneNumberVisible}
              value={phoneNumber}
              visible={ownProfile || selMember.phoneNumberVisible}
            />
            <Cell3
              authorized={authorized}
              currentUser={currentUser}
              fieldName="phoneNumberVisible"
              selMember={selMember}
              title="phone number"
              toggleVisibility={toggleVisibility}
            />
          </Row>
          <Row>
            <Cell category="address" />
            <Cell2Address
              authorized={authorized}
              grayed={!selMember.addressVisible}
              value={address}
              visible={ownProfile || selMember.addressVisible}
            />
            <Cell3
              authorized={authorized}
              currentUser={currentUser}
              fieldName="addressVisible"
              selMember={selMember}
              title="address"
              toggleVisibility={toggleVisibility}
            />
          </Row>
          <Row>
            <Cell category="birthday" />
            <Cell2
              authorized={authorized}
              grayed={!selMember.birthdayVisible}
              visible={ownProfile || selMember.birthdayVisible}
              value={birthday}
            />
            <Cell3
              authorized={authorized}
              currentUser={currentUser}
              fieldName="birthdayVisible"
              selMember={selMember}
              title="birthday"
              toggleVisibility={toggleVisibility}
            />
          </Row>
          <Row>
            <Cell category="avatar" />
            <td>
              {!selMember?.avatar && (
                <UploadFile
                  file={file}
                  setFile={setFile}
                  storagePath={`avatars/${file?.name}`}
                  label="UPLOAD IMAGE"
                  addRef={addAvatarRef}
                />
              )}
              {selMember?.avatar && (
                <Box className="flex-row flex-center-all">
                  <Link
                    href={selMember?.avatar?.url}
                    target="_blank"
                    underline="none"
                  >{`${selMember?.avatar?.name} `}</Link>
                </Box>
              )}
            </td>
            <td>
              {selMember?.avatar && (ownProfile || authorized) && (
                <IconButton
                  color="primary"
                  onClick={() => handleDelete(selMember?.avatar?.name)}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </td>
          </Row>
        </tbody>
      </table>

      <ConfirmAction
        btnLabel="DELETE"
        handleAction={deleteMember}
        handleClose={closeConfirmAction}
        open={confirmActionOpen}
        submitting={submitting}
        title="Delete Member Info"
      >
        <Typography>
          Delete <strong>{selMember?.displayName}</strong> and all associated
          information?
        </Typography>
      </ConfirmAction>
      {(ownProfile || authorized) && (
        <Button
          onClick={openConfirmAction}
          sx={{
            position: "absolute",
            bottom: 2,
            left: "240px",
            color: "rgb(168,168,168)",
          }}
        >
          DELETE
        </Button>
      )}
    </Box>
  );
}

function Row({ children }) {
  const rowStyle = { height: "50px" };
  return <tr style={rowStyle}>{children}</tr>;
}

function Cell({ category }) {
  const cellStyle = {
    width: "200px",
    paddingTop: "8px",
    textAlign: "right",
    verticalAlign: "top",
  };
  return (
    <td style={cellStyle}>
      <Typography sx={{ pr: 6 }}>{category}</Typography>
    </td>
  );
}

function Cell2({ authorized, grayed, value, visible }) {
  const cellStyle = {
    width: "200px",
    paddingTop: "8px",
    textAlign: "left",
    verticalAlign: "top",
  };
  const color = grayed ? "text.secondary" : "default";
  if (authorized) {
    return (
      <td style={cellStyle}>
        <Typography color={color}>{value}</Typography>
      </td>
    );
  } else if (!value || !visible) {
    return (
      <td style={cellStyle}>
        <Typography color={color} fontStyle="italic">
          N/A
        </Typography>
      </td>
    );
  } else {
    return (
      <td style={cellStyle}>
        <Typography color={color}>{value}</Typography>
      </td>
    );
  }
}

function Cell2Address({ authorized, grayed, value, visible }) {
  const cellStyle = {
    width: "200px",
    paddingTop: "8px",
    paddingBottom: "3px",
    textAlign: "left",
    verticalAlign: "top",
  };
  const color = grayed ? "text.secondary" : "default";
  if (authorized) {
    return (
      <td style={cellStyle}>
        <Typography color={color}>{value.streetAddress}</Typography>
        {value.city && (
          <Typography color={color}>
            {value.city}, {value.state}, {value.zipCode}
          </Typography>
        )}
      </td>
    );
  } else if (!value || !visible) {
    return (
      <td style={cellStyle}>
        <Typography color={color} fontStyle="italic">
          N/A
        </Typography>
      </td>
    );
  } else {
    return (
      <td style={cellStyle}>
        <Typography color={color}>{value.streetAddress}</Typography>
        <Typography color={color}>
          {value.city}, {value.state}, {value.zipCode}
        </Typography>
      </td>
    );
  }
}

function Cell3({
  authorized,
  currentUser,
  toggleVisibility,
  fieldName,
  selMember,
  title,
}) {
  const visibilityStatus = selMember[fieldName];
  const message1 = `Your ${title} is currently visible to other Christ Kaleidoscope members. Click to hide.`;
  const message2 = `Your ${title} is currently hidden from other Christ Kaleidoscope members. Click to unhide.`;

  if (authorized || currentUser.email === selMember.email) {
    return (
      <td style={{ verticalAlign: "top" }}>
        {visibilityStatus ? (
          <Tooltip title={message1} placement="right">
            <Button onClick={() => toggleVisibility(fieldName)}>Hide</Button>
          </Tooltip>
        ) : (
          <Tooltip title={message2} placement="right">
            <IconButton onClick={() => toggleVisibility(fieldName)}>
              <VisibilityOffIcon />
            </IconButton>
          </Tooltip>
        )}
      </td>
    );
  } else {
    return <td></td>;
  }
}

function MemberForm({ edit, initVal, handleClose, open, setSelMember }) {
  const [firstName, setFirstName] = useState(initVal?.firstName || "");
  const [lastName, setLastName] = useState(initVal?.lastName || "");
  const [email, setEmail] = useState(initVal?.email || "");
  const [phoneNumber, setPhoneNumber] = useState(initVal?.phoneNumber || "");
  const [streetAddress, setStreetAddress] = useState(
    initVal?.streetAddress || ""
  );
  const [city, setCity] = useState(initVal?.city || "");
  const [state, setState] = useState(initVal?.state || "");
  const [zipCode, setZipCode] = useState(initVal?.zipCode || "");
  const [birthMonth, setBirthMonth] = useState(initVal?.birthMonth || "");
  const [birthDay, setBirthDay] = useState(initVal?.birthday || 1);
  const [submitting, setSubmitting] = useState(false);

  const handleFirstName = (e) => setFirstName(e.target.value);
  const handleLastName = (e) => setLastName(e.target.value);
  const handleEmail = (e) => setEmail(e.target.value);
  const handlePhoneNumber = (e) => setPhoneNumber(e.target.value);
  const handleStreetAddress = (e) => setStreetAddress(e.target.value);
  const handleZipCode = (e) => setZipCode(e.target.value);
  const handleState = (e) => setState(e.target.value);
  const handleCity = (e) => setCity(e.target.value);
  const handleBirthMonth = (e) => setBirthMonth(e.target.value);
  const handleBirthDay = (e) => setBirthDay(e.target.value);

  const values = {
    firstName: firstName,
    lastName: lastName,
    displayName: firstName + " " + lastName,
    email: email,
    phoneNumber: phoneNumber,
    streetAddress: streetAddress,
    city: city,
    state: state,
    zipCode: zipCode,
    birthMonth: birthMonth,
    birthDay: birthDay,
    visible: false,
  };

  function resetForm() {
    setFirstName(initVal?.firstName || "");
    setLastName(initVal?.lastName || "");
    setEmail(initVal?.email || "");
    setPhoneNumber(initVal?.phoneNumber || "");
    setStreetAddress(initVal?.streetAddress || "");
    setCity(initVal?.city || "");
    setState(initVal?.state || "");
    setZipCode(initVal?.zipCode || "");
    setBirthMonth(initVal?.birthMonth || "");
    setBirthDay(initVal?.birthDay || "");
  }

  async function addMember() {
    setSubmitting(true);
    addDoc(collection(db, "members"), values)
      .then(() => setTimeout(() => setSelMember(values), 600))
      .then(() => setTimeout(() => handleClose(), 300))
      .catch((error) => console.log(error))
      .finally(() => setTimeout(() => setSubmitting(false), 200));
  }

  async function editMember() {
    setSubmitting(true);
    updateDoc(doc(db, "members", initVal.id), values)
      .then(() => setSelMember({ id: initVal.id, ...values }))
      .then(() => setTimeout(() => handleClose(), 300))
      .catch((error) => console.log(error))
      .finally(() => setTimeout(() => setSubmitting(false), 200));
  }

  const SectionHeader = ({ title }) => (
    <Typography
      color="text.secondary"
      fontStyle="italic"
      sx={{ mb: "4px", mt: "8px", ml: "5px" }}
    >
      {title}
    </Typography>
  );

  useEffect(
    resetForm,
    //eslint-disable-next-line
    [open]
  );

  return (
    <Lightbox open={open} onClose={handleClose}>
      <LightboxHeader title={edit ? "UPDATE MEMBER INFO" : "NEW MEMBER INFO"} />
      <SectionHeader title="name" />
      <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
        <FirstNameField onChange={handleFirstName} value={firstName} />
        <LastNameField onChange={handleLastName} value={lastName} />
      </Stack>
      <SectionHeader title="contact information" />
      <EmailField onChange={handleEmail} value={email} />
      <PhoneNumField onChange={handlePhoneNumber} value={phoneNumber} />
      <SectionHeader title="address" />
      <StreetAddressField
        onChange={handleStreetAddress}
        value={streetAddress}
      />
      <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
        <CityField onChange={handleCity} value={city} />
        <StateField onChange={handleState} value={state} />
        <ZipCodeField onChange={handleZipCode} value={zipCode} />
      </Stack>
      <SectionHeader title="birthday" />
      <Stack direction="row" spacing={1}>
        <FormControl variant="filled">
          <InputLabel>Month</InputLabel>
          <Select
            label="month"
            value={birthMonth}
            onChange={handleBirthMonth}
            sx={{ mb: 1, minWidth: 200 }}
          >
            {months.map((month, ind) => (
              <MenuItem key={`month${ind}`} value={month}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <BirthdayField onChange={handleBirthDay} value={birthDay} />
      </Stack>
      <Box className="flex flex-row flex-end">
        <SubmitButton
          onClick={edit ? editMember : addMember}
          submitting={submitting}
          title="SAVE"
        />
      </Box>
    </Lightbox>
  );
}
