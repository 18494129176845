import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Radio,
  Stack,
  Tooltip,
  Typography,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  AnswerChoiceField,
  PromptField,
} from "../common/CustomInputFields.jsx";
import { BtnContainer, SubmitBtn } from "../common/CustomButtons.jsx";
import { copyArray, generateCode } from "../../utils/utils.js";
import { updatePoll } from "../../firestoreClient.js";

// import { updateUserQSet } from "../../../firestoreClient";

export default function MultipleChoiceBuilder({
  edit,
  handleClose,
  poll,
  question,
}) {
  const initVal = getInitVal(question, edit);

  const [prompt, setPrompt] = useState(initVal.prompt);
  const [answerChoices, setAnswerChoices] = useState(initVal.answerChoices);
  const [allowFreeResponse, setAllowFreeResponse] = useState(false);
  const [allowSelectMany, setAllowSelectMany] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const values = {
    type: "multiple choice",
    prompt: prompt,
    answerChoices: answerChoices,
    allowSelectMany: allowSelectMany,
    allowFreeResponse: allowFreeResponse,
  };

  const handlePrompt = (e) => setPrompt(e.target.value);

  function handleText(e, ind) {
    const updated = copyArray(answerChoices);
    updated[ind] = e.target.value;
    setAnswerChoices(updated);
  }

  function addAnswerChoice() {
    const newAnswerChoice = { answerChoice: "", isCorrect: false };
    const updated = [...answerChoices, newAnswerChoice];
    setAnswerChoices(updated);
  }

  function deleteAnswerChoice(ind) {
    const updated = copyArray(answerChoices);
    updated.splice(ind, 1);
    setAnswerChoices(updated);
  }

  function handleSubmit() {
    values.id = edit ? question.id : generateCode(20);

    const updatedQuestions = edit
      ? poll.questions.map((el) => (el.id === question.id ? values : el))
      : [...poll.questions, values];

    const updatedValues = { questions: updatedQuestions };

    updatePoll(poll, updatedValues, setSubmitting, handleClose);
  }

  return (
    <>
      <PromptField value={prompt} onChange={handlePrompt} />
      {answerChoices.map((el, ind) => (
        <Stack key={`choice-${ind}`} direction="row" sx={{ my: 1 }} spacing={1}>
          <AnswerChoiceField
            key={`answer-choice-${ind}`}
            onChange={(e) => handleText(e, ind)}
            value={el}
          />
          <Tooltip title="remove answer chocie" placement="right">
            <IconButton onClick={() => deleteAnswerChoice(ind)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      ))}

      <BtnContainer center>
        <Button onClick={addAnswerChoice}>ADD ANSWER CHOICE</Button>
      </BtnContainer>
      <Typography sx={{ minWidth: "400px" }}>Participant may select</Typography>
      <Box className="flex flex-row flex-align-center">
        <Radio
          checked={!allowSelectMany}
          onChange={() => setAllowSelectMany(false)}
        />
        <Typography>one choice </Typography>
      </Box>
      <Box className="flex flex-row flex-align-center">
        <Radio
          checked={allowSelectMany}
          onChange={() => setAllowSelectMany(true)}
        />

        <Typography>more than one choice</Typography>
      </Box>
      <Box className="flex flex-row flex-align-center">
        <Checkbox
          edge="start"
          checked={allowFreeResponse}
          onChange={() => setAllowFreeResponse(!allowFreeResponse)}
        />
        <Typography>Allow free response</Typography>
      </Box>
      {allowFreeResponse && (
        <Typography color="text.secondary">
          Partcipant may submit a different response than those provided
        </Typography>
      )}

      <BtnContainer center>
        <SubmitBtn
          disabled={submitting}
          label="SAVE QUESTION"
          onClick={handleSubmit}
          submitting={submitting}
        />
      </BtnContainer>
    </>
  );
}

function getInitVal(question, edit) {
  const existingValues = {
    prompt: question?.prompt || "",
    answerChoices: question?.answerChoices || [],
    points: question?.points || 1,
    attempts: question?.attempts || 1,
  };

  const cleanValues = {
    prompt: "",
    answerChoices: ["", "", "", ""],
  };

  return edit ? existingValues : cleanValues;
}
