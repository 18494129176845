import { useState, useEffect } from "react";
import { BackToHomeBtn, PageHeader, ConfirmAction } from "./CommonCpnts";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  TextField,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import {
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import DeleteIcon from "@mui/icons-material/Delete";
import { db, storage } from "../config/firebaseConfig.js";
import { CustomDivider, UploadFile } from "./CommonCpnts";
import SermonForm from "./forms/SermonForm.jsx";
import ScriptCommForm from "./forms/ScriptCommForm.jsx";
import WorshipTechForm from "./forms/WorshipTechForm";
import SnacksForm from "./forms/SnacksForm.jsx";
import RemindersForm from "./forms/RemindersForm.jsx";
import PickTimeForm from "./forms/PickTimeForm.jsx";
import PickDateForm from "./forms/PickDateForm.jsx";
import SpeakerForm from "./forms/SpeakerForm.jsx";
import ReaderForm from "./forms/ReaderForm";
import {
  getDateString,
  getShortDateString,
  nextSunday,
  twoWeeksAgo,
} from "../utils/utils.js";
import { ref, deleteObject } from "firebase/storage";
import { LoginForm } from "./forms/LoginForm.jsx";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TableViewIcon from "@mui/icons-material/TableView";
import AddIcon from "@mui/icons-material/Add";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TableCellForm from "./forms/TableCellForm.jsx";
import ChildrensMinistryForm from "./forms/ChildrensMinistryForm";
import WelcomeTeamForm from "./forms/WelcomeTeamForm";
import { Link as RouterLink } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const editClearBox = { position: "absolute", top: -4, right: 0 };
const reminderBox = { position: "absolute", top: 10, right: 10 };

export default function SundayService({ currentUser }) {
  const [directory, setDirectory] = useState([]);
  const [services, setServices] = useState([]);
  const [view, setView] = useState("calendar");
  const [loginOpen, setLoginOpen] = useState(false);
  const [fetching, setFetching] = useState(true);

  const [sel, setSel] = useState(null);
  const memberInfo = directory?.find((el) => el.email === currentUser?.email);
  const authorized = memberInfo?.permissions?.includes("c9b82b90ae572c840a76");
  const pendingApproval = directory?.filter((el) => el.pendingApproval);

  const openLogin = () => setLoginOpen(true);
  const closeLogin = () => setLoginOpen(false);

  async function fetchDirectory() {
    const q = query(collection(db, "members"), orderBy("firstName", "asc"));
    const querySnapshot = await getDocs(q);
    const fetchedItems = [];
    querySnapshot.forEach((doc) => {
      fetchedItems.push({ userID: doc.id, ...doc.data() });
    });
    setDirectory(fetchedItems);
  }

  useEffect(() => {
    const q = query(
      collection(db, "services"),
      where("startDate", ">", twoWeeksAgo()),
      orderBy("startDate", "asc")
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedItems = snapshot.docs.map((doc) => ({
        id: doc.id,
        startDate: doc.data().startDate?.toDate() || null,
        endDate: doc.data().endDate?.toDate() || null,
        liturgy: doc.data().liturgy || null,
        sermonTitle: doc.data().sermonTitle,
        sermonSpeaker: doc.data().sermonSpeaker,
        passage1: doc.data().passage1 || null,
        passage2: doc.data().passage2 || null,
        passage3: doc.data().passage3 || null,
        reader1: doc.data().reader1 || null,
        reader2: doc.data().reader2 || null,
        reader3: doc.data().reader3 || null,
        communion1: doc.data().communion1 || null,
        communion2: doc.data().communion2 || null,
        emceeLead: doc.data().emceeLead || null,
        musicLead: doc.data().musicLead || null,
        audioLead: doc.data().audioLead || null,
        livestreamLead: doc.data().livestreamLead || null,
        slidesLead: doc.data().slidesLead || null,
        childrensMinistryLead: doc.data().childrensMinistryLead || null,
        welcomeLead: doc.data().welcomeLead || null,
        snackPerson1: doc.data().snackPerson1 || null,
        snackPerson2: doc.data().snackPerson2 || null,
        snackPerson3: doc.data().snackPerson3 || null,
        snackItem1: doc.data().snackItem1 || "",
        snackItem2: doc.data().snackItem2 || "",
        snackItem3: doc.data().snackItem3 || "",

        confirmations: doc.data().confirmations || [],
      }));

      setServices(fetchedItems);
      setTimeout(() => setFetching(false), 300);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    fetchDirectory();
  }, [currentUser]);

  return (
    <div className="page-container">
      <BackToHomeBtn />
      {authorized && pendingApproval.length > 0 && (
        <div
          style={{ backgroundColor: "#026c8c", padding: 5, marginBottom: 5 }}
        >
          <Typography sx={{ color: "white", pl: 2 }} variant="h6">
            {pendingApproval[0].firstName +
              " " +
              pendingApproval[0].lastName +
              " (" +
              pendingApproval[0].email +
              ") "}
            is waiting for their account to be approved.
          </Typography>
          <Typography
            // display="inline"
            sx={{ color: "white", pl: 2 }}
            variant="h6"
          >
            Please navigate to the directory to approve or deny their request
          </Typography>
          <Button
            component={RouterLink}
            to="/directory"
            sx={{ color: "white" }}
            variant="outlined"
            endIcon={<ChevronRightIcon />}
          >
            Visit Directory
          </Button>
        </div>
      )}
      <PageHeader title="Sunday Service" />
      <Box
        className="flex flex-row"
        sx={{ position: "absolute", top: 12, right: 12 }}
      >
        <Button
          onClick={() => setView("calendar")}
          startIcon={<CalendarMonthIcon />}
        >
          calendar
        </Button>
        <Button onClick={() => setView("table")} startIcon={<TableViewIcon />}>
          table
        </Button>
      </Box>

      {!currentUser && (
        <Box className="flex flex-row" sx={{ ml: "10px" }}>
          <Typography display="inline">Please</Typography>
          <Link className="link" onClick={openLogin} sx={{ mx: 1 }}>
            <Typography>login</Typography>
          </Link>
          <Typography display="inline">to edit Sunday services</Typography>
          <LoginForm open={loginOpen} handleClose={closeLogin} />
        </Box>
      )}
      {fetching && (
        <Box className="flex flex-center-all" sx={{ p: 30 }}>
          <CircularProgress />
        </Box>
      )}

      {!fetching && (
        <CalendarView
          currentUser={currentUser}
          directory={directory}
          sel={sel}
          services={services}
          setSel={setSel}
          view={view}
        />
      )}
      <TableView
        currentUser={currentUser}
        directory={directory}
        services={services}
        view={view}
      />
    </div>
  );
}

function TableView({ currentUser, directory, services, view }) {
  const [sel, setSel] = useState(null);
  const [open, setOpen] = useState(false);
  const [openTimeForm, setTimeForm] = useState(false);
  const [openSermon, setOpenSermon] = useState(false);
  const [openReader, setOpenReader] = useState(false);
  const [edit, setEdit] = useState(false);
  const [orderBy, setOrderBy] = useState("ascending");
  const [servicesOrdered, setServicesOrdered] = useState([...services]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmActionOpen, setConfirmActionOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleOpenSermon = () => setOpenSermon(true);
  const handleCloseSermon = () => setOpenSermon(false);
  const handleOpenReader = () => setOpenReader(true);
  const handleCloseReader = () => setOpenReader(false);
  const openConfirmAction = () => setConfirmActionOpen(true);
  const closeConfirmAction = () => setConfirmActionOpen(false);
  const handleOpenTimeForm = () => setTimeForm(true);
  const handleCloseTimeForm = () => setTimeForm(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSel(null);
  };

  useEffect(() => {
    const servicesCopy = [...services];
    if (orderBy === "ascending") {
      setServicesOrdered(servicesCopy);
    } else if (orderBy === "descending") {
      setServicesOrdered(servicesCopy.reverse());
    } else return;
  }, [services, orderBy]);

  if (view !== "table") return null;

  function parseCellValue(service, role) {
    switch (role) {
      case "sermonSpeaker": {
        return service?.sermonSpeaker?.displayName || "N/A";
      }
      case "reader1": {
        const displayName = service?.reader1?.displayName || "N/A";
        const passage = service?.passage1 || "no passage";
        return `${displayName} (${passage})`;
      }
      case "reader2": {
        const displayName = service?.reader2?.displayName || "N/A";
        const passage = service?.passage2 || "no passage";
        return `${displayName} (${passage})`;
      }
      case "reader3": {
        const displayName = service?.reader3?.displayName || "N/A";
        const passage = service?.passage3 || "no passage";
        return `${displayName} (${passage})`;
      }
      case "communion1": {
        return service?.communion1?.displayName || "N/A";
      }
      case "communion2": {
        return service?.communion2?.displayName || "N/A";
      }
      case "emceeLead": {
        return service?.emceeLead?.displayName || "N/A";
      }
      case "musicLead": {
        return service?.musicLead?.displayName || "N/A";
      }
      case "audioLead": {
        return service?.audioLead?.displayName || "N/A";
      }
      case "livestreamLead": {
        return service?.livestreamLead?.displayName || "N/A";
      }
      case "slidesLead": {
        return service?.slidesLead?.displayName || "N/A";
      }
      case "childrensMinistryLead": {
        return service?.childrensMinistryLead?.displayName || "N/A";
      }
      case "welcomeLead": {
        return service?.welcomeLead?.displayName || "N/A";
      }
      case "snackPerson1": {
        return service?.snackPerson1?.displayName || "N/A";
      }
      case "snackPerson2": {
        return service?.snackPerson2?.displayName || "N/A";
      }
      case "snackPerson3": {
        return service?.snackPerson3?.displayName || "N/A";
      }
      default:
        break;
    }
  }

  const handleCloseMenu = () => setAnchorEl(null);

  function handleClick(service, role) {
    const person = service[role];
    handleOpen();
    setSel({
      id: service?.id,
      date: service?.startDate,
      role: role,
      person: person,
    });
  }

  function handleSermonEdit(service, role) {
    const person = service[role];
    handleOpenSermon();
    setSel({
      id: service?.id,
      date: service?.startDate,
      role: role,
      sermonSpeaker: person,
      sermonTitle: service?.sermonTitle,
    });
  }

  function getPassage(service, role) {
    switch (role) {
      case "reader1":
        return service?.passage1;
      case "reader2":
        return service?.passage2;
      case "reader3":
        return service?.passage3;
      default:
        return "";
    }
  }

  function handleReaderEdit(service, role) {
    const person = service[role];
    const passage = getPassage(service, role);
    handleOpenReader();
    setSel({
      id: service?.id,
      date: service?.startDate,
      role: role,
      reader: person,
      passage: passage,
    });
  }

  function toggleOrderBy() {
    if (orderBy === "ascending") {
      setOrderBy("descending");
    } else if (orderBy === "descending") {
      setOrderBy("ascending");
    } else return;
  }

  function deleteService() {
    const memberRef = doc(db, "services", sel.id);
    setSubmitting(true);
    deleteDoc(memberRef)
      .then(() => {
        setSel(null);
        closeConfirmAction();
      })
      .catch((error) => console.log(error))
      .finally(() => setSubmitting(false));
  }

  const nextService = services?.find(
    (service) => service?.startDate.getTime() - new Date().getTime() > 0
  );

  return (
    <>
      <Button
        startIcon={<AddIcon />}
        onClick={() => {
          setSel(null);
          handleOpenTimeForm();
          setEdit(false);
        }}
      >
        Add Service
      </Button>
      <Box className="flex flex-row" sx={{ pr: 3, mb: 3 }}>
        <Box>
          <table className="sunday-server-table">
            <thead>
              <tr>
                <td className="header-cell relative">
                  <Typography sx={{ p: 2, minHeight: "23px" }} noWrap>
                    Date
                  </Typography>
                  <IconButton
                    onClick={toggleOrderBy}
                    sx={{ position: "absolute", top: 10, left: 15 }}
                  >
                    <SwapVertIcon sx={{ color: "rgb(160,160,160)" }} />
                  </IconButton>
                </td>
              </tr>
            </thead>
            <tbody>
              {servicesOrdered.map((service, ind) => (
                <tr
                  className="relative"
                  key={`service${ind}`}
                  style={pickRowColor(ind)}
                >
                  <Cell value={getShortDateString(service?.startDate)} />
                  <IconButton
                    onClick={(e) => {
                      setAnchorEl(e.currentTarget);
                      setSel(service);
                      setEdit(true);
                    }}
                    sx={{ position: "absolute", top: 10, right: -2 }}
                  >
                    <MoreVertIcon sx={{ color: "rgb(160,160,160)" }} />
                  </IconButton>

                  {service.id === nextService.id && (
                    <svg
                      width="5"
                      height="58"
                      style={{ position: "absolute", top: 0, left: -2 }}
                    >
                      <rect
                        width="300"
                        height="100"
                        style={{ fill: "#026c8c" }}
                      />
                    </svg>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              handleOpenTimeForm();
            }}
          >
            edit date/time
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              openConfirmAction();
            }}
          >
            delete
          </MenuItem>
        </Menu>
        <ConfirmAction
          btnLabel="DELETE"
          handleAction={deleteService}
          handleClose={closeConfirmAction}
          open={confirmActionOpen}
          submitting={submitting}
          title="Delete Service"
        >
          <Typography>
            Delete service planned for
            <strong>{getDateString(sel?.startDate)}</strong> and all associated
            information?
          </Typography>
        </ConfirmAction>
        <Box className="flex-grow overflow-auto">
          <table className="sunday-server-table">
            <thead>
              <tr>
                <HeaderCell value="Speaker" />
                <HeaderCell value="Psalm (Passage)" />
                <HeaderCell value="Reader 1 (Passage)" />
                <HeaderCell value="Reader 2 (Passage)" />
                <HeaderCell value="Communion 1" />
                <HeaderCell value="Communion 2" />
                <HeaderCell value="Emcee" />
                <HeaderCell value="Music" />
                <HeaderCell value="Audio" />
                <HeaderCell value="Livestream" />
                <HeaderCell value="Slides" />
                <HeaderCell value="Children's Ministry" />
                <HeaderCell value="Welcome Team" />
                <HeaderCell value="Food 1" />
                <HeaderCell value="Food 2" />
                <HeaderCell value="Coffee" />
              </tr>
            </thead>
            <tbody>
              {servicesOrdered.map((service, ind) => (
                <tr key={`service${ind}`} style={pickRowColor(ind)}>
                  <EditableCell
                    currentUser={currentUser}
                    value={parseCellValue(service, "sermonSpeaker")}
                    onClick={() => handleSermonEdit(service, "sermonSpeaker")}
                  />
                  <EditableCell
                    currentUser={currentUser}
                    value={parseCellValue(service, "reader3")}
                    onClick={() => handleReaderEdit(service, "reader3")}
                  />
                  <EditableCell
                    currentUser={currentUser}
                    value={parseCellValue(service, "reader1")}
                    onClick={() => handleReaderEdit(service, "reader1")}
                  />
                  <EditableCell
                    currentUser={currentUser}
                    value={parseCellValue(service, "reader2")}
                    onClick={() => handleReaderEdit(service, "reader2")}
                  />
                  <EditableCell
                    currentUser={currentUser}
                    value={parseCellValue(service, "communion1")}
                    onClick={() => handleClick(service, "communion1")}
                  />
                  <EditableCell
                    currentUser={currentUser}
                    value={parseCellValue(service, "communion2")}
                    onClick={() => handleClick(service, "communion2")}
                  />
                  <EditableCell
                    currentUser={currentUser}
                    value={parseCellValue(service, "emceeLead")}
                    onClick={() => handleClick(service, "emceeLead")}
                  />
                  <EditableCell
                    currentUser={currentUser}
                    value={parseCellValue(service, "musicLead")}
                    onClick={() => handleClick(service, "musicLead")}
                  />
                  <EditableCell
                    currentUser={currentUser}
                    value={parseCellValue(service, "audioLead")}
                    onClick={() => handleClick(service, "audioLead")}
                  />
                  <EditableCell
                    currentUser={currentUser}
                    value={parseCellValue(service, "livestreamLead")}
                    onClick={() => handleClick(service, "livestreamLead")}
                  />
                  <EditableCell
                    currentUser={currentUser}
                    value={parseCellValue(service, "slidesLead")}
                    onClick={() => handleClick(service, "slidesLead")}
                  />
                  <EditableCell
                    currentUser={currentUser}
                    value={parseCellValue(service, "childrensMinistryLead")}
                    onClick={() =>
                      handleClick(service, "childrensMinistryLead")
                    }
                  />
                  <EditableCell
                    currentUser={currentUser}
                    value={parseCellValue(service, "welcomeLead")}
                    onClick={() => handleClick(service, "welcomeLead")}
                  />
                  <EditableCell
                    currentUser={currentUser}
                    value={parseCellValue(service, "snackPerson1")}
                    onClick={() => handleClick(service, "snackPerson1")}
                  />
                  <EditableCell
                    currentUser={currentUser}
                    value={parseCellValue(service, "snackPerson2")}
                    onClick={() => handleClick(service, "snackPerson2")}
                  />
                  <EditableCell
                    currentUser={currentUser}
                    value={parseCellValue(service, "snackPerson3")}
                    onClick={() => handleClick(service, "snackPerson3")}
                  />
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
        <TableCellForm
          key={sel?.id}
          directory={directory}
          handleClose={handleClose}
          open={open}
          sel={sel}
        />
        <PickDateForm
          date={sel?.startDate || new Date()}
          edit={edit}
          open={openTimeForm}
          handleClose={handleCloseTimeForm}
          sel={sel}
        />
        <SpeakerForm
          key={`${sel?.id}sermon`}
          directory={directory}
          open={openSermon}
          handleClose={handleCloseSermon}
          sel={sel}
        />
        <ReaderForm
          key={`${sel?.id}reader`}
          directory={directory}
          open={openReader}
          handleClose={handleCloseReader}
          sel={sel}
        />
      </Box>
    </>
  );
}

function pickRowColor(ind) {
  if (ind % 2)
    return {
      backgroundColor: "rgba(51, 116, 186, 0.08)",
    };
  else
    return {
      backgroundColor: "rgba(51, 116, 186, 0.14)",
    };
}

function HeaderCell({ value }) {
  return (
    <td className="header-cell">
      <Typography sx={{ p: 2, minHeight: "23px" }} noWrap>
        {value}
      </Typography>
    </td>
  );
}

function Cell({ value }) {
  return (
    <td className="cell">
      <Typography sx={{ p: 2, pr: 5, minHeight: "23px" }} noWrap>
        {value}
      </Typography>
    </td>
  );
}

function EditableCell({ currentUser, value, onClick }) {
  if (!currentUser) {
    return (
      <td className="non-editable-cell">
        <Typography sx={{ p: 2, minHeight: "23px" }} noWrap>
          {value}
        </Typography>
      </td>
    );
  } else {
    return (
      <td className="editable-cell" onClick={onClick}>
        <Typography sx={{ p: 2, minHeight: "23px" }} noWrap>
          {value}
        </Typography>
      </td>
    );
  }
}

function CalendarView({ currentUser, directory, sel, services, setSel, view }) {
  const [date, setDate] = useState(() => nextSunday()); // date selected from calendar
  const [file, setFile] = useState(null);
  const [openSermon, setOpenSermon] = useState(false);
  const [openScriptComm, setOpenScriptComm] = useState(false);
  const [openMusic, setOpenMusic] = useState(false);
  const [openSnacks, setOpenSnacks] = useState(false);
  const [openReminders, setOpenReminders] = useState(false);
  const [openTimeForm, setTimeForm] = useState(false);
  const [openChildrensMinistry, setOpenChildrensMinistry] = useState(false);
  const [openWelcome, setOpenWelcome] = useState(false);
  const memberInfo = directory?.find((el) => el.email === currentUser?.email);

  const handleDate = (value) => setDate(value);
  const handleOpenSermon = () => setOpenSermon(true);
  const handleCloseSermon = () => setOpenSermon(false);
  const handleOpenScriptComm = () => setOpenScriptComm(true);
  const handleCloseScriptComm = () => setOpenScriptComm(false);
  const handleOpenMusic = () => setOpenMusic(true);
  const handleCloseMusic = () => setOpenMusic(false);
  const handleOpenSnacks = () => setOpenSnacks(true);
  const handleCloseSnacks = () => setOpenSnacks(false);
  const handleOpenReminders = () => setOpenReminders(true);
  const handleCloseReminders = () => setOpenReminders(false);
  const handleOpenTimeForm = () => setTimeForm(true);
  const handleCloseTimeForm = () => setTimeForm(false);
  const handleOpenWelcome = () => setOpenWelcome(true);
  const handleCloseWelcome = () => setOpenWelcome(false);

  const handleOpenChildrensMinistry = () => setOpenChildrensMinistry(true);
  const handleCloseChildrensMinistry = () => setOpenChildrensMinistry(false);

  async function addLiturgy(url) {
    const ref = doc(db, "services", sel.id);
    const { name } = file;

    await updateDoc(ref, {
      liturgy: { name: name, url: url },
    });
  }

  async function deleteLiturgyRef() {
    const ref = doc(db, "services", sel.id);

    await updateDoc(ref, {
      liturgy: deleteField(),
    });
  }

  async function clearSermon() {
    await updateDoc(doc(db, "services", sel.id), {
      sermonTitle: deleteField(),
      sermonSpeaker: deleteField(),
      confirmations: arrayRemove("speaker"),
    });
  }

  async function clearScriptComm() {
    await updateDoc(doc(db, "services", sel.id), {
      passage1: deleteField(),
      passage2: deleteField(),
      passage3: deleteField(),
      reader1: deleteField(),
      reader2: deleteField(),
      reader3: deleteField(),
      communion1: deleteField(),
      communion2: deleteField(),
      confirmations: arrayRemove(
        "reader1",
        "reader2",
        "reader3",
        "server1",
        "server2"
      ),
    });
  }

  async function clearWorshipTech() {
    await updateDoc(doc(db, "services", sel.id), {
      emceeLead: deleteField(),
      musicLead: deleteField(),
      audioLead: deleteField(),
      livestreamLead: deleteField(),
      slidesLead: deleteField(),
      confirmations: arrayRemove(
        "emcee-lead",
        "music-lead",
        "audio-lead",
        "livestream-lead",
        "slides-lead"
      ),
    });
  }

  async function clearChildrensMinistry() {
    await updateDoc(doc(db, "services", sel.id), {
      childrensMinistryLead: deleteField(),
      confirmations: arrayRemove("childrens-ministry-lead"),
    });
  }

  async function clearWelcomeTeam() {
    await updateDoc(doc(db, "services", sel.id), {
      welcomeLead: deleteField(),
      confirmations: arrayRemove("welcome-team-lead"),
    });
  }

  async function clearSnacks() {
    await updateDoc(doc(db, "services", sel.id), {
      snackPerson1: deleteField(),
      snackPerson2: deleteField(),
      snackPerson3: deleteField(),
      snackItem1: deleteField(),
      snackItem2: deleteField(),
      snackItem3: deleteField(),
      confirmations: arrayRemove("snacks1", "snacks2", "snacks3"),
    });
  }

  function handleDelete(name) {
    const storageRef = ref(storage, `services/liturgies/${name}`);
    deleteObject(storageRef)
      .then(() => {
        deleteLiturgyRef();
        // File deleted successfully
      })
      .catch((error) => {
        console.log("error");
      });
  }

  function toggleConfirm(role, confirmed) {
    const docRef = doc(db, "services", sel?.id);
    updateDoc(docRef, {
      confirmations: confirmed ? arrayRemove(role) : arrayUnion(role),
    }).catch((error) => console.log(error.message));
  }

  useEffect(
    () => {
      const found = services?.find(
        (service) =>
          service.startDate?.getMonth() === date.getMonth() &&
          service.startDate?.getDate() === date.getDate()
      );
      setSel(found);
    },
    //eslint-disable-next-line
    [services, date]
  );

  if (view !== "calendar") return null;

  return (
    <Box className="sunday-service-container">
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        openTo="day"
        value={date}
        onChange={handleDate}
        renderInput={(params) => <TextField {...params} />}
      />
      <Card className="relative sunday-service-card">
        <CardContent>
          <Typography variant="h5">{getDateString(date)}</Typography>
          <TimeRange sel={sel} />
          <CustomDivider sel={sel} />

          <ScheduleService
            currentUser={currentUser}
            date={date}
            handleOpen={handleOpenTimeForm}
            key={date.toLocaleString()}
            sel={sel}
          />
          <Section header="Sermon" sel={sel}>
            <Table>
              <Row
                category={sel?.sermonTitle}
                confirmed={sel?.confirmations?.includes("speaker")}
                displayName={sel?.sermonSpeaker?.displayName}
                currentUser={currentUser}
                role="speaker"
                toggleConfirm={toggleConfirm}
              />
            </Table>
            <Box sx={{ p: 2 }} className="flex flex-center-all">
              {!sel?.liturgy && currentUser && !memberInfo?.pendingApproval && (
                <UploadFile
                  file={file}
                  setFile={setFile}
                  storagePath={`services/liturgies/${file?.name}`}
                  label="UPLOAD LITURGY"
                  addRef={addLiturgy}
                />
              )}
              {sel?.liturgy && currentUser && !memberInfo?.pendingApproval && (
                <>
                  <Link
                    href={sel?.liturgy?.url}
                    target="_blank"
                    underline="none"
                  >{`${sel?.liturgy?.name} `}</Link>
                  {currentUser && (
                    <Tooltip title="delete liturgy" placement="right">
                      <IconButton
                        onClick={() => handleDelete(sel?.liturgy?.name)}
                      >
                        <DeleteIcon sx={{ color: "rgb(176,176,176)" }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
            </Box>
            <Actions
              clearForm={clearSermon}
              currentUser={currentUser}
              handleOpen={handleOpenSermon}
              memberInfo={memberInfo}
            />
          </Section>
          <CustomDivider sel={sel} />
          <Section header="Scripture & Communion" sel={sel}>
            <Table>
              <Row
                category={sel?.passage3 || "psalm"}
                confirmed={sel?.confirmations?.includes(`reader3`)}
                currentUser={currentUser}
                displayName={sel?.reader3?.displayName}
                key="reader3"
                role="reader3"
                toggleConfirm={toggleConfirm}
              />
              <Row
                category={sel?.passage1 || "scripture 1"}
                confirmed={sel?.confirmations?.includes(`reader1`)}
                currentUser={currentUser}
                displayName={sel?.reader1?.displayName}
                key="reader1"
                role="reader1"
                toggleConfirm={toggleConfirm}
              />
              <Row
                category={sel?.passage2 || "scripture 2"}
                confirmed={sel?.confirmations?.includes(`reader2`)}
                currentUser={currentUser}
                displayName={sel?.reader2?.displayName}
                key="reader2"
                role="reader2"
                toggleConfirm={toggleConfirm}
              />
              <Row
                category="communion 1"
                confirmed={sel?.confirmations?.includes(`communion1`)}
                currentUser={currentUser}
                displayName={sel?.communion1?.displayName}
                key="communion1"
                role="communion1"
                toggleConfirm={toggleConfirm}
              />
              <Row
                category="communion 2"
                confirmed={sel?.confirmations?.includes(`communion2`)}
                currentUser={currentUser}
                displayName={sel?.communion2?.displayName}
                key="communion2"
                role="communion2"
                toggleConfirm={toggleConfirm}
              />
            </Table>
            <Actions
              clearForm={clearScriptComm}
              currentUser={currentUser}
              handleOpen={handleOpenScriptComm}
              memberInfo={memberInfo}
            />
          </Section>
          <CustomDivider sel={sel} />

          <Section header="Emcee, Worship, Audio & Slides" sel={sel}>
            <Table>
              <Row
                category={"emcee"}
                confirmed={sel?.confirmations?.includes("emcee-lead")}
                currentUser={currentUser}
                displayName={sel?.emceeLead?.displayName}
                role="emcee-lead"
                toggleConfirm={toggleConfirm}
              />
              <Row
                category={"music lead"}
                confirmed={sel?.confirmations?.includes("music-lead")}
                currentUser={currentUser}
                displayName={sel?.musicLead?.displayName}
                role="music-lead"
                toggleConfirm={toggleConfirm}
              />
              <Row
                category={"audio"}
                confirmed={sel?.confirmations?.includes("audio-lead")}
                currentUser={currentUser}
                displayName={sel?.audioLead?.displayName}
                role="audio-lead"
                toggleConfirm={toggleConfirm}
              />
              <Row
                category={"livestream"}
                confirmed={sel?.confirmations?.includes("livestream-lead")}
                currentUser={currentUser}
                displayName={sel?.livestreamLead?.displayName}
                role="livestream-lead"
                toggleConfirm={toggleConfirm}
              />
              <Row
                category={"slides"}
                confirmed={sel?.confirmations?.includes("slides-lead")}
                currentUser={currentUser}
                displayName={sel?.slidesLead?.displayName}
                role="slides-lead"
                toggleConfirm={toggleConfirm}
              />
            </Table>
            <Actions
              clearForm={clearWorshipTech}
              currentUser={currentUser}
              handleOpen={handleOpenMusic}
              memberInfo={memberInfo}
            />
          </Section>
          <CustomDivider sel={sel} />
          <Section header="Children's Ministry" sel={sel}>
            <Table>
              <Row
                category={"ministry lead"}
                confirmed={sel?.confirmations?.includes(
                  "childrens-ministry-lead"
                )}
                currentUser={currentUser}
                displayName={sel?.childrensMinistryLead?.displayName}
                role="childrens-ministry-lead"
                toggleConfirm={toggleConfirm}
              />
            </Table>
            <Actions
              clearForm={clearChildrensMinistry}
              currentUser={currentUser}
              handleOpen={handleOpenChildrensMinistry}
              memberInfo={memberInfo}
            />
          </Section>
          <CustomDivider sel={sel} />
          <Section header="Welcome Team" sel={sel}>
            <Table>
              <Row
                category={"welcome lead"}
                confirmed={sel?.confirmations?.includes("welcome-team-lead")}
                currentUser={currentUser}
                displayName={sel?.welcomeLead?.displayName}
                role="welcome-team-lead"
                toggleConfirm={toggleConfirm}
              />
            </Table>
            <Actions
              clearForm={clearWelcomeTeam}
              currentUser={currentUser}
              handleOpen={handleOpenWelcome}
              memberInfo={memberInfo}
            />
          </Section>
          <CustomDivider sel={sel} />
          <Section header="Snacks" sel={sel}>
            <Table>
              <Row
                category={sel?.snackItem1}
                confirmed={sel?.confirmations?.includes(`snacks1`)}
                currentUser={currentUser}
                displayName={sel?.snackPerson1?.displayName}
                role={`snacks1`}
                toggleConfirm={toggleConfirm}
              />
              <Row
                category={sel?.snackItem2}
                confirmed={sel?.confirmations?.includes(`snacks2`)}
                currentUser={currentUser}
                displayName={sel?.snackPerson2?.displayName}
                role={`snacks2`}
                toggleConfirm={toggleConfirm}
              />
              <Row
                category={sel?.snackItem3}
                confirmed={sel?.confirmations?.includes(`snacks3`)}
                currentUser={currentUser}
                displayName={sel?.snackPerson3?.displayName}
                role={`snacks3`}
                toggleConfirm={toggleConfirm}
              />
            </Table>
            <Actions
              clearForm={clearSnacks}
              currentUser={currentUser}
              handleOpen={handleOpenSnacks}
              memberInfo={memberInfo}
            />
          </Section>

          {currentUser && sel?.id && !memberInfo?.pendingApproval && (
            <Box sx={reminderBox}>
              <Button onClick={handleOpenReminders}>Send Reminders</Button>
            </Box>
          )}
        </CardContent>
      </Card>

      <PickTimeForm
        key={date.toLocaleString()}
        date={date}
        directory={directory}
        open={openTimeForm}
        handleClose={handleCloseTimeForm}
        sel={sel}
      />
      <SermonForm
        key={`${sel?.id}sermon`}
        directory={directory}
        open={openSermon}
        handleClose={handleCloseSermon}
        sel={sel}
      />
      <ScriptCommForm
        key={`${sel?.id}passages`}
        directory={directory}
        open={openScriptComm}
        handleClose={handleCloseScriptComm}
        values={sel?.passages}
        sel={sel}
      />
      <WorshipTechForm
        key={`${sel?.id}music`}
        directory={directory}
        open={openMusic}
        handleClose={handleCloseMusic}
        sel={sel}
      />
      <ChildrensMinistryForm
        key={`${sel?.id}-childrens-ministry`}
        directory={directory}
        open={openChildrensMinistry}
        handleClose={handleCloseChildrensMinistry}
        sel={sel}
      />
      <WelcomeTeamForm
        key={`${sel?.id}-welcome-team`}
        directory={directory}
        open={openWelcome}
        handleClose={handleCloseWelcome}
        sel={sel}
      />
      <SnacksForm
        key={`${sel?.id}snacks`}
        directory={directory}
        open={openSnacks}
        handleClose={handleCloseSnacks}
        sel={sel}
      />
      <RemindersForm
        key={`${sel?.id}reminders`}
        directory={directory}
        open={openReminders}
        handleClose={handleCloseReminders}
        sel={sel}
      />
    </Box>
  );
}

function Actions({ handleOpen, clearForm, currentUser, memberInfo }) {
  if (!currentUser || memberInfo?.pendingApproval) return null;

  return (
    <Stack direction="row" sx={editClearBox}>
      <Button onClick={handleOpen}>EDIT</Button>
      <Divider orientation="vertical" variant="middle" flexItem />
      <Button onClick={clearForm}>CLEAR</Button>
    </Stack>
  );
}

function ScheduleService({ handleOpen, sel, currentUser }) {
  if (sel) return null;
  return (
    <Box sx={{ height: "200px" }} className="flex flex-column flex-center-all">
      <Typography variant="subtitle1" textAlign="center" sx={{ mb: 2 }}>
        No service scheduled yet for this date
      </Typography>
      {currentUser && (
        <Button onClick={handleOpen} variant="contained">
          SCHEDULE SERVICE
        </Button>
      )}
    </Box>
  );
}

function TimeRange({ sel }) {
  if (!sel) return null;

  const timeFormat = {
    hour: "2-digit",
    minute: "2-digit",
  };

  const startTime = sel?.startDate.toLocaleTimeString("en-US", timeFormat);
  const endTime = sel?.endDate.toLocaleTimeString("en-US", timeFormat);
  return <Typography>{startTime + "–" + endTime}</Typography>;
}

function Row({
  category,
  confirmed,
  currentUser,
  displayName,
  role,
  toggleConfirm,
}) {
  const handleConfirm = () => toggleConfirm(role, confirmed);
  if (!category && !displayName) return null;

  return (
    <tr>
      <td className="table-left-col">
        {currentUser && !confirmed && displayName && (
          <Link
            onClick={handleConfirm}
            component="button"
            className="confirm"
            underline="none"
          >
            confirm
          </Link>
        )}
        {confirmed && !currentUser && <CheckIcon color="success" />}
        {confirmed && currentUser && (
          <Tooltip title="click to unconfirm" placement="left">
            <IconButton onClick={handleConfirm} sx={{ p: 0 }}>
              <CheckIcon color="success" />
            </IconButton>
          </Tooltip>
        )}
      </td>
      <td className="table-middle-col">
        {displayName ? (
          <Typography>{displayName}</Typography>
        ) : (
          <Typography color="text.secondary">unassigned</Typography>
        )}
      </td>
      <td>
        <Typography>{category}</Typography>
      </td>
    </tr>
  );
}

function Table({ children }) {
  return (
    <table className="service-section-table">
      <tbody>{children}</tbody>
    </table>
  );
}

function Section({ header, children, sel }) {
  if (!sel) return null;
  return (
    <Box className="relative sunday-service-card-section">
      <Typography sx={{ fontStyle: "italic", mb: 1 }} variant="subtitle1">
        {header}
      </Typography>
      {children}
    </Box>
  );
}
