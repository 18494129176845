import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getFileExtension } from "../utils/utils.js";
import { useStorage } from "../hooks/useStorage.js";
import { SubmitBtn, BtnContainer } from "./common/CustomButtons.jsx";
import { Lightbox } from "./common/CustomLightbox.jsx";

export function BackToHomeBtn() {
  return (
    <Button component={RouterLink} to="/" startIcon={<ChevronLeftIcon />}>
      Back
    </Button>
  );
}

export function CustomDivider({ sel }) {
  if (!sel) return null;
  return <Divider sx={{ my: 2 }} />;
}

export function PendingApproval({ pageName, title }) {
  return (
    <div className="page-container">
      <BackToHomeBtn />
      <PageHeader title={title} />
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" display="inline">
          Almost there! To protect the safety of all our members, your account
          is awaiting approval by the Christ Kaleidoscope staff.
          <br />
          <br /> Once approved, you'll be able to view the
          {" " + pageName}. Please provide up to 48 hours for approval.
          <br />
          If you have any questions please email Katie
          (katie.heemstra@gmail.com).
        </Typography>
      </Box>
    </div>
  );
}

export function DirectoryAutocomplete({ directory, label, setState, value }) {
  const directoryNames = directory?.map((person) => person?.displayName);

  const handleTypedInput = (e) => {
    setState(e.target.value);
    const index = directory.findIndex(
      (el) => el.displayName.toLowerCase() === e.target.value.toLowerCase()
    );
    index >= 0
      ? setState(directory[index])
      : setState({ displayName: e.target.value });
  };

  const handleSelect = (e) => {
    setState(e.target.innerText);
    const index = directory.findIndex(
      (el) => el.displayName === e.target.innerText
    );
    setState(directory[index]);
  };

  return (
    <Autocomplete
      onChange={handleSelect}
      freeSolo
      options={directoryNames}
      renderInput={(params) => (
        <TextField {...params} label={label} onChange={handleTypedInput} />
      )}
      sx={{ mb: 2 }}
      value={value}
    />
  );
}

export function PageHeader({ title }) {
  return (
    <Typography color="primary" sx={{ pl: 1, mb: 1 }} variant="h3" noWrap>
      {title}
    </Typography>
  );
}

export function SermonTitleField({ onChange, value }) {
  return (
    <TextField
      autoComplete="off"
      fullWidth
      label="sermon title"
      onChange={onChange}
      sx={{ mb: 2 }}
      value={value}
      variant="outlined"
    />
  );
}

export function ChapterField({ onChange, value }) {
  return (
    <TextField
      autoComplete="off"
      fullWidth
      label="chapter"
      onChange={onChange}
      sx={{ mr: 3, width: 150 }}
      value={value}
      variant="outlined"
      type="number"
      inputProps={{ min: 1 }}
    />
  );
}

export function VerseStartField({ onChange, value }) {
  return (
    <TextField
      autoComplete="off"
      fullWidth
      label="starting verse"
      onChange={onChange}
      sx={{ width: 100 }}
      value={value}
      variant="outlined"
      type="number"
      inputProps={{ min: 1 }}
    />
  );
}

export function VerseEndField({ onChange, value }) {
  return (
    <TextField
      autoComplete="off"
      fullWidth
      label="ending verse"
      onChange={onChange}
      sx={{ width: 100 }}
      value={value}
      variant="outlined"
      type="number"
      inputProps={{ min: 1 }}
    />
  );
}

export function EmailField({ onChange, value }) {
  return (
    <TextField
      autoComplete="off"
      fullWidth
      label="email"
      onChange={onChange}
      value={value}
      variant="filled"
    />
  );
}

export function GroupNameField({ onChange, value }) {
  return (
    <TextField
      autoComplete="off"
      fullWidth
      label="group name"
      onChange={onChange}
      placeholder="The Avengers"
      sx={{ mb: 1 }}
      value={value}
      variant="filled"
    />
  );
}

export function EventNameField({ onChange, value }) {
  return (
    <TextField
      autoComplete="off"
      fullWidth
      label="event name"
      onChange={onChange}
      sx={{ mb: 1 }}
      value={value}
      variant="filled"
    />
  );
}

export function LocationField({ onChange, value }) {
  return (
    <TextField
      autoComplete="off"
      fullWidth
      label="location"
      onChange={onChange}
      sx={{ mb: 1 }}
      value={value}
      variant="filled"
    />
  );
}

export function DescriptionField({ onChange, value }) {
  return (
    <TextField
      fullWidth
      label="description"
      multiline
      onChange={onChange}
      rows={3}
      sx={{ mb: 1 }}
      value={value}
      variant="filled"
    />
  );
}

export function ItemField({ onChange, value }) {
  return (
    <TextField
      autoComplete="off"
      fullWidth
      label="item"
      onChange={onChange}
      sx={{ mb: 2 }}
      value={value}
      variant="outlined"
    />
  );
}

export function StartDatePicker({ onChange, value }) {
  return (
    <DatePicker
      label="start date"
      onChange={onChange}
      renderInput={(params) => <TextField {...params} />}
      value={value}
    />
  );
}

export function EndDatePicker({ onChange, value }) {
  return (
    <DatePicker
      label="end date"
      onChange={onChange}
      renderInput={(params) => <TextField {...params} />}
      value={value}
    />
  );
}

export function SubmitButton({ submitting, onClick, title, disabled }) {
  return (
    <Button
      disabled={submitting || disabled}
      variant="contained"
      color="primary"
      onClick={onClick}
      sx={{ my: 2 }}
    >
      {submitting ? <CircularProgress size={25} /> : title}
    </Button>
  );
}

export function UploadFile({ file, setFile, storagePath, addRef, label }) {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadProgress, setUploadProgress] = useState(null);

  useStorage(file, setFile, storagePath, setUploadProgress, addRef);

  const acceptedTypes = ["image/png", "image/jpeg", "application/pdf"];
  const acceptedExtensions = ["PNG", "jpeg", "pdf"];

  const handleSelectFile = (e) => {
    let selectedFile = e.target.files[0];
    const fileType = selectedFile?.type;
    const fileExtension = getFileExtension(selectedFile);

    const validFile =
      selectedFile &&
      (acceptedTypes.includes(fileType) ||
        acceptedExtensions.includes(fileExtension));

    if (validFile) {
      setFile(selectedFile);
      setError(false);
      setErrorMessage("");
    } else {
      setFile(null);
      setError(true);
      setErrorMessage("Please select an image file (.jpeg or .png)");
    }
  };

  return (
    <Box>
      {!file && (
        <Button
          // color="primary"
          component="label"
          startIcon={<CloudUploadIcon />}
        >
          {label}
          <input type="file" hidden onChange={handleSelectFile} />
        </Button>
      )}

      {error && <Alert severity="warning">{errorMessage}</Alert>}
      {file && <Typography>{file.name}</Typography>}
      {uploadProgress > 0 && (
        <LinearProgress variant="determinate" value={uploadProgress} />
      )}
    </Box>
  );
}

export function PersonChip({ currentUser, person, handleDelete }) {
  if (currentUser) {
    return (
      <Chip
        label={person.displayName}
        onDelete={() => handleDelete(person)}
        sx={{ mb: 1, mr: 1 }}
      />
    );
  } else {
    return <Chip label={person.displayName} sx={{ mb: 1, mr: 1 }} />;
  }
}

export function ConfirmAction({
  btnLabel,
  children,
  handleAction,
  handleClose,
  open,
  placeholder,
  requireFieldInput,
  submitting,
  title,
}) {
  const [value, setValue] = useState("");
  const handleChange = (e) => setValue(e.target.value);

  if (!requireFieldInput)
    return (
      <Lightbox open={open} onClose={handleClose}>
        <Header title={title} />
        {children}
        <BtnContainer split>
          <CancelButton onClick={handleClose} />
          <SubmitBtn
            disabled={submitting}
            label={btnLabel}
            onClick={handleAction}
            submitting={submitting}
            warning
          />
        </BtnContainer>
      </Lightbox>
    );

  if (requireFieldInput)
    return (
      <Lightbox open={open} onClose={handleClose}>
        <Header title={title} />
        {children}
        <TextField
          fullWidth
          onChange={handleChange}
          value={value}
          placeholder={placeholder}
        />
        <BtnContainer split>
          <CancelButton onClick={handleClose} />
          <SubmitBtn
            // disabled={value !== placeholder}
            label={btnLabel}
            onClick={handleAction}
            // submitting={submitting}
          />
        </BtnContainer>
      </Lightbox>
    );
}

function Header({ title }) {
  return (
    <Typography color="secondary" sx={{ mb: 3 }} variant="h5">
      {title}
    </Typography>
  );
}

function CancelButton({ onClick }) {
  return (
    <Button onClick={onClick} color="secondary">
      CANCEL
    </Button>
  );
}
