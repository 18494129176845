import { useState } from "react";
import { useAuth } from "../../contexts/AuthContext.jsx";
import { Alert, Link } from "@mui/material";
import { Lightbox, LightboxHeader } from "../common/CustomLightbox.jsx";
import { EmailField, PasswordField } from "../common/CustomInputFields.jsx";
import { BtnContainer, SubmitBtn } from "../common/CustomButtons.jsx";

export function LoginForm({ open, handleClose }) {
  const { login, resetPassword } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [forgotPwd, setForgotPwd] = useState(false);

  const clean = !password || !email;

  const handleEmail = (e) => setEmail(e.target.value);
  const handlePassword = (e) => setPassword(e.target.value);

  function handleForgotPwd() {
    setForgotPwd(true);
  }

  function handleLogin() {
    const tidiedEmail = email.slice().trim();
    setErrorMessage("");
    setSubmitting(true);
    login(tidiedEmail, password)
      .then(() => setTimeout(() => handleClose(), 500))
      .then(() => setTimeout(() => resetForm(), 600))
      .catch((error) => {
        setTimeout(() => setErrorMessage(getErrorMessage(error.code)), 600);
      })
      .finally(() => setTimeout(() => setSubmitting(false), 500));
  }

  function handleResetPwd() {
    const tidiedEmail = email.slice().trim();
    setErrorMessage("");
    setSubmitting(true);
    resetPassword(tidiedEmail)
      .then(() => setTimeout(() => handleClose(), 500))
      .then(() => setTimeout(() => resetForm(), 600))
      .finally(() => setTimeout(() => setSubmitting(false), 500));
  }

  const resetForm = () => {
    setEmail("");
    setPassword("");
    setErrorMessage("");
    setSuccessMessage("");
    setForgotPwd(false);
  };

  const closeAndReset = () => {
    handleClose();
    setTimeout(() => resetForm(), 500);
  };

  const handleEnter = (e) => e.code === "Enter" && handleLogin();

  return (
    <Lightbox open={open} onClose={closeAndReset} onKeyPress={handleEnter}>
      <LightboxHeader title={forgotPwd ? "Reset Password" : "Login"} center />
      <EmailField onChange={handleEmail} value={email} />

      {!forgotPwd && (
        <PasswordField onChange={handlePassword} value={password} />
      )}

      {errorMessage && <Alert severity="warning">{errorMessage}</Alert>}
      {successMessage && <Alert severity="success">{successMessage}</Alert>}

      {forgotPwd ? (
        <BtnContainer center>
          <SubmitBtn
            disabled={!email || submitting}
            label="EMAIL RESET LINK"
            onClick={handleResetPwd}
            submitting={submitting}
          />
        </BtnContainer>
      ) : (
        <BtnContainer split>
          <Link href="#" underline="hover" onClick={handleForgotPwd}>
            Forgot Password
          </Link>
          <SubmitBtn
            disabled={clean || submitting}
            label="LOG IN"
            onClick={handleLogin}
            submitting={submitting}
          />
        </BtnContainer>
      )}
    </Lightbox>
  );
}

function getErrorMessage(errorCode) {
  switch (errorCode) {
    case "auth/invalid-email":
      return "Please check if you entered your email correctly.";
    case "auth/user-not-found":
      return "The email you entered could not be found.";
    case "auth/wrong-password":
      return "The password you entered is incorrect.";
    case "auth/too-many-requests":
      return "You've enterred the incorrect password too many times. Please wait 10 minutes before trying again.";
    case "auth/email-already-in-use":
      return "The email you entered is already in use.";
    default:
      return "An unknown error occured";
  }
}
