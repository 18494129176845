import { useState, useEffect } from "react";
import { db } from "../../config/firebaseConfig.js";
import { doc, updateDoc } from "firebase/firestore";
import { Box, Typography } from "@mui/material";
import { DirectoryAutocomplete, SubmitButton } from "../CommonCpnts";
import { trimPersonInfo, getDateString } from "../../utils/utils.js";
import { Lightbox } from "../common/CustomLightbox.jsx";

export default function TableCellForm({ directory, open, handleClose, sel }) {
  const [person, setPerson] = useState(sel?.person || "");
  const [submitting, setSubmitting] = useState(false);
  const values = {
    [sel?.role]: trimPersonInfo(person),
  };
  const dateString = sel?.date ? getDateString(sel?.date) : null;

  useEffect(
    () => resetForm(),
    // eslint-disable-next-line
    [sel?.id, sel?.role]
  ); // clears fields on open if user clears section.

  function getHeader() {
    switch (sel?.role) {
      case "sermonSpeaker":
        return "Speaker";
      case "reader1":
        return "Reader 1";
      case "reader2":
        return "Reader 2";
      case "reader3":
        return "Reader 3";
      case "communion1":
        return "Communion 1";
      case "communion2":
        return "Communion 2";
      case "musicLead":
        return "Music Lead";
      case "emceeLead":
        return "Emcee";
      case "audioLead":
        return "Audio";
      case "slidesLead":
        return "Slides";
      case "childrensMinistryLead":
        return "Children's Ministry";
      case "welcomeLead":
        return "Welcome Team";
      case "snackPerson1":
        return "Snack 1";
      case "snackPerson2":
        return "Snack 2";
      case "snackPerson3":
        return "Coffee";
      default:
        return sel?.role;
    }
  }

  function saveDetails() {
    const serviceRef = doc(db, "services", sel.id);
    setSubmitting(true);
    updateDoc(serviceRef, values)
      .then(() => setTimeout(() => handleClose(), 300))
      .catch((error) => console.log(error))
      .finally(() => setTimeout(() => setSubmitting(false), 200));
  }

  function resetForm() {
    setPerson(sel?.person || "");
  }

  useEffect(
    resetForm,
    // eslint-disable-next-line
    [open]
  );

  return (
    <Lightbox open={open} onClose={handleClose}>
      <Typography align="left" color="primary" variant="h5">
        {getHeader()}
      </Typography>
      <Typography
        align="left"
        color="text.secondary"
        variant="subtitle2"
        sx={{ mb: 2 }}
      >
        {dateString}
      </Typography>

      <Box>
        <DirectoryAutocomplete
          directory={directory}
          label="name"
          setState={setPerson}
          value={person?.displayName || ""}
        />
      </Box>

      <Box className="flex flex-end">
        <SubmitButton
          onClick={saveDetails}
          submitting={submitting}
          title="SAVE"
        />
      </Box>
    </Lightbox>
  );
}

export function EditMusicianForm({ directory, open, handleClose, sel }) {
  const [person, setPerson] = useState(sel?.person || "");
  const [submitting, setSubmitting] = useState(false);
  const values = {
    [sel?.role]: trimPersonInfo(person),
  };
  const dateString = sel?.date ? getDateString(sel?.date) : null;

  useEffect(
    () => resetForm(),
    // eslint-disable-next-line
    [sel?.id, sel?.role]
  ); // clears fields on open if user clears section.

  function getHeader() {
    switch (sel?.role) {
      case "leader":
        return "Leader";
      case "keyboard":
        return "Keyboard";
      case "guitar":
        return "Guitar";
      case "bass":
        return "Bass";
      case "miscellaneous":
        return "Miscellaneous";
      default:
        return sel?.role;
    }
  }

  function saveDetails() {
    const serviceRef = doc(db, "set_lists", sel.id);
    setSubmitting(true);
    updateDoc(serviceRef, values)
      .then(() => setTimeout(() => handleClose(), 300))
      .catch((error) => console.log(error))
      .finally(() => setTimeout(() => setSubmitting(false), 200));
  }

  function resetForm() {
    setPerson(sel?.person || "");
  }

  useEffect(
    resetForm,
    // eslint-disable-next-line
    [open]
  );

  return (
    <Lightbox open={open} onClose={handleClose}>
      <Typography align="left" color="primary" variant="h5">
        {getHeader()}
      </Typography>
      <Typography
        align="left"
        color="text.secondary"
        variant="subtitle2"
        sx={{ mb: 2 }}
      >
        {dateString}
      </Typography>

      <Box>
        <DirectoryAutocomplete
          directory={directory}
          label="name"
          setState={setPerson}
          value={person?.displayName || ""}
        />
      </Box>

      <Box className="flex flex-end">
        <SubmitButton
          onClick={saveDetails}
          submitting={submitting}
          title="SAVE"
        />
      </Box>
    </Lightbox>
  );
}
