import { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import parse from "html-react-parser";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { formatDate } from "../../utils/utils";
import { Lightbox } from "../common/CustomLightbox";

const copyToClipboard = (str) => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText)
    return navigator.clipboard.writeText(str);
  return Promise.reject("The Clipboard API is not available.");
};

export default function WorshipReminders({ open, handleClose, sel }) {
  const [templateIndex, setTemplateIndex] = useState(0);
  const [checked, setChecked] = useState([]);

  const handleTemplateIndex = (event) => {
    setTemplateIndex(event.target.value);
  };

  function handleToggle(value) {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  }

  useEffect(resetForm, [open]);

  if (!sel?.id) return null;

  const leader = sel.leader;
  const keyboard = sel.keyboard;
  const guitar = sel.guitar;
  const bass = sel.bass;
  const cajon = sel.cajon;
  const vocal = sel.vocal;
  const miscellaneous = sel.miscellaneous;

  const leaderName = leader?.displayName || "(not assigned)";
  const keyboardName = keyboard?.displayName || "(not assigned)";
  const guitarName = guitar?.displayName || "(not assigned)";
  const bassName = bass?.displayName || "(not assigned)";
  const cajonName = cajon?.displayName || "(not assigned)";
  const vocalName = vocal?.displayName || "(not assigned)";
  const miscellaneousName = miscellaneous?.displayName || "(not assigned)";

  const infoList = [leader, keyboard, guitar, bass, cajon, miscellaneous];

  const displayNames = infoList.map((info) => info?.displayName);
  const uniqueDisplayNames = removeDuplicates(displayNames);

  const emails = infoList.map((info) => info?.email);
  const uniqueEmails = removeDuplicates(emails);

  function removeDuplicates(names) {
    const uniqueNames = [...new Set(names)];
    return uniqueNames.filter((name) => name); // removes undefined elements from array
  }

  const tidiedList = uniqueDisplayNames.map((name) => getAssociatedEmail(name));

  function getAssociatedEmail(name) {
    const ind = displayNames.findIndex((el) => el === name);
    const associatedEmail = infoList[ind]?.email || null;
    return {
      email: associatedEmail,
      displayName: name || "couldn't find",
    };
  }

  function toggleCheckAll() {
    if (checked.length === uniqueEmails.length) {
      setChecked([]);
    } else {
      setChecked(uniqueEmails);
    }
  }

  const mailingList = checked.join(", ");

  const templates = [
    {
      selectHandle: "template1",
      title: `Sunday Music Reminder - ${formatDate(sel.date)}`,
      text:
        "Hello worship team! Thanks for helping out with Sunday music this week. Please find below the team, set list, and chord charts for next Sunday's service." +
        "<br/><br/>" +
        "Team" +
        "<ul>" +
        `<li>leader: ${leaderName}</li>` +
        `<li>keyboard: ${keyboardName}` +
        `<li>guitar: ${guitarName}` +
        `<li>bass: ${bassName}</li>` +
        `<li>cajon: ${cajonName}</li>` +
        `<li>vocal: ${vocalName}</li>` +
        `<li>miscellaneous: ${miscellaneousName}</li>` +
        "</ul>" +
        "<br/>" +
        "Songs" +
        "<ul>" +
        `<li>${sel.songs[0]?.title || "<em>N/A</em>"} (opening)</li>` +
        `<li>${sel.songs[1]?.title || "<em>N/A</em>"}</li>` +
        `<li>${sel.songs[2]?.title || "<em>N/A</em>"}</li>` +
        `<li>${sel.songs[3]?.title || "<em>N/A</em>"}</li>` +
        `<li>${sel.songs[4]?.title || "<em>N/A</em>"} (communion)</li>` +
        `<li>${sel.songs[5]?.title || "<em>N/A</em>"} (closing)</li>` +
        "</ul>" +
        "Please remember to confirm if you can make it on https://ckmembers.org Please arrive at 8:30 AM to set up and 9:00 AM to practice. Thanks!" +
        "<br/>" +
        "<br/>" +
        "Grace and peace," +
        "<br/>" +
        "[Worship Leader]",
      plaintext:
        "Hello worship team! Thanks for helping out with Sunday music this week. Please find below the team, set list, and chord charts for next Sunday's service. " +
        "Team" +
        `leader: ${leaderName} BREAK` +
        " " +
        `keyboard: ${keyboardName} BREAK` +
        " " +
        `guitar: ${guitarName}  BREAK` +
        " " +
        `bass: ${bassName} BREAK` +
        " " +
        `cajon: ${cajonName} BREAK` +
        " " +
        `miscellaneous: ${miscellaneousName} BREAK`,
    },
    {
      selectHandle: "template2",
      title: "Friendly Reminder - Please Confirm Your Worship Team Role",
      text:
        `Hello! This is a follow-up reminder that you're signed up for the worship team on ${formatDate(
          sel.date
        )}. Please login to https://ckmembers.org as soon as possible to confirm or let me know if you need help finding a substitute.` +
        "<br/>" +
        "<br/>" +
        "Grace and peace," +
        "<br/>" +
        "[Worship Leader]",
      plaintext:
        "Hello! This is a follow-up reminder that you're signed up for this Sunday's service. Please login to https://ckmembers.org as soon as possible to confirm or let me know if you need help finding a substitute.",
    },
  ];

  const emailMessage = `mailto:${mailingList}?subject=${templates[templateIndex]?.title}&body=${templates[templateIndex]?.plaintext}`;

  const templateFieldStyle = {
    width: 550,
    position: "relative",
    p: 2,
    mb: 2,
    borderRadius: 2,
  };

  function SectionHeader({ title }) {
    return (
      <Typography
        variant="subtitle1"
        fontStyle="italic"
        color="text.secondary"
        sx={{ mb: 1 }}
      >
        {title}
      </Typography>
    );
  }

  function resetForm() {
    setChecked([]);
    setTemplateIndex(0);
  }

  return (
    <Lightbox open={open} onClose={handleClose}>
      <Box className="flex flex-row">
        <List
          sx={{
            width: "100%",
            maxWidth: 300,
            maxHeight: 600,
            overflow: "auto",
            bgcolor: "background.paper",
          }}
        >
          <ListItem disablePadding>
            <ListItemButton onClick={toggleCheckAll}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.length === uniqueEmails.length}
                />
              </ListItemIcon>
              <ListItemText primary="Select All" />
            </ListItemButton>
          </ListItem>
          <Divider />
          {tidiedList.map((value, ind) => {
            return (
              <ListItem key={`listitem${ind}`} disablePadding>
                <ListItemButton
                  onClick={() => handleToggle(value.email)}
                  dense
                  disabled={!value.email}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(value.email) !== -1}
                      tabIndex={-1}
                      disableRipple
                      disabled={!value.email}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={value.displayName}
                    secondary={value.email || "(no email entered)"}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        <Box className="flex" sx={{ ml: 5, width: 800 }}>
          <Paper sx={{ p: 2 }} className="relative">
            <Box className="flex flex-space-between" sx={{ mb: 2 }}>
              <Typography variant="h5" sx={{ m: 1 }}>
                Email Template
              </Typography>

              <FormControl>
                <InputLabel id="demo-simple-select-label">Template</InputLabel>
                <Select
                  sx={{ width: 200 }}
                  label="Template"
                  value={templateIndex}
                  onChange={handleTemplateIndex}
                >
                  <MenuItem value={0}>weekly reminder</MenuItem>
                  <MenuItem value={1}>generic reminder</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box sx={templateFieldStyle}>
              <SectionHeader title="Mailing List" />

              <Tooltip title="copy mailing list" placement="right">
                <IconButton
                  sx={{ position: "absolute", left: 100, top: 10 }}
                  onClick={() => copyToClipboard(mailingList)}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>

              {mailingList.length === 0 && (
                <Typography sx={{ color: "left" }}>
                  (no emails selected)
                </Typography>
              )}
              {mailingList.length > 0 && (
                <Typography variant="subtitle2" color="primary">
                  {mailingList}
                </Typography>
              )}
            </Box>
            <Box sx={templateFieldStyle}>
              <SectionHeader title="Title" />
              {templateIndex >= 0 && templates[templateIndex] && (
                <Tooltip title="copy title" placement="right">
                  <IconButton
                    sx={{ position: "absolute", left: 60, top: 10 }}
                    onClick={() =>
                      copyToClipboard(templates[templateIndex]?.title)
                    }
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              )}
              {templateIndex < 0 && (
                <Typography sx={{ color: "gray" }}>
                  (no template selected)
                </Typography>
              )}
              {templateIndex >= 0 && (
                <Typography>{templates[templateIndex]?.title}</Typography>
              )}
            </Box>
            <Box sx={templateFieldStyle}>
              <SectionHeader title="Body" />
              {templateIndex >= 0 && (
                <Tooltip title="copy body" placement="right">
                  <IconButton
                    sx={{ position: "absolute", left: 60, top: 10 }}
                    onClick={() =>
                      copyToClipboard(templates[templateIndex]?.plaintext)
                    }
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              )}
              {templateIndex < 0 && (
                <Typography sx={{ color: "gray" }}>
                  (no template selected)
                </Typography>
              )}
              {templateIndex >= 0 &&
                parse(templates[templateIndex]?.text || "")}
            </Box>

            <Box className="flex flex-end">
              <Link href={emailMessage} underline="none">
                Open Template In Mail Client
              </Link>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Lightbox>
  );
}
