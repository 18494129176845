import { useEffect, useState } from "react";
import { db } from "../../config/firebaseConfig.js";
import { doc, updateDoc } from "firebase/firestore";
import { Box, Tab, Tabs } from "@mui/material";
import { DirectoryAutocomplete, SubmitButton } from "../CommonCpnts";

import { trimPersonInfo } from "../../utils/utils.js";
import { Lightbox, LightboxHeader } from "../common/CustomLightbox.jsx";
import { PassageField } from "../common/CustomInputFields.jsx";

export default function ScriptCommForm({ directory, open, handleClose, sel }) {
  const [reader1, setReader1] = useState(sel?.reader1 || "");
  const [reader2, setReader2] = useState(sel?.reader2 || "");
  const [reader3, setReader3] = useState(sel?.reader3 || "");

  const [passage1, setPassage1] = useState(sel?.passage1 || "");
  const [passage2, setPassage2] = useState(sel?.passage2 || "");
  const [passage3, setPassage3] = useState(sel?.passage3 || "");

  const [communion1, setCommunion1] = useState(sel?.communion1 || "");
  const [communion2, setCommunion2] = useState(sel?.communion1 || "");
  const [tabIndex, setTabIndex] = useState(0);
  const [submitting, setSubmitting] = useState(false);

  const handleTabChange = (event, newValue) => setTabIndex(newValue);
  const handlePassage1 = (e) => setPassage1(e.target.value);
  const handlePassage2 = (e) => setPassage2(e.target.value);
  const handlePassage3 = (e) => setPassage3(e.target.value);

  function saveDetails() {
    const values = {
      passage1: passage1,
      passage2: passage2,
      passage3: passage3,
      reader1: trimPersonInfo(reader1),
      reader2: trimPersonInfo(reader2),
      reader3: trimPersonInfo(reader3),
      communion1: trimPersonInfo(communion1),
      communion2: trimPersonInfo(communion2),
    };
    const ref = doc(db, "services", sel.id);
    setSubmitting(true);
    updateDoc(ref, values)
      .then(() => setTimeout(() => handleClose(), 300))
      .catch((error) => console.log(error))
      .finally(() => setTimeout(() => setSubmitting(false), 200));
  }

  function resetForm() {
    setReader1(sel?.reader1 || "");
    setReader2(sel?.reader2 || "");
    setPassage1(sel?.passage1 || "");
    setPassage2(sel?.passage2 || "");
    setCommunion1(sel?.communion1 || "");
    setCommunion2(sel?.communion2 || "");
  }

  useEffect(
    resetForm,
    // eslint-disable-next-line
    [open]
  );

  return (
    <Lightbox open={open} onClose={handleClose}>
      <LightboxHeader title="Scripture & Communion" />
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3 }}>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="Psalm" />
          <Tab label="Passage 1" />
          <Tab label="Passage 2" />
          <Tab label="Communion" />
        </Tabs>
      </Box>

      {tabIndex === 0 && (
        <Box>
          <PassageField onChange={handlePassage3} value={passage3} />
          <DirectoryAutocomplete
            directory={directory}
            label="reader"
            setState={setReader3}
            value={reader3?.displayName || ""}
          />
        </Box>
      )}
      {tabIndex === 1 && (
        <Box>
          <PassageField onChange={handlePassage1} value={passage1} />
          <DirectoryAutocomplete
            directory={directory}
            label="reader"
            setState={setReader1}
            value={reader1?.displayName || ""}
          />
        </Box>
      )}
      {tabIndex === 2 && (
        <Box>
          <PassageField onChange={handlePassage2} value={passage2} />
          <DirectoryAutocomplete
            directory={directory}
            label="reader"
            setState={setReader2}
            value={reader2?.displayName || ""}
          />
        </Box>
      )}
      {tabIndex === 3 && (
        <Box>
          <DirectoryAutocomplete
            directory={directory}
            label="communion server 1"
            setState={setCommunion1}
            value={sel?.communion1?.displayName || ""}
          />
          <DirectoryAutocomplete
            directory={directory}
            label="communion server 2"
            setState={setCommunion2}
            value={sel?.communion2?.displayName || ""}
          />
        </Box>
      )}
      <Box className="flex flex-end">
        <SubmitButton
          onClick={saveDetails}
          submitting={submitting}
          title="SAVE"
        />
      </Box>
    </Lightbox>
  );
}
