import { db } from "./config/firebaseConfig";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

export function fetchPolls(setPolls) {
  const q = query(collection(db, "polls"));
  const unsubscribe = onSnapshot(q, (snapshot) => {
    const fetchedItems = snapshot.docs.map((doc) => ({
      id: doc.id,
      title: doc.data().title || "",
      startDate: doc.data().startDate?.toDate() || new Date(),
      endDate: doc.data().endDate?.toDate() || new Date(),
      releaseDate: doc.data().releaseDate?.toDate() || new Date(),
      questions: doc.data().questions || [],
      editorIDs: doc.data().editorIDs || [],
    }));
    setPolls(fetchedItems);
  });
  return unsubscribe;
}

export async function fetchUserPollSubmission(
  poll,
  userID,
  setResponse,
  setView
) {
  const ref = doc(db, "polls", poll.id, "responses", userID);
  const snapshot = await getDoc(ref);
  if (snapshot.exists()) {
    const submittedResponse = { ...snapshot.data(), submitted: true };

    setResponse(submittedResponse);
    setView("submitted");
  } else {
    const initValues = {};
    poll?.questions.forEach(
      (question) =>
        (initValues[`${question.id}`] = getTypeInitValue(question.type))
    );
    setResponse(initValues);
    setView("poll");
  }
}

export async function fetchAllPollSubmissions(poll, setAllResponses) {
  const ref = collection(db, "polls", poll.id, "responses");
  const snapshot = await getDocs(ref);
  const fetchedItems = [];
  snapshot.forEach((doc) => fetchedItems.push({ ...doc.data() }));

  setAllResponses(fetchedItems);
}

function getTypeInitValue(questionType) {
  if (questionType === "multiple choice") {
    return [];
  } else if (questionType === "short answer") {
    return "";
  } else return null;
}

export function updatePoll(poll, updatedValues, setSubmitting, successAction) {
  const ref = doc(db, "polls", poll.id);

  setSubmitting && setSubmitting(true);
  updateDoc(ref, updatedValues)
    .then(() => successAction && setTimeout(() => successAction(), 250))
    .catch((error) => console.log(error))
    .finally(
      () => setSubmitting && setTimeout(() => setSubmitting(false), 200)
    );
}

export function updatePollQuestions(poll, updatedQuestions) {
  const ref = doc(db, "polls", poll.id);
  updateDoc(ref, { questions: updatedQuestions });
}

export async function saveUserPollResponse(poll, response, user, setView) {
  const ref = doc(db, "polls", poll.id, "responses", user.uid);
  const membersRef = collection(db, "members");
  const q = query(membersRef, where("email", "==", user.email));

  const values = {
    ...response,
    userID: user.uid,
    userEmail: user.email,
    dateSubmitted: new Date(),
  };

  await setDoc(ref, values);
  setView("submitted");

  const userSnapshot = await getDocs(q);

  const userInfo = userSnapshot.docs[0].data();

  const userDisplayName = userInfo?.firstName + " " + userInfo?.lastName || "";

  updateDoc(ref, {
    userDisplayName: userDisplayName,
  });
}
