import { useEffect, useState } from "react";
import { db } from "../../config/firebaseConfig.js";
import { doc, updateDoc } from "firebase/firestore";
import { Box, Typography } from "@mui/material";
import { DirectoryAutocomplete, SubmitButton } from "../CommonCpnts";
import { trimPersonInfo } from "../../utils/utils.js";
import { Lightbox, LightboxHeader } from "../common/CustomLightbox.jsx";

export default function ChildrensMinistryForm({
  directory,
  open,
  handleClose,
  sel,
}) {
  const [ministryLead, setMinistryLead] = useState(
    sel?.childrensMinistryLead || ""
  );

  const [submitting, setSubmitting] = useState(false);

  function saveDetails() {
    const values = {
      childrensMinistryLead: trimPersonInfo(ministryLead),
    };
    const ref = doc(db, "services", sel.id);
    setSubmitting(true);
    updateDoc(ref, values)
      .then(() => setTimeout(() => handleClose(), 300))
      .catch((error) => console.log(error))
      .finally(() => setTimeout(() => setSubmitting(false), 200));
  }

  function resetForm() {
    setMinistryLead(sel?.childrensMinistryLead || "");
  }

  useEffect(
    resetForm,
    // eslint-disable-next-line
    [open]
  );

  return (
    <Lightbox open={open} onClose={handleClose}>
      <LightboxHeader />
      <Typography color="primary" variant="h5" sx={{ mb: 2 }}>
        Children's Ministry
      </Typography>
      <DirectoryAutocomplete
        directory={directory}
        label="ministry leader"
        setState={setMinistryLead}
        value={ministryLead?.displayName || ""}
      />
      <Box className="flex flex-end">
        <SubmitButton
          onClick={saveDetails}
          submitting={submitting}
          title="SAVE"
        />
      </Box>
    </Lightbox>
  );
}
