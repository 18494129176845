import { useEffect, useState } from "react";
import { db } from "../../config/firebaseConfig.js";
import { doc, updateDoc } from "firebase/firestore";
import { Box, Typography } from "@mui/material";
import { DirectoryAutocomplete, SubmitButton } from "../CommonCpnts";
import { trimPersonInfo } from "../../utils/utils.js";
import { Lightbox, LightboxHeader } from "../common/CustomLightbox.jsx";

export default function WorshipTechForm({ directory, open, handleClose, sel }) {
  const [emceeLead, setEmceeLead] = useState(sel?.emceeLead || "");
  const [musicLead, setMusicLead] = useState(sel?.musicLead || "");
  const [audioLead, setAudioLead] = useState(sel?.audioLead || "");
  const [livestreamLead, setLivestreamLead] = useState(
    sel?.livestreamLead || ""
  );
  const [slidesLead, setSlidesLead] = useState(sel?.slidesLead || "");
  const [submitting, setSubmitting] = useState(false);

  const values = {
    emceeLead: trimPersonInfo(emceeLead),
    musicLead: trimPersonInfo(musicLead),
    audioLead: trimPersonInfo(audioLead),
    livestreamLead: trimPersonInfo(livestreamLead),
    slidesLead: trimPersonInfo(slidesLead),
  };

  function saveDetails() {
    const ref = doc(db, "services", sel.id);
    setSubmitting(true);
    updateDoc(ref, values)
      .then(() => setTimeout(() => handleClose(), 300))
      .catch((error) => console.log(error))
      .finally(() => setTimeout(() => setSubmitting(false), 200));
  }

  function resetForm() {
    setEmceeLead(sel?.emceeLead || "");
    setMusicLead(sel?.musicLead || "");
    setAudioLead(sel?.audioLead || "");
    setLivestreamLead(sel?.livestreamLead || "");
    setSlidesLead(sel?.slidesLead || "");
  }

  useEffect(
    resetForm,
    // eslint-disable-next-line
    [open]
  );

  return (
    <Lightbox open={open} onClose={handleClose}>
      <LightboxHeader />
      <Typography color="primary" variant="h5" sx={{ mb: 2 }}>
        Emcee, Worship, Audio, & Slides
      </Typography>
      <DirectoryAutocomplete
        directory={directory}
        label="emcee"
        setState={setEmceeLead}
        value={emceeLead?.displayName || ""}
      />
      <DirectoryAutocomplete
        directory={directory}
        label="worship lead"
        setState={setMusicLead}
        value={musicLead?.displayName || ""}
      />
      <DirectoryAutocomplete
        directory={directory}
        label="audio"
        setState={setAudioLead}
        value={audioLead?.displayName || ""}
      />
      <DirectoryAutocomplete
        directory={directory}
        label="livestream"
        setState={setLivestreamLead}
        value={livestreamLead?.displayName || ""}
      />
      <DirectoryAutocomplete
        directory={directory}
        label="slides"
        setState={setSlidesLead}
        value={slidesLead?.displayName || ""}
      />
      <Box className="flex flex-end">
        <SubmitButton
          onClick={saveDetails}
          submitting={submitting}
          title="SAVE"
        />
      </Box>
    </Lightbox>
  );
}
